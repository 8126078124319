<template>
  <div>
    <NavbarMain />
    <div class="grid">
      <div class="col-12">
        <div v-show="showprogressppal">
          <ProgressBar mode="indeterminate" style="height: 0.5em" />
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-12 md:col-12 lg:col-7">
        <div class="grid">
          <div class="col-12">
            <DataTable
              :value="usuarios"
              v-show="!cargaskeletonusuarios"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="table-header">
                  Usuarios
                  <div class="p-inputgroup mr-4 ml-4">
                    <InputText
                      placeholder="Buscar x nombre"
                      v-model="usuario.filtro"
                      @keyup.enter="filtrarusuario"
                    />
                    <Button
                      icon="pi pi-search"
                      class="p-button-success"
                      @click="filtrarusuario"
                    />
                  </div>
                  <Button icon="pi pi-plus" @click="openmodalusuario()" />
                </div>
              </template>

              <Column field="usu_nombre" header="Nombre"> ></Column>
              <Column header="Estado">
                <template #body="slotProps">
                  <InputSwitch
                    v-model="slotProps.data.usu_estado"
                    @change="desactivarusuario($event, slotProps.data)"
                  /> </template
              ></Column>
              <Column field="usu_perfil" header="Perfil"> ></Column>

              <Column>
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-primary mr-2"
                    @click="listarmodalusuario(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-key"
                    class="p-button-rounded p-button-secondary"
                    @click="listarcambiarclave(slotProps.data)"
                  /> </template
              ></Column>
            </DataTable>
          </div>
          <div class="col-12">
            <DataTable :value="skeletons" v-show="cargaskeletonusuarios">
              <Column field="ske1" header="Cargando...">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
              <Column field="ske2" header="Cargando...">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
              <Column field="ske3" header="Cargando...">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
              <Column field="ske4" header="Cargando...">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-12 lg:col-5">
        <div class="grid">
          <div class="col-12">
            <DataTable
              :value="perfiles"
              v-show="!cargaskeletonperfiles"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="table-header">
                  Perfiles
                  <div class="p-inputgroup mr-4 ml-4">
                    <InputText
                      placeholder="Buscar x detalle"
                      v-model="perfil.filtro"
                      @keyup.enter="filtrarperfil"
                    />
                    <Button
                      icon="pi pi-search"
                      class="p-button-success"
                      @click="filtrarperfil"
                    />
                  </div>
                  <Button icon="pi pi-plus" @click="openmodalperfil()" />
                </div>
              </template>
              <Column field="pfl_detalle" header="Detalle"></Column>

              <Column>
                <template #body="slotProps">
                  <Button
                    icon="pi pi-lock-open"
                    class="p-button-rounded p-button-secondary mr-2"
                    @click="cargarnuevosroles(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-success"
                    @click="listarmodalperfil(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
          <div class="col-12">
            <DataTable :value="skeletons" v-show="cargaskeletonperfiles">
              <Column field="ske1" header="Cargando...">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
              <Column field="ske2" header="Cargando...">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
    <Dialog
      header="Header"
      :visible.sync="showmodalusuario"
      position="center"
      class="modales"
      :modal="true"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ usuario.titulomodal }}</h4>
      </template>

      <div>
        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Dropdown
                v-model="usuario.perfil"
                :options="perfilescombo"
                optionLabel="pfl_detalle"
                placeholder="Seleccionar un perfil"
              />
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
              <InputText
                placeholder="Nombre de usuario"
                v-model="usuario.alias"
                :readonly="!usuario.nuevo"
              />
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Nombre Completo"
                v-model="usuario.nombre"
              />
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Password
                v-model="usuario.clave"
                placeholder="Clave"
                v-show="usuario.nuevo"
              />
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Password
                v-model="usuario.repclave"
                placeholder="Repetir Clave"
                v-show="usuario.nuevo"
              />
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText placeholder="Correo" v-model="usuario.correo" />
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText placeholder="Telefono" v-model="usuario.telefono" />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="usuario.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="usuario.boton"
          icon="pi pi-check"
          autofocus
          @click="listaraccionusuario"
        />
      </template>
    </Dialog>

    <Dialog
      header="Header"
      :visible.sync="showmodalrolesxperfil"
      position="center"
      :modal="true"
      :containerStyle="{ width: '100vw' }"
    >
      <template #header>
        <h4>Roles por perfil</h4>
      </template>
      <div>
        <div class="grid">
          <div class="col-12">
            <DataTable :value="rolesxperfil" responsiveLayout="scroll">
              <Column field="rol_detalle" header="Nombre Rol"></Column>
              <Column field="rp_detalle" header="Nombre Grupo"></Column>
              <Column>
                <template #body="slotProps">
                  <InputSwitch
                    v-model="slotProps.data.pxr_permitido"
                    @change="desactivarrol($event, slotProps.data)"
                  /> </template
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </Dialog>

    <Dialog
      header="Header"
      :visible.sync="showmodalperfil"
      position="center"
      :modal="true"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ perfil.titulomodal }}</h4>
      </template>
      <div>
        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Nombre del Perfil"
                v-model="perfil.nombre"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="perfil.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="perfil.boton"
          icon="pi pi-check"
          autofocus
          @click="listaraccionperfil"
        />
      </template>
    </Dialog>

    <Dialog
      header="Header"
      :visible.sync="showmodalcambiarclave"
      position="center"
      :modal="true"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>Cambiar Clave</h4>
      </template>
      <div>
        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Password v-model="usuario.changeclave" placeholder="Clave" />
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Password
                v-model="usuario.changerepclave"
                placeholder="Repetir Clave"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="usuario.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="usuario.boton"
          icon="pi pi-check"
          autofocus
          @click="cambiarclaveusuario"
        />
      </template>
    </Dialog>

    <b-modal id="modal-perfil" ref="modal-perfil">
      <template #modal-title>
        <h4>{{ perfil.titulomodal }}</h4>
      </template>
      <div>
        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Nombre del Perfil"
                v-model="perfil.nombre"
              />
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <ProgressSpinner
          class="mr-2"
          v-show="perfil.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="perfil.boton"
          icon="pi pi-check"
          autofocus
          @click="listaraccionperfil"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarMain from "@/components/NavbarMain.vue";
import Toast from "primevue/toast";
export default {
  name: "Usuarios",

  data() {
    return {
      usuario: {
        alias: "",
        nombre: "",
        clave: "",
        repclave: "",
        correo: "",
        telefono: "",
        perfil: 0,
        titulomodal: "",
        boton: "",
        accion: 0,
        nuevo: true,
        filtro: "",
        changeclave: "",
        changerepclave: "",
        wait: false,
      },

      perfil: {
        id: 0,
        nombre: "",
        titulomodal: "",
        boton: "",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      },

      skeletons: [
        {
          ske1: "...",
          ske2: "...",
          ske3: "...",
          ske4: "...",
        },
        {
          ske1: "...",
          ske2: "...",
          ske3: "...",
          ske4: "...",
        },
        {
          ske1: "...",
          ske2: "...",
          ske3: "...",
          ske4: "...",
        },
        {
          ske1: "...",
          ske2: "...",
          ske3: "...",
          ske4: "...",
        },
      ],

      value1: null,
      value2: ["qwert", "1234"],

      cargaskeletonusuarios: false,
      cargaskeletonperfiles: false,

      showmodalusuario: false,
      showmodalrolesxperfil: false,
      showmodalperfil: false,

      showmodalcambiarclave: false,

      showprogressppal: false,
      perfiles: [],
      perfilescombo: [],
      usuarios: [],
      rolesxperfil: [],
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    openmodalusuario: function () {
      this.usuario = {
        alias: "",
        nombre: "",
        clave: "",
        repclave: "",
        correo: "",
        telefono: "",
        perfil: 0,
        titulomodal: "Nuevo Usuario",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
      };

      this.showmodalusuario = true;
    },

    listarmodalusuario: function (data) {
      this.usuario = {
        alias: data.usu_alias,
        nombre: data.usu_nombre,
        clave: "",
        repclave: "",
        correo: data.usu_correo,
        telefono: data.usu_telefono,
        perfil: this.perfiles.find((p) => p.pfl_id === data.pfl_id),
        titulomodal: "Editar Usuario",
        boton: "Editar",
        accion: 1,
        nuevo: false,
      };
      this.showmodalusuario = true;
    },

    listarcambiarclave: function (data) {
      this.usuario = {
        alias: data.usu_alias,
        boton: "Cambiar clave",
      };
      this.showmodalcambiarclave = true;
    },

    listaraccionusuario: function () {
      if (this.usuario.nuevo) {
        this.registrarnuevousuario();
      } else {
        this.editarusuario();
      }
    },

    openmodalperfil: function () {
      this.perfil = {
        nombre: "",
        titulomodal: "Nuevo Perfil",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
      };
      this.showmodalperfil = true;
      //this.$refs["modal-perfil"].show();
    },

    listarmodalperfil: function (data) {
      this.perfil = {
        id: data.pfl_id,
        nombre: data.pfl_detalle,
        titulomodal: "Editar Perfil",
        boton: "Editar",
        accion: 1,
        nuevo: false,
      };
      this.showmodalperfil = true;
      //this.$refs["modal-perfil"].show();
    },

    getperfiles: async function () {
      try {
        this.showprogressppal = true;
        this.cargaskeletonperfiles = true;
        let self = this;
        await this.axios
          .post("usuarios.php?dato=getperfiles")
          .then(function (response) {
            if (response.status === 200) {
              self.perfiles = response.data;
              self.perfilescombo = response.data;
            } else {
              //mensajeERR("ERROR: " + response.status + ": " + response.data);
              //console.log("RESPONSE: " + response.data);
              //console.log("STATUS: " + response.status);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.showprogressppal = false;
            self.cargaskeletonperfiles = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getusuarios: async function () {
      try {
        this.showprogressppal = true;
        this.cargaskeletonusuarios = true;
        let self = this;
        await this.axios
          .post("usuarios.php?dato=getusuarios")
          .then(function (response) {
            if (response.status === 200) {
              self.usuarios = response.data;
            } else {
              //mensajeERR("ERROR: " + response.status + ": " + response.data);
              //console.log("RESPONSE: " + response.data);
              //console.log("STATUS: " + response.status);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.showprogressppal = false;
            self.cargaskeletonusuarios = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    filtrarusuario: async function () {
      try {
        this.showprogressppal = true;
        this.cargaskeletonusuarios = true;
        let self = this;

        var parametros = {
          nombreusuario: this.usuario.filtro,
        };

        await this.axios
          .post("usuarios.php?dato=filtrarusuario", JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.usuarios = response.data;
              if (self.usuarios.length === 0) {
                self.mensajeWar("No se encontraron registros");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.showprogressppal = false;
            self.cargaskeletonusuarios = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    filtrarperfil: async function () {
      try {
        this.showprogressppal = true;
        this.cargaskeletonperfiles = true;
        let self = this;

        var parametros = {
          detalleperfil: this.perfil.filtro,
        };

        await this.axios
          .post("usuarios.php?dato=filtrarperfil", JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.perfiles = response.data;
              if (self.perfiles.length === 0) {
                self.mensajeWar("No se encontraron registros");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.showprogressppal = false;
            self.cargaskeletonperfiles = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    cargarnuevosroles: async function (data) {
      try {
        this.showprogressppal = true;
        let self = this;

        var parametros = {
          perfil: data.pfl_id,
        };

        await this.axios
          .post(
            "usuarios.php?dato=refreshrolesxperfil",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.rolesxperfil = response.data;
              self.showmodalrolesxperfil = true;
              self.showmodalrolesxperfil = true;
            } else {
              //mensajeERR("ERROR: " + response.status + ": " + response.data);
              //console.log("RESPONSE: " + response.data);
              //console.log("STATUS: " + response.status);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.showprogressppal = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    registrarnuevousuario: async function () {
      try {
        var mensaje = this.validarRegistrarUsuario;

        if (mensaje === "") {
          this.showprogressppal = true;
          let self = this;

          this.usuario.boton = " Guardando... ";
          this.usuario.wait = true;

          var parametros = {
            alias: this.usuario.alias,
            nombre: this.usuario.nombre,
            clave: this.usuario.clave,
            correo: this.usuario.correo,
            telefono: this.usuario.telefono,
            perfil: this.usuario.perfil.pfl_id,
          };

          await this.axios
            .post("usuarios.php?dato=nuevousuario", JSON.stringify(parametros))
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.mensajeOK(response.data);
                  self.showmodalusuario = false;
                  self.getusuarios();
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.showprogressppal = false;
              self.usuario.boton = "Guardar";
              self.usuario.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    editarusuario: async function () {
      try {
        var mensaje = this.validarEditarUsuario;

        if (mensaje === "") {
          this.showprogressppal = true;
          let self = this;

          this.usuario.boton = " Editando... ";
          this.usuario.wait = true;

          var parametros = {
            alias: this.usuario.alias,
            nombre: this.usuario.nombre,
            correo: this.usuario.correo,
            telefono: this.usuario.telefono,
            perfil: this.usuario.perfil.pfl_id,
          };

          await this.axios
            .post("usuarios.php?dato=editarusuario", JSON.stringify(parametros))
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.mensajeOK(response.data);
                  self.showmodalusuario = false;
                  self.getusuarios();
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.showprogressppal = false;
              self.usuario.boton = " Editar ";
              self.usuario.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    desactivarusuario: async function (event, usuario) {
      this.showprogressppal = true;
      let self = this;

      try {
        var parametros = {
          alias: usuario.usu_alias,
          estado: usuario.usu_estado,
        };

        await this.axios
          .post(
            "usuarios.php?dato=desactivarusuario",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              if (response.data.includes(self.global.transaccionOK)) {
                self.mensajeOK(response.data);
                self.getusuarios();
              } else if (self.global.buscarapierror(response.data) !== "") {
                let msj_user = self.global.buscarapierror(response.data);
                self.mensajeWar(msj_user);
              } else {
                self.mensajeErr(self.global.respuestaValidaSinTransaccion);
              }
            } else {
              self.global.mensajeErr(self.global.respuestaInvalida);
            }
          })
          .catch((error) => {
            self.global.catching(error);
          })
          .finally(() => {
            self.showprogressppal = false;
          });
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    cambiarclaveusuario: async function () {
      try {
        var mensaje = this.validarCambiarClaveUsuario;

        if (mensaje === "") {
          this.showprogressppal = true;
          let self = this;

          this.usuario.boton = " Cambiando clave... ";
          this.usuario.wait = true;

          var parametros = {
            alias: this.usuario.alias,
            clave: this.usuario.changeclave,
          };

          await this.axios
            .post(
              "usuarios.php?dato=cambiarclaveusuario",
              JSON.stringify(parametros)
            )
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.mensajeOK(response.data);
                  self.showmodalcambiarclave = false;
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.showprogressppal = false;
              self.usuario.boton = " Cambiar clave ";
              self.usuario.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log("ERROR JS: " + e);
      }
    },

    desactivarrol: async function (event, rol) {
      this.showprogressppal = true;
      let self = this;

      try {
        var parametros = {
          id: rol.pxr_id,
          permitido: rol.pxr_permitido,
        };

        await this.axios
          .post("usuarios.php?dato=desactivarrol", JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              if (response.data.includes(self.global.transaccionOK)) {
                self.mensajeOK(response.data);
              } else if (self.global.buscarapierror(response.data) !== "") {
                let msj_user = self.global.buscarapierror(response.data);
                self.mensajeWar(msj_user);
              } else {
                self.mensajeErr(self.global.respuestaValidaSinTransaccion);
              }
            } else {
              self.global.mensajeErr(self.global.respuestaInvalida);
            }
          })
          .catch((error) => {
            self.global.catching(error);
          })
          .finally(() => {
            self.showprogressppal = false;
          });
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    registrarnuevoperfil: async function () {
      try {
        var mensaje = this.validarRegistrarPerfil;

        if (mensaje === "") {
          this.showprogressppal = true;
          let self = this;

          this.perfil.boton = " Guardando... ";
          this.perfil.wait = true;

          var parametros = {
            nombre: this.perfil.nombre,
          };

          await this.axios
            .post("usuarios.php?dato=nuevoperfil", JSON.stringify(parametros))
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.showmodalperfil = false;
                  //self.$refs["modal-perfil"].hide();
                  self.mensajeOK(response.data);
                  self.getperfiles();
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.showprogressppal = false;
              self.perfil.boton = " Guardar ";
              self.perfil.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    editarperfil: async function () {
      try {
        var mensaje = this.validarRegistrarPerfil;

        if (mensaje === "") {
          this.showprogressppal = true;
          let self = this;

          this.perfil.boton = " Editando... ";
          this.perfil.wait = true;

          var parametros = {
            pfl_detalle: this.perfil.nombre,
            pfl_id: this.perfil.id,
          };

          await this.axios
            .post("usuarios.php?dato=editarperfil", JSON.stringify(parametros))
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.showmodalperfil = false;
                  //self.$refs["modal-perfil"].hide();
                  self.mensajeOK(response.data);
                  self.getperfiles();
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.showprogressppal = false;
              self.perfil.boton = " Editar ";
              self.perfil.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    listaraccionperfil: function () {
      if (this.perfil.nuevo) {
        this.registrarnuevoperfil();
      } else {
        this.editarperfil();
      }
    },

    cambiarestadorol: function (event, rol) {
      try {
        alert(rol.rol_detalle + " " + rol.pxr_permitido);
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    cambiarestadousuario: function (event, usuario) {
      try {
        alert(usuario.usu_alias + " " + usuario.usu_estado);
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    verchips: function () {
      console.log("VAL_1: " + this.value1);
      var result = this.value1.toString().split(",");
      console.log(result);
      //var arr = this.value1.split(",");
    },

    cerrarsesion: function () {},
  },

  created: function () {
    //document.body.style.zoom = "90%";
    //this.global.hello();
    this.getperfiles();
    this.getusuarios();
  },

  computed: {
    validarRegistrarUsuario() {
      var mensajeAlerta = "";
      if (
        this.usuario.alias === "" ||
        this.usuario.alias.length > 20 ||
        this.usuario.alias.includes(" ") ||
        this.usuario.alias.length < 6
      ) {
        mensajeAlerta =
          "Debe digitar un nombre de usuario válido que no contenga espacios, mayor a 5 caractéres 7 menor o igual a 20 caractéres";
      } else if (
        this.usuario.nombre === "" ||
        this.usuario.nombre.length > 75 ||
        this.usuario.nombre.length < 10
      ) {
        mensajeAlerta =
          "Debe digitar un nombre válido mayor a 10 caractéres y menor o igual a 75 caractéres";
      } else if (
        this.usuario.clave !== this.usuario.repclave ||
        this.usuario.clave.length < 6
      ) {
        mensajeAlerta =
          "Las claves digitadas deben coincidir y no pueden tener menos de 6 caractéres";
      }
      if (this.usuario.correo !== "") {
        if (this.global.validarCorreo(this.usuario.correo)) {
          if (this.usuario.correo.length > 75) {
            mensajeAlerta = "El correo no debe tener mas de 75 caractéres";
          }
        } else {
          mensajeAlerta = "El correo digitado es inválido";
        }
      }
      if (this.usuario.telefono !== "") {
        if (this.global.validarTelefono(this.usuario.telefono)) {
          if (this.usuario.telefono.length > 10) {
            mensajeAlerta = "El telefono no debe tener mas de 10 caractéres";
          }
        } else {
          mensajeAlerta = "El teléfono digitado es inválido";
        }
      } else if (this.usuario.perfil === 0) {
        mensajeAlerta = "Debe seleccionar un perfil de usuario";
      }

      return mensajeAlerta;
    },

    validarEditarUsuario() {
      var mensajeAlerta = "";
      if (
        this.usuario.nombre === "" ||
        this.usuario.nombre.length > 75 ||
        this.usuario.nombre.length < 10
      ) {
        mensajeAlerta =
          "Debe digitar un nombre válido mayor a 10 caractéres y menor o igual a 75 caractéres";
      } else if (this.usuario.perfil === 0) {
        mensajeAlerta = "Debe seleccionar un perfil de usuario";
      }
      return mensajeAlerta;
    },

    validarRegistrarPerfil() {
      var mensajeAlerta = "";
      if (
        this.perfil.nombre === "" ||
        this.perfil.nombre.length > 50 ||
        this.perfil.nombre.length < 5
      ) {
        mensajeAlerta =
          "Debe digitar un nombre de perfil válido menor a 50 caractéres y mayor a 5 caractéres";
      }
      return mensajeAlerta;
    },

    validarCambiarClaveUsuario() {
      var mensajeAlerta = "";
      if (this.usuario.changeclave !== undefined) {
        if (
          this.usuario.changeclave !== this.usuario.changerepclave ||
          this.usuario.changeclave.length < 6
        ) {
          mensajeAlerta =
            "Las claves digitadas deben coincidir, no pueden tener menos de 6 caractéres y no puede quedar vacía";
        }
      } else {
        mensajeAlerta = "La clave es inválida";
      }

      return mensajeAlerta;
    },
  },

  mounted() {
    if (localStorage.getItem("name")) {
      this.getperfiles();
      this.getusuarios();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

p {
  margin: 0;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinnercustom {
  width: 20px;
  height: 20px;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

@media screen and (min-width: 640px) {
  .modales {
    width: "100vw";
  }
}

@media screen and (min-width: 900px) {
  .modales {
    width: "80vw";
  }
}

@media screen and (min-width: 1800px) {
  .modales {
    width: "30vw";
  }
}
</style>
