<template>
  <div>
    <NavbarMain />
    <div class="container"></div>

    
    <div class="grid">
      <div class="col-12">
        <!-- Criterios -->
        <DataTable
          :value="criterios"
          v-show="!cargaskeletoncriterios"
          responsiveLayout="scroll"
          :paginator="true"
          :rows="5"
        >
          <Column field="cri_detalle" header="CRITERIO"></Column>

          <Column
            field="cri_uninal"
            header="SALIDAS DE CAMPO UNIVERSIDADES NACIONALES"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.cri_uninal === 'ALTA'"
                severity="danger"
                :value="slotProps.data.cri_uninal"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.cri_uninal === 'MEDIA'"
                severity="warning"
                :value="slotProps.data.cri_uninal"
              ></Badge>
              <Badge
                v-else
                severity="success"
                :value="slotProps.data.cri_uninal"
              ></Badge>
            </template>
          </Column>
          <Column
            field="cri_uniint"
            header="SALIDAS DE CAMPO UNIVERSIDADES INTERNACIONALES"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.cri_uniint === 'ALTA'"
                severity="danger"
                :value="slotProps.data.cri_uniint"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.cri_uniint === 'MEDIA'"
                severity="warning"
                :value="slotProps.data.cri_uniint"
              ></Badge>
              <Badge
                v-else
                severity="success"
                :value="slotProps.data.cri_uniint"
              ></Badge> </template
          ></Column>
          <Column
            field="cri_curpro"
            header="CURSOS DE CAMPO PARA PROFESIONALES Y ESTUDIANTES NACIONALES"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.cri_curpro === 'ALTA'"
                severity="danger"
                :value="slotProps.data.cri_curpro"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.cri_curpro === 'MEDIA'"
                severity="warning"
                :value="slotProps.data.cri_curpro"
              ></Badge>
              <Badge
                v-else
                severity="success"
                :value="slotProps.data.cri_curpro"
              ></Badge> </template
          ></Column>
          <Column
            field="cri_curest"
            header="CURSOS DE CAMPO PARA PROFESIONALES Y ESTUDIANTES INTERNACIONALES"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.cri_curest === 'ALTA'"
                severity="danger"
                :value="slotProps.data.cri_curest"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.cri_curest === 'MEDIA'"
                severity="warning"
                :value="slotProps.data.cri_curest"
              ></Badge>
              <Badge
                v-else
                severity="success"
                :value="slotProps.data.cri_curest"
              ></Badge> </template
          ></Column>
          <Column
            field="cri_tesnac"
            header="TESISTAS NACIONALES E INTERNACIONALES"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.cri_tesnac === 'ALTA'"
                severity="danger"
                :value="slotProps.data.cri_tesnac"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.cri_tesnac === 'MEDIA'"
                severity="warning"
                :value="slotProps.data.cri_tesnac"
              ></Badge>
              <Badge
                v-else
                severity="success"
                :value="slotProps.data.cri_tesnac"
              ></Badge> </template
          ></Column>
          <Column
            field="cri_expinv"
            header="EXPEDICIONES O INVESTIGACIONES DE ONG O ENTIDADES"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.cri_expinv === 'ALTA'"
                severity="danger"
                :value="slotProps.data.cri_expinv"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.cri_expinv === 'MEDIA'"
                severity="warning"
                :value="slotProps.data.cri_expinv"
              ></Badge>
              <Badge
                v-else
                severity="success"
                :value="slotProps.data.cri_expinv"
              ></Badge> </template
          ></Column>

          <Column field="cri_agetur" header="AGENCIAS DE TURISMO DE NATURALEZA">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.cri_agetur === 'ALTA'"
                severity="danger"
                :value="slotProps.data.cri_agetur"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.cri_agetur === 'MEDIA'"
                severity="warning"
                :value="slotProps.data.cri_agetur"
              ></Badge>
              <Badge
                v-else
                severity="success"
                :value="slotProps.data.cri_agetur"
              ></Badge> </template
          ></Column>
          <Column header="CONSULTORIAS">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.cri_consul === 'ALTA'"
                severity="danger"
                :value="slotProps.data.cri_consul"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.cri_consul === 'MEDIA'"
                severity="warning"
                :value="slotProps.data.cri_consul"
              ></Badge>
              <Badge
                v-else
                severity="success"
                :value="slotProps.data.cri_consul"
              ></Badge>
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="col-12 mt-4 mb-4">
        <h5>Código de colores</h5>
        <Button
          label="Muy prioritario"
          class="p-button-rounded p-button-danger"
        />
        <Button label="Prioritario" class="p-button-rounded p-button-warning" />
        <Button
          label="Mediantamente Prioritario"
          class="p-button-rounded p-button-success"
        />
        <Button label="Poco interés" class="p-button-rounded p-button-info" />
         <Button label="Sin interés 👎🏻" class="p-button-rounded p-button-info" />
      </div>



      <div class="col-12">
        <!-- CLASIFICADAS -->
                                   <DataTable
          :value="sumatoriasclasificadas"
          v-show="!cargaskeletonsumatoriasclasificadas"
          responsiveLayout="scroll"
        >
          <Column field="tpmed_detalle" header="TIPO"></Column>
          <Column
            field="med_objinvestigacion"
            header="OBJETIVO DE LA INVESTIGACIÓN"
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_objinvestigacion > 36"
                  severity="danger"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 26 &&
                    slotProps.data.med_objinvestigacion < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 18 &&
                    slotProps.data.med_objinvestigacion < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 8 &&
                    slotProps.data.med_objinvestigacion < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_objinvestigacion > 29"
                  severity="danger"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 21 &&
                    slotProps.data.med_objinvestigacion < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 13 &&
                    slotProps.data.med_objinvestigacion < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 5 &&
                    slotProps.data.med_objinvestigacion < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_objinvestigacion > 24"
                  severity="danger"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 18 &&
                    slotProps.data.med_objinvestigacion < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 12 &&
                    slotProps.data.med_objinvestigacion < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 5 &&
                    slotProps.data.med_objinvestigacion < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_objinvestigacion > 16"
                  severity="danger"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 11 &&
                    slotProps.data.med_objinvestigacion < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 7 &&
                    slotProps.data.med_objinvestigacion < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 3 &&
                    slotProps.data.med_objinvestigacion < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_objinvestigacion > 16"
                  severity="danger"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 12 &&
                    slotProps.data.med_objinvestigacion < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 8 &&
                    slotProps.data.med_objinvestigacion < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 4 &&
                    slotProps.data.med_objinvestigacion < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_objinvestigacion > 8"
                  severity="danger"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 6 &&
                    slotProps.data.med_objinvestigacion <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 4 &&
                    slotProps.data.med_objinvestigacion < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_objinvestigacion > 1 &&
                    slotProps.data.med_objinvestigacion < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_objinvestigacion + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_monitoreo" header="MONITOREO">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_monitoreo > 36"
                  severity="danger"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 26 &&
                    slotProps.data.med_monitoreo < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 18 &&
                    slotProps.data.med_monitoreo < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 8 &&
                    slotProps.data.med_monitoreo < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_monitoreo + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_monitoreo > 29"
                  severity="danger"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 21 &&
                    slotProps.data.med_monitoreo < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 13 &&
                    slotProps.data.med_monitoreo < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 5 &&
                    slotProps.data.med_monitoreo < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_monitoreo + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_monitoreo > 24"
                  severity="danger"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 18 &&
                    slotProps.data.med_monitoreo < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 12 &&
                    slotProps.data.med_monitoreo < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 5 &&
                    slotProps.data.med_monitoreo < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_monitoreo + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_monitoreo > 16"
                  severity="danger"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 11 &&
                    slotProps.data.med_monitoreo < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 7 &&
                    slotProps.data.med_monitoreo < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 3 &&
                    slotProps.data.med_monitoreo < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_monitoreo + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_monitoreo > 16"
                  severity="danger"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 12 &&
                    slotProps.data.med_monitoreo < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 8 &&
                    slotProps.data.med_monitoreo < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 4 &&
                    slotProps.data.med_monitoreo < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_monitoreo + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_monitoreo > 8"
                  severity="danger"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 6 &&
                    slotProps.data.med_monitoreo <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 4 &&
                    slotProps.data.med_monitoreo < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_monitoreo > 1 &&
                    slotProps.data.med_monitoreo < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_monitoreo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_monitoreo + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_estconservacion" header="ESTADO DE CONSERVACIÓN">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_estconservacion > 36"
                  severity="danger"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 26 &&
                    slotProps.data.med_estconservacion < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 18 &&
                    slotProps.data.med_estconservacion < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 8 &&
                    slotProps.data.med_estconservacion < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_estconservacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_estconservacion > 29"
                  severity="danger"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 21 &&
                    slotProps.data.med_estconservacion < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 13 &&
                    slotProps.data.med_estconservacion < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 5 &&
                    slotProps.data.med_estconservacion < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_estconservacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_estconservacion > 24"
                  severity="danger"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 18 &&
                    slotProps.data.med_estconservacion < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 12 &&
                    slotProps.data.med_estconservacion < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 5 &&
                    slotProps.data.med_estconservacion < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_estconservacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_estconservacion > 16"
                  severity="danger"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 11 &&
                    slotProps.data.med_estconservacion < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 7 &&
                    slotProps.data.med_estconservacion < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 3 &&
                    slotProps.data.med_estconservacion < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_estconservacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_estconservacion > 16"
                  severity="danger"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 12 &&
                    slotProps.data.med_estconservacion < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 8 &&
                    slotProps.data.med_estconservacion < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 4 &&
                    slotProps.data.med_estconservacion < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_estconservacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_estconservacion > 8"
                  severity="danger"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 6 &&
                    slotProps.data.med_estconservacion <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 4 &&
                    slotProps.data.med_estconservacion < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_estconservacion > 1 &&
                    slotProps.data.med_estconservacion < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_estconservacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_estconservacion + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_seguridad" header="SEGURIDAD">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_seguridad > 36"
                  severity="danger"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 26 &&
                    slotProps.data.med_seguridad < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 18 &&
                    slotProps.data.med_seguridad < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 8 &&
                    slotProps.data.med_seguridad < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_seguridad + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_seguridad > 29"
                  severity="danger"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 21 &&
                    slotProps.data.med_seguridad < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 13 &&
                    slotProps.data.med_seguridad < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 5 &&
                    slotProps.data.med_seguridad < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_seguridad + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_seguridad > 24"
                  severity="danger"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 18 &&
                    slotProps.data.med_seguridad < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 12 &&
                    slotProps.data.med_seguridad < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 5 &&
                    slotProps.data.med_seguridad < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_seguridad + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_seguridad > 16"
                  severity="danger"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 11 &&
                    slotProps.data.med_seguridad < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 7 &&
                    slotProps.data.med_seguridad < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 3 &&
                    slotProps.data.med_seguridad < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_seguridad + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_seguridad > 16"
                  severity="danger"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 12 &&
                    slotProps.data.med_seguridad < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 8 &&
                    slotProps.data.med_seguridad < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 4 &&
                    slotProps.data.med_seguridad < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_seguridad + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_seguridad > 8"
                  severity="danger"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 6 &&
                    slotProps.data.med_seguridad <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 4 &&
                    slotProps.data.med_seguridad < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_seguridad > 1 &&
                    slotProps.data.med_seguridad < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_seguridad"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_seguridad + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_repcientifica" header="REPUTACIÓN CIENTÍFICA">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_repcientifica > 36"
                  severity="danger"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 26 &&
                    slotProps.data.med_repcientifica < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 18 &&
                    slotProps.data.med_repcientifica < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 8 &&
                    slotProps.data.med_repcientifica < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_repcientifica + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_repcientifica > 29"
                  severity="danger"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 21 &&
                    slotProps.data.med_repcientifica < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 13 &&
                    slotProps.data.med_repcientifica < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 5 &&
                    slotProps.data.med_repcientifica < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_repcientifica + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_repcientifica > 24"
                  severity="danger"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 18 &&
                    slotProps.data.med_repcientifica < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 12 &&
                    slotProps.data.med_repcientifica < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 5 &&
                    slotProps.data.med_repcientifica < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_repcientifica + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_repcientifica > 16"
                  severity="danger"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 11 &&
                    slotProps.data.med_repcientifica < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 7 &&
                    slotProps.data.med_repcientifica < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 3 &&
                    slotProps.data.med_repcientifica < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_repcientifica + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_repcientifica > 16"
                  severity="danger"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 12 &&
                    slotProps.data.med_repcientifica < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 8 &&
                    slotProps.data.med_repcientifica < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 4 &&
                    slotProps.data.med_repcientifica < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_repcientifica + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_repcientifica > 8"
                  severity="danger"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 6 &&
                    slotProps.data.med_repcientifica <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 4 &&
                    slotProps.data.med_repcientifica < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_repcientifica > 1 &&
                    slotProps.data.med_repcientifica < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_repcientifica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_repcientifica + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_colecta" header="COLECTA">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_colecta > 36"
                  severity="danger"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 26 &&
                    slotProps.data.med_colecta < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 18 &&
                    slotProps.data.med_colecta < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 8 &&
                    slotProps.data.med_colecta < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_colecta + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_colecta > 29"
                  severity="danger"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 21 &&
                    slotProps.data.med_colecta < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 13 &&
                    slotProps.data.med_colecta < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 5 &&
                    slotProps.data.med_colecta < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_colecta + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_colecta > 24"
                  severity="danger"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 18 &&
                    slotProps.data.med_colecta < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 12 &&
                    slotProps.data.med_colecta < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 5 &&
                    slotProps.data.med_colecta < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_colecta + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_colecta > 16"
                  severity="danger"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 11 &&
                    slotProps.data.med_colecta < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 7 &&
                    slotProps.data.med_colecta < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 3 &&
                    slotProps.data.med_colecta < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_colecta + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_colecta > 16"
                  severity="danger"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 12 &&
                    slotProps.data.med_colecta < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 8 &&
                    slotProps.data.med_colecta < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 4 &&
                    slotProps.data.med_colecta < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_colecta + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_colecta > 8"
                  severity="danger"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 6 &&
                    slotProps.data.med_colecta <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 4 &&
                    slotProps.data.med_colecta < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_colecta > 1 &&
                    slotProps.data.med_colecta < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_colecta"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_colecta + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column
            field="med_conprestadores"
            header="NIVEL DE CONOCIMIENTO DE LOS PRESTADORES DE SERVICIO"
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_conprestadores > 36"
                  severity="danger"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 26 &&
                    slotProps.data.med_conprestadores < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 18 &&
                    slotProps.data.med_conprestadores < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 8 &&
                    slotProps.data.med_conprestadores < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_conprestadores + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_conprestadores > 29"
                  severity="danger"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 21 &&
                    slotProps.data.med_conprestadores < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 13 &&
                    slotProps.data.med_conprestadores < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 5 &&
                    slotProps.data.med_conprestadores < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_conprestadores + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_conprestadores > 24"
                  severity="danger"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 18 &&
                    slotProps.data.med_conprestadores < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 12 &&
                    slotProps.data.med_conprestadores < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 5 &&
                    slotProps.data.med_conprestadores < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_conprestadores + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_conprestadores > 16"
                  severity="danger"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 11 &&
                    slotProps.data.med_conprestadores < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 7 &&
                    slotProps.data.med_conprestadores < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 3 &&
                    slotProps.data.med_conprestadores < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_conprestadores + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_conprestadores > 16"
                  severity="danger"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 12 &&
                    slotProps.data.med_conprestadores < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 8 &&
                    slotProps.data.med_conprestadores < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 4 &&
                    slotProps.data.med_conprestadores < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_conprestadores + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_conprestadores > 8"
                  severity="danger"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 6 &&
                    slotProps.data.med_conprestadores <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 4 &&
                    slotProps.data.med_conprestadores < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_conprestadores > 1 &&
                    slotProps.data.med_conprestadores < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_conprestadores"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_conprestadores + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>
          <Column
            field="med_usodatos"
            header="USO DE DATOS Y DIVULGACIÓN A LAS COMUNIDADES Y GREMIO"
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_usodatos > 36"
                  severity="danger"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 26 &&
                    slotProps.data.med_usodatos < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 18 &&
                    slotProps.data.med_usodatos < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 8 &&
                    slotProps.data.med_usodatos < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_usodatos + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_usodatos > 29"
                  severity="danger"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 21 &&
                    slotProps.data.med_usodatos < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 13 &&
                    slotProps.data.med_usodatos < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 5 &&
                    slotProps.data.med_usodatos < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_usodatos + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_usodatos > 24"
                  severity="danger"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 18 &&
                    slotProps.data.med_usodatos < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 12 &&
                    slotProps.data.med_usodatos < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 5 &&
                    slotProps.data.med_usodatos < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_usodatos + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_usodatos > 16"
                  severity="danger"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 11 &&
                    slotProps.data.med_usodatos < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 7 &&
                    slotProps.data.med_usodatos < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 3 &&
                    slotProps.data.med_usodatos < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_usodatos + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_usodatos > 16"
                  severity="danger"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 12 &&
                    slotProps.data.med_usodatos < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 8 &&
                    slotProps.data.med_usodatos < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 4 &&
                    slotProps.data.med_usodatos < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_usodatos + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_usodatos > 8"
                  severity="danger"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 6 &&
                    slotProps.data.med_usodatos <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 4 &&
                    slotProps.data.med_usodatos < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_usodatos > 1 &&
                    slotProps.data.med_usodatos < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_usodatos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_usodatos + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_alojamiento" header="ALOJAMIENTO">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_alojamiento > 36"
                  severity="danger"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 26 &&
                    slotProps.data.med_alojamiento < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 18 &&
                    slotProps.data.med_alojamiento < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 8 &&
                    slotProps.data.med_alojamiento < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alojamiento + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_alojamiento > 29"
                  severity="danger"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 21 &&
                    slotProps.data.med_alojamiento < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 13 &&
                    slotProps.data.med_alojamiento < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 5 &&
                    slotProps.data.med_alojamiento < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alojamiento + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_alojamiento > 24"
                  severity="danger"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 18 &&
                    slotProps.data.med_alojamiento < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 12 &&
                    slotProps.data.med_alojamiento < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 5 &&
                    slotProps.data.med_alojamiento < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alojamiento + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_alojamiento > 16"
                  severity="danger"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 11 &&
                    slotProps.data.med_alojamiento < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 7 &&
                    slotProps.data.med_alojamiento < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 3 &&
                    slotProps.data.med_alojamiento < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alojamiento + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_alojamiento > 16"
                  severity="danger"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 12 &&
                    slotProps.data.med_alojamiento < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 8 &&
                    slotProps.data.med_alojamiento < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 4 &&
                    slotProps.data.med_alojamiento < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alojamiento + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_alojamiento > 8"
                  severity="danger"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 6 &&
                    slotProps.data.med_alojamiento <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 4 &&
                    slotProps.data.med_alojamiento < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alojamiento > 1 &&
                    slotProps.data.med_alojamiento < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_alojamiento"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alojamiento + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_alimentacion" header="ALIMENTACIÓN">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_alimentacion > 36"
                  severity="danger"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 26 &&
                    slotProps.data.med_alimentacion < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 18 &&
                    slotProps.data.med_alimentacion < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 8 &&
                    slotProps.data.med_alimentacion < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alimentacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_alimentacion > 29"
                  severity="danger"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 21 &&
                    slotProps.data.med_alimentacion < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 13 &&
                    slotProps.data.med_alimentacion < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 5 &&
                    slotProps.data.med_alimentacion < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alimentacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_alimentacion > 24"
                  severity="danger"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 18 &&
                    slotProps.data.med_alimentacion < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 12 &&
                    slotProps.data.med_alimentacion < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 5 &&
                    slotProps.data.med_alimentacion < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alimentacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_alimentacion > 16"
                  severity="danger"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 11 &&
                    slotProps.data.med_alimentacion < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 7 &&
                    slotProps.data.med_alimentacion < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 3 &&
                    slotProps.data.med_alimentacion < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alimentacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_alimentacion > 16"
                  severity="danger"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 12 &&
                    slotProps.data.med_alimentacion < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 8 &&
                    slotProps.data.med_alimentacion < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 4 &&
                    slotProps.data.med_alimentacion < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alimentacion + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_alimentacion > 8"
                  severity="danger"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 6 &&
                    slotProps.data.med_alimentacion <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 4 &&
                    slotProps.data.med_alimentacion < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_alimentacion > 1 &&
                    slotProps.data.med_alimentacion < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_alimentacion"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_alimentacion + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_wifi" header="WIFI">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_wifi > 36"
                  severity="danger"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 26 && slotProps.data.med_wifi < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 18 && slotProps.data.med_wifi < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 8 && slotProps.data.med_wifi < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_wifi + '👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_wifi > 29"
                  severity="danger"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 21 && slotProps.data.med_wifi < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 13 && slotProps.data.med_wifi < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 5 && slotProps.data.med_wifi < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_wifi + '👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_wifi > 24"
                  severity="danger"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 18 && slotProps.data.med_wifi < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 12 && slotProps.data.med_wifi < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 5 && slotProps.data.med_wifi < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_wifi + '👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_wifi > 16"
                  severity="danger"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 11 && slotProps.data.med_wifi < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 7 && slotProps.data.med_wifi < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 3 && slotProps.data.med_wifi < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_wifi + '👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_wifi > 16"
                  severity="danger"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 12 && slotProps.data.med_wifi < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 8 && slotProps.data.med_wifi < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 4 && slotProps.data.med_wifi < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_wifi + '👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_wifi > 8"
                  severity="danger"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 6 && slotProps.data.med_wifi <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 4 && slotProps.data.med_wifi < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_wifi > 1 && slotProps.data.med_wifi < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_wifi"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_wifi + '👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_contelefonica" header="CONEXIÓN TELEFÓNICA">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_contelefonica > 36"
                  severity="danger"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 26 &&
                    slotProps.data.med_contelefonica < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 18 &&
                    slotProps.data.med_contelefonica < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 8 &&
                    slotProps.data.med_contelefonica < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_contelefonica + '👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_contelefonica > 29"
                  severity="danger"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 21 &&
                    slotProps.data.med_contelefonica < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 13 &&
                    slotProps.data.med_contelefonica < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 5 &&
                    slotProps.data.med_contelefonica < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_contelefonica + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_contelefonica > 24"
                  severity="danger"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 18 &&
                    slotProps.data.med_contelefonica < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 12 &&
                    slotProps.data.med_contelefonica < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 5 &&
                    slotProps.data.med_contelefonica < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_contelefonica + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_contelefonica > 16"
                  severity="danger"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 11 &&
                    slotProps.data.med_contelefonica < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 7 &&
                    slotProps.data.med_contelefonica < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 3 &&
                    slotProps.data.med_contelefonica < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_contelefonica + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_contelefonica > 16"
                  severity="danger"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 12 &&
                    slotProps.data.med_contelefonica < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 8 &&
                    slotProps.data.med_contelefonica < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 4 &&
                    slotProps.data.med_contelefonica < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_contelefonica + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_contelefonica > 8"
                  severity="danger"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 6 &&
                    slotProps.data.med_contelefonica <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 4 &&
                    slotProps.data.med_contelefonica < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_contelefonica > 1 &&
                    slotProps.data.med_contelefonica < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_contelefonica"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_contelefonica + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_energia" header="ENERGÍA ELECTRICA">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_energia > 36"
                  severity="danger"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 26 &&
                    slotProps.data.med_energia < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 18 &&
                    slotProps.data.med_energia < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 8 &&
                    slotProps.data.med_energia < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_energia + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_energia > 29"
                  severity="danger"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 21 &&
                    slotProps.data.med_energia < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 13 &&
                    slotProps.data.med_energia < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 5 &&
                    slotProps.data.med_energia < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_energia + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_energia > 24"
                  severity="danger"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 18 &&
                    slotProps.data.med_energia < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 12 &&
                    slotProps.data.med_energia < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 5 &&
                    slotProps.data.med_energia < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_energia + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_energia > 16"
                  severity="danger"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 11 &&
                    slotProps.data.med_energia < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 7 &&
                    slotProps.data.med_energia < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 3 &&
                    slotProps.data.med_energia < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_energia + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_energia > 16"
                  severity="danger"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 12 &&
                    slotProps.data.med_energia < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 8 &&
                    slotProps.data.med_energia < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 4 &&
                    slotProps.data.med_energia < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_energia + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_energia > 8"
                  severity="danger"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 6 &&
                    slotProps.data.med_energia <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 4 &&
                    slotProps.data.med_energia < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_energia > 1 &&
                    slotProps.data.med_energia < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_energia"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_energia + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_aguapotable" header="AGUA POTABLE">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_aguapotable > 36"
                  severity="danger"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 26 &&
                    slotProps.data.med_aguapotable < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 18 &&
                    slotProps.data.med_aguapotable < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 8 &&
                    slotProps.data.med_aguapotable < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_aguapotable + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_aguapotable > 29"
                  severity="danger"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 21 &&
                    slotProps.data.med_aguapotable < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 13 &&
                    slotProps.data.med_aguapotable < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 5 &&
                    slotProps.data.med_aguapotable < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_aguapotable + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_aguapotable > 24"
                  severity="danger"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 18 &&
                    slotProps.data.med_aguapotable < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 12 &&
                    slotProps.data.med_aguapotable < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 5 &&
                    slotProps.data.med_aguapotable < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_aguapotable + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_aguapotable > 16"
                  severity="danger"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 11 &&
                    slotProps.data.med_aguapotable < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 7 &&
                    slotProps.data.med_aguapotable < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 3 &&
                    slotProps.data.med_aguapotable < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_aguapotable + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_aguapotable > 16"
                  severity="danger"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 12 &&
                    slotProps.data.med_aguapotable < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 8 &&
                    slotProps.data.med_aguapotable < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 4 &&
                    slotProps.data.med_aguapotable < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_aguapotable + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_aguapotable > 8"
                  severity="danger"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 6 &&
                    slotProps.data.med_aguapotable <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 4 &&
                    slotProps.data.med_aguapotable < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_aguapotable > 1 &&
                    slotProps.data.med_aguapotable < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_aguapotable"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_aguapotable + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_transporte" header="TRANSPORTE">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_transporte > 36"
                  severity="danger"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 26 &&
                    slotProps.data.med_transporte < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 18 &&
                    slotProps.data.med_transporte < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 8 &&
                    slotProps.data.med_transporte < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_transporte + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_transporte > 29"
                  severity="danger"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 21 &&
                    slotProps.data.med_transporte < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 13 &&
                    slotProps.data.med_transporte < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 5 &&
                    slotProps.data.med_transporte < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_transporte + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_transporte > 24"
                  severity="danger"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 18 &&
                    slotProps.data.med_transporte < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 12 &&
                    slotProps.data.med_transporte < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 5 &&
                    slotProps.data.med_transporte < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_transporte + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_transporte > 16"
                  severity="danger"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 11 &&
                    slotProps.data.med_transporte < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 7 &&
                    slotProps.data.med_transporte < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 3 &&
                    slotProps.data.med_transporte < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_transporte + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_transporte > 16"
                  severity="danger"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 12 &&
                    slotProps.data.med_transporte < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 8 &&
                    slotProps.data.med_transporte < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 4 &&
                    slotProps.data.med_transporte < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_transporte + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_transporte > 8"
                  severity="danger"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 6 &&
                    slotProps.data.med_transporte <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 4 &&
                    slotProps.data.med_transporte < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_transporte > 1 &&
                    slotProps.data.med_transporte < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_transporte"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_transporte + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_guicertificado" header="GUIANZA CERTIFICADA">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_guicertificado > 36"
                  severity="danger"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 26 &&
                    slotProps.data.med_guicertificado < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 18 &&
                    slotProps.data.med_guicertificado < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 8 &&
                    slotProps.data.med_guicertificado < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guicertificado + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_guicertificado > 29"
                  severity="danger"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 21 &&
                    slotProps.data.med_guicertificado < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 13 &&
                    slotProps.data.med_guicertificado < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 5 &&
                    slotProps.data.med_guicertificado < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guicertificado + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_guicertificado > 24"
                  severity="danger"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 18 &&
                    slotProps.data.med_guicertificado < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 12 &&
                    slotProps.data.med_guicertificado < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 5 &&
                    slotProps.data.med_guicertificado < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guicertificado + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_guicertificado > 16"
                  severity="danger"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 11 &&
                    slotProps.data.med_guicertificado < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 7 &&
                    slotProps.data.med_guicertificado < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 3 &&
                    slotProps.data.med_guicertificado < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guicertificado + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_guicertificado > 16"
                  severity="danger"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 12 &&
                    slotProps.data.med_guicertificado < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 8 &&
                    slotProps.data.med_guicertificado < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 4 &&
                    slotProps.data.med_guicertificado < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guicertificado + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_guicertificado > 8"
                  severity="danger"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 6 &&
                    slotProps.data.med_guicertificado <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 4 &&
                    slotProps.data.med_guicertificado < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guicertificado > 1 &&
                    slotProps.data.med_guicertificado < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_guicertificado"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guicertificado + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_guilocal" header="GUIANZA LOCAL">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_guilocal > 36"
                  severity="danger"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 26 &&
                    slotProps.data.med_guilocal < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 18 &&
                    slotProps.data.med_guilocal < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 8 &&
                    slotProps.data.med_guilocal < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guilocal + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_guilocal > 29"
                  severity="danger"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 21 &&
                    slotProps.data.med_guilocal < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 13 &&
                    slotProps.data.med_guilocal < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 5 &&
                    slotProps.data.med_guilocal < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guilocal + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_guilocal > 24"
                  severity="danger"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 18 &&
                    slotProps.data.med_guilocal < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 12 &&
                    slotProps.data.med_guilocal < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 5 &&
                    slotProps.data.med_guilocal < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guilocal + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_guilocal > 16"
                  severity="danger"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 11 &&
                    slotProps.data.med_guilocal < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 7 &&
                    slotProps.data.med_guilocal < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 3 &&
                    slotProps.data.med_guilocal < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guilocal + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_guilocal > 16"
                  severity="danger"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 12 &&
                    slotProps.data.med_guilocal < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 8 &&
                    slotProps.data.med_guilocal < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 4 &&
                    slotProps.data.med_guilocal < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guilocal + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_guilocal > 8"
                  severity="danger"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 6 &&
                    slotProps.data.med_guilocal <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 4 &&
                    slotProps.data.med_guilocal < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guilocal > 1 &&
                    slotProps.data.med_guilocal < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_guilocal"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guilocal + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column
            field="med_guisegidioma"
            header="GUIANZA EN UN SEGUNDO IDIOMA"
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_guisegidioma > 36"
                  severity="danger"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 26 &&
                    slotProps.data.med_guisegidioma < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 18 &&
                    slotProps.data.med_guisegidioma < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 8 &&
                    slotProps.data.med_guisegidioma < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guisegidioma + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_guisegidioma > 29"
                  severity="danger"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 21 &&
                    slotProps.data.med_guisegidioma < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 13 &&
                    slotProps.data.med_guisegidioma < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 5 &&
                    slotProps.data.med_guisegidioma < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guisegidioma + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_guisegidioma > 24"
                  severity="danger"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 18 &&
                    slotProps.data.med_guisegidioma < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 12 &&
                    slotProps.data.med_guisegidioma < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 5 &&
                    slotProps.data.med_guisegidioma < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guisegidioma + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_guisegidioma > 16"
                  severity="danger"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 11 &&
                    slotProps.data.med_guisegidioma < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 7 &&
                    slotProps.data.med_guisegidioma < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 3 &&
                    slotProps.data.med_guisegidioma < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guisegidioma + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_guisegidioma > 16"
                  severity="danger"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 12 &&
                    slotProps.data.med_guisegidioma < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 8 &&
                    slotProps.data.med_guisegidioma < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 4 &&
                    slotProps.data.med_guisegidioma < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guisegidioma + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_guisegidioma > 8"
                  severity="danger"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 6 &&
                    slotProps.data.med_guisegidioma <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 4 &&
                    slotProps.data.med_guisegidioma < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_guisegidioma > 1 &&
                    slotProps.data.med_guisegidioma < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_guisegidioma"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_guisegidioma + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_esptrabajo" header="ESPACIOS DE TRABAJO">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_esptrabajo > 36"
                  severity="danger"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 26 &&
                    slotProps.data.med_esptrabajo < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 18 &&
                    slotProps.data.med_esptrabajo < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 8 &&
                    slotProps.data.med_esptrabajo < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_esptrabajo + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_esptrabajo > 29"
                  severity="danger"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 21 &&
                    slotProps.data.med_esptrabajo < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 13 &&
                    slotProps.data.med_esptrabajo < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 5 &&
                    slotProps.data.med_esptrabajo < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_esptrabajo + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_esptrabajo > 24"
                  severity="danger"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 18 &&
                    slotProps.data.med_esptrabajo < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 12 &&
                    slotProps.data.med_esptrabajo < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 5 &&
                    slotProps.data.med_esptrabajo < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_esptrabajo + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_esptrabajo > 16"
                  severity="danger"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 11 &&
                    slotProps.data.med_esptrabajo < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 7 &&
                    slotProps.data.med_esptrabajo < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 3 &&
                    slotProps.data.med_esptrabajo < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_esptrabajo + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_esptrabajo > 16"
                  severity="danger"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 12 &&
                    slotProps.data.med_esptrabajo < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 8 &&
                    slotProps.data.med_esptrabajo < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 4 &&
                    slotProps.data.med_esptrabajo < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_esptrabajo + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_esptrabajo > 8"
                  severity="danger"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 6 &&
                    slotProps.data.med_esptrabajo <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 4 &&
                    slotProps.data.med_esptrabajo < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_esptrabajo > 1 &&
                    slotProps.data.med_esptrabajo < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_esptrabajo"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_esptrabajo + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_equipos" header="EQUIPOS">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_equipos > 36"
                  severity="danger"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 26 &&
                    slotProps.data.med_equipos < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 18 &&
                    slotProps.data.med_equipos < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 8 &&
                    slotProps.data.med_equipos < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_equipos + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_equipos > 29"
                  severity="danger"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 21 &&
                    slotProps.data.med_equipos < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 13 &&
                    slotProps.data.med_equipos < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 5 &&
                    slotProps.data.med_equipos < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_equipos + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_equipos > 24"
                  severity="danger"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 18 &&
                    slotProps.data.med_equipos < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 12 &&
                    slotProps.data.med_equipos < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 5 &&
                    slotProps.data.med_equipos < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_equipos + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_equipos > 16"
                  severity="danger"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 11 &&
                    slotProps.data.med_equipos < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 7 &&
                    slotProps.data.med_equipos < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 3 &&
                    slotProps.data.med_equipos < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_equipos + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_equipos > 16"
                  severity="danger"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 12 &&
                    slotProps.data.med_equipos < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 8 &&
                    slotProps.data.med_equipos < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 4 &&
                    slotProps.data.med_equipos < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_equipos + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_equipos > 8"
                  severity="danger"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 6 &&
                    slotProps.data.med_equipos <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 4 &&
                    slotProps.data.med_equipos < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_equipos > 1 &&
                    slotProps.data.med_equipos < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_equipos"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_equipos + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column field="med_materiales" header="MATERIALES">
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_materiales > 36"
                  severity="danger"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 26 &&
                    slotProps.data.med_materiales < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 18 &&
                    slotProps.data.med_materiales < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 8 &&
                    slotProps.data.med_materiales < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_materiales + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_materiales > 29"
                  severity="danger"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 21 &&
                    slotProps.data.med_materiales < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 13 &&
                    slotProps.data.med_materiales < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 5 &&
                    slotProps.data.med_materiales < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_materiales + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_materiales > 24"
                  severity="danger"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 18 &&
                    slotProps.data.med_materiales < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 12 &&
                    slotProps.data.med_materiales < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 5 &&
                    slotProps.data.med_materiales < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_materiales + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_materiales > 16"
                  severity="danger"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 11 &&
                    slotProps.data.med_materiales < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 7 &&
                    slotProps.data.med_materiales < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 3 &&
                    slotProps.data.med_materiales < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_materiales + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_materiales > 16"
                  severity="danger"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 12 &&
                    slotProps.data.med_materiales < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 8 &&
                    slotProps.data.med_materiales < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 4 &&
                    slotProps.data.med_materiales < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_materiales + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_materiales > 8"
                  severity="danger"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 6 &&
                    slotProps.data.med_materiales <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 4 &&
                    slotProps.data.med_materiales < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_materiales > 1 &&
                    slotProps.data.med_materiales < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_materiales"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_materiales + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>

          <Column
            field="med_actcomplementarias"
            header="ACTIVIDADES COMPLEMENTARIAS"
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.contador > 40">
                <Badge
                  v-if="slotProps.data.med_actcomplementarias > 36"
                  severity="danger"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 26 &&
                    slotProps.data.med_actcomplementarias < 37
                  "
                  severity="warning"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 18 &&
                    slotProps.data.med_actcomplementarias < 27
                  "
                  severity="success"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 8 &&
                    slotProps.data.med_actcomplementarias < 19
                  "
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 35 && slotProps.data.contador < 40
                "
              >
                <Badge
                  v-if="slotProps.data.med_actcomplementarias > 29"
                  severity="danger"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 21 &&
                    slotProps.data.med_actcomplementarias < 30
                  "
                  severity="warning"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 13 &&
                    slotProps.data.med_actcomplementarias < 22
                  "
                  severity="success"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 5 &&
                    slotProps.data.med_actcomplementarias < 14
                  "
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 25 && slotProps.data.contador < 35
                "
              >
                <Badge
                  v-if="slotProps.data.med_actcomplementarias > 24"
                  severity="danger"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 18 &&
                    slotProps.data.med_actcomplementarias < 25
                  "
                  severity="warning"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 12 &&
                    slotProps.data.med_actcomplementarias < 19
                  "
                  severity="success"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 5 &&
                    slotProps.data.med_actcomplementarias < 13
                  "
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 20 && slotProps.data.contador < 25
                "
              >
                <Badge
                  v-if="slotProps.data.med_actcomplementarias > 16"
                  severity="danger"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 11 &&
                    slotProps.data.med_actcomplementarias < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 7 &&
                    slotProps.data.med_actcomplementarias < 12
                  "
                  severity="success"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 3 &&
                    slotProps.data.med_actcomplementarias < 8
                  "
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias + ' 👎🏻' "
                ></Badge>
              </div>
              <div
                v-else-if="
                  slotProps.data.contador > 15 && slotProps.data.contador < 20
                "
              >
                <Badge
                  v-if="slotProps.data.med_actcomplementarias > 16"
                  severity="danger"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 12 &&
                    slotProps.data.med_actcomplementarias < 17
                  "
                  severity="warning"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 8 &&
                    slotProps.data.med_actcomplementarias < 13
                  "
                  severity="success"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 4 &&
                    slotProps.data.med_actcomplementarias < 9
                  "
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias + ' 👎🏻' "
                ></Badge>
              </div>
              <div v-else>
                <Badge
                  v-if="slotProps.data.med_actcomplementarias > 8"
                  severity="danger"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 6 &&
                    slotProps.data.med_actcomplementarias <= 8
                  "
                  severity="warning"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 4 &&
                    slotProps.data.med_actcomplementarias < 7
                  "
                  severity="success"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else-if="
                    slotProps.data.med_actcomplementarias > 1 &&
                    slotProps.data.med_actcomplementarias < 5
                  "
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias"
                ></Badge>
                <Badge
                  v-else
                  severity="info"
                  :value="slotProps.data.med_actcomplementarias + ' 👎🏻' "
                ></Badge>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

            <div class="col-12">
        <!-- TOTALES -->
        <DataTable
          :value="sumatorias"
          v-show="!cargaskeletonsumatorias"
          responsiveLayout="scroll"
          :paginator="true"
          :rows="5"
        >
          <Column field="med_total" header=""></Column>
          <Column
            field="med_objinvestigacion"
            header="OBJETIVO DE LA INVESTIGACIÓN"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_objinvestigacion > 203"
                severity="danger"
                :value="slotProps.data.med_objinvestigacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_objinvestigacion > 153 &&
                  slotProps.data.med_objinvestigacion < 204
                "
                severity="warning"
                :value="slotProps.data.med_objinvestigacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_objinvestigacion > 102 &&
                  slotProps.data.med_objinvestigacion < 154
                "
                severity="success"
                :value="slotProps.data.med_objinvestigacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_objinvestigacion > 50 &&
                  slotProps.data.med_objinvestigacion < 103
                "
                severity="info"
                :value="slotProps.data.med_objinvestigacion"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_objinvestigacion"
              ></Badge>
            </template>
          </Column>
          <Column field="med_monitoreo" header="MONITOREO">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_monitoreo > 203"
                severity="danger"
                :value="slotProps.data.med_monitoreo"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_monitoreo > 153 &&
                  slotProps.data.med_monitoreo < 204
                "
                severity="warning"
                :value="slotProps.data.med_monitoreo"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_monitoreo > 102 &&
                  slotProps.data.med_monitoreo < 154
                "
                severity="success"
                :value="slotProps.data.med_monitoreo"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_monitoreo > 50 &&
                  slotProps.data.med_monitoreo < 103
                "
                severity="info"
                :value="slotProps.data.med_monitoreo"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_monitoreo"
              ></Badge>
            </template>
          </Column>
          <Column field="med_estconservacion" header="ESTADO DE CONSERVACIÓN">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_estconservacion > 203"
                severity="danger"
                :value="slotProps.data.med_estconservacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_estconservacion > 153 &&
                  slotProps.data.med_estconservacion < 204
                "
                severity="warning"
                :value="slotProps.data.med_estconservacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_estconservacion > 102 &&
                  slotProps.data.med_estconservacion < 154
                "
                severity="success"
                :value="slotProps.data.med_estconservacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_estconservacion > 50 &&
                  slotProps.data.med_estconservacion < 103
                "
                severity="info"
                :value="slotProps.data.med_estconservacion"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_estconservacion"
              ></Badge>
            </template>
          </Column>
          <Column field="med_seguridad" header="SEGURIDAD">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_seguridad > 203"
                severity="danger"
                :value="slotProps.data.med_seguridad"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_seguridad > 153 &&
                  slotProps.data.med_seguridad < 204
                "
                severity="warning"
                :value="slotProps.data.med_seguridad"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_seguridad > 102 &&
                  slotProps.data.med_seguridad < 154
                "
                severity="success"
                :value="slotProps.data.med_seguridad"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_seguridad > 50 &&
                  slotProps.data.med_seguridad < 103
                "
                severity="info"
                :value="slotProps.data.med_seguridad"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_seguridad"
              ></Badge>
            </template>
          </Column>
          <Column field="med_repcientifica" header="REPUTACIÓN CIENTÍFICA">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_repcientifica > 203"
                severity="danger"
                :value="slotProps.data.med_repcientifica"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_repcientifica > 153 &&
                  slotProps.data.med_repcientifica < 204
                "
                severity="warning"
                :value="slotProps.data.med_repcientifica"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_repcientifica > 102 &&
                  slotProps.data.med_repcientifica < 154
                "
                severity="success"
                :value="slotProps.data.med_repcientifica"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_repcientifica > 50 &&
                  slotProps.data.med_repcientifica < 103
                "
                severity="info"
                :value="slotProps.data.med_repcientifica"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_repcientifica"
              ></Badge>
            </template>
          </Column>
          <Column field="med_colecta" header="COLECTA">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_colecta > 203"
                severity="danger"
                :value="slotProps.data.med_colecta"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_colecta > 153 &&
                  slotProps.data.med_colecta < 204
                "
                severity="warning"
                :value="slotProps.data.med_colecta"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_colecta > 102 &&
                  slotProps.data.med_colecta < 154
                "
                severity="success"
                :value="slotProps.data.med_colecta"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_colecta > 50 &&
                  slotProps.data.med_colecta < 103
                "
                severity="info"
                :value="slotProps.data.med_colecta"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_colecta"
              ></Badge>
            </template>
          </Column>
          <Column
            field="med_conprestadores"
            header="NIVEL DE CONOCIMIENTO DE LOS PRESTADORES DE SERVICIO"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_conprestadores > 203"
                severity="danger"
                :value="slotProps.data.med_conprestadores"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_conprestadores > 153 &&
                  slotProps.data.med_conprestadores < 204
                "
                severity="warning"
                :value="slotProps.data.med_conprestadores"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_conprestadores > 102 &&
                  slotProps.data.med_conprestadores < 154
                "
                severity="success"
                :value="slotProps.data.med_conprestadores"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_conprestadores > 50 &&
                  slotProps.data.med_conprestadores < 103
                "
                severity="info"
                :value="slotProps.data.med_conprestadores"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_conprestadores"
              ></Badge>
            </template>
          </Column>
          <Column
            field="med_usodatos"
            header="USO DE DATOS Y DIVULGACIÓN A LAS COMUNIDADES Y GREMIO"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_usodatos > 203"
                severity="danger"
                :value="slotProps.data.med_usodatos"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_usodatos > 153 &&
                  slotProps.data.med_usodatos < 204
                "
                severity="warning"
                :value="slotProps.data.med_usodatos"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_usodatos > 102 &&
                  slotProps.data.med_usodatos < 154
                "
                severity="success"
                :value="slotProps.data.med_usodatos"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_usodatos > 50 &&
                  slotProps.data.med_usodatos < 103
                "
                severity="info"
                :value="slotProps.data.med_usodatos"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_usodatos"
              ></Badge>
            </template>
          </Column>
          <Column field="med_alojamiento" header="ALOJAMIENTO">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_alojamiento > 203"
                severity="danger"
                :value="slotProps.data.med_alojamiento"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_alojamiento > 153 &&
                  slotProps.data.med_alojamiento < 204
                "
                severity="warning"
                :value="slotProps.data.med_alojamiento"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_alojamiento > 102 &&
                  slotProps.data.med_alojamiento < 154
                "
                severity="success"
                :value="slotProps.data.med_alojamiento"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_alojamiento > 50 &&
                  slotProps.data.med_alojamiento < 103
                "
                severity="info"
                :value="slotProps.data.med_alojamiento"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_alojamiento"
              ></Badge>
            </template>
          </Column>
          <Column field="med_alimentacion" header="ALIMENTACIÓN">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_alimentacion > 203"
                severity="danger"
                :value="slotProps.data.med_alimentacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_alimentacion > 153 &&
                  slotProps.data.med_alimentacion < 204
                "
                severity="warning"
                :value="slotProps.data.med_alimentacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_alimentacion > 102 &&
                  slotProps.data.med_alimentacion < 154
                "
                severity="success"
                :value="slotProps.data.med_alimentacion"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_alimentacion > 50 &&
                  slotProps.data.med_alimentacion < 103
                "
                severity="info"
                :value="slotProps.data.med_alimentacion"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_alimentacion"
              ></Badge>
            </template>
          </Column>
          <Column field="med_wifi" header="WIFI">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_wifi > 203"
                severity="danger"
                :value="slotProps.data.med_wifi"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_wifi > 153 && slotProps.data.med_wifi < 204
                "
                severity="warning"
                :value="slotProps.data.med_wifi"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_wifi > 102 && slotProps.data.med_wifi < 154
                "
                severity="success"
                :value="slotProps.data.med_wifi"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_wifi > 50 && slotProps.data.med_wifi < 103
                "
                severity="info"
                :value="slotProps.data.med_wifi"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_wifi"
              ></Badge>
            </template>
          </Column>

          <Column field="med_contelefonica" header="CONEXIÓN TELEFÓNICA">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_contelefonica > 203"
                severity="danger"
                :value="slotProps.data.med_contelefonica"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_contelefonica > 153 &&
                  slotProps.data.med_contelefonica < 204
                "
                severity="warning"
                :value="slotProps.data.med_contelefonica"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_contelefonica > 102 &&
                  slotProps.data.med_contelefonica < 154
                "
                severity="success"
                :value="slotProps.data.med_contelefonica"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_contelefonica > 50 &&
                  slotProps.data.med_contelefonica < 103
                "
                severity="info"
                :value="slotProps.data.med_contelefonica"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_contelefonica"
              ></Badge>
            </template>
          </Column>

          <Column field="med_energia" header="ENERGÍA ELECTRICA">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_energia > 203"
                severity="danger"
                :value="slotProps.data.med_energia"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_energia > 153 &&
                  slotProps.data.med_energia < 204
                "
                severity="warning"
                :value="slotProps.data.med_energia"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_energia > 102 &&
                  slotProps.data.med_energia < 154
                "
                severity="success"
                :value="slotProps.data.med_energia"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_energia > 50 &&
                  slotProps.data.med_energia < 103
                "
                severity="info"
                :value="slotProps.data.med_energia"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_energia"
              ></Badge>
            </template>
          </Column>

          <Column field="med_aguapotable" header="AGUA POTABLE">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_aguapotable > 203"
                severity="danger"
                :value="slotProps.data.med_aguapotable"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_aguapotable > 153 &&
                  slotProps.data.med_aguapotable < 204
                "
                severity="warning"
                :value="slotProps.data.med_aguapotable"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_aguapotable > 102 &&
                  slotProps.data.med_aguapotable < 154
                "
                severity="success"
                :value="slotProps.data.med_aguapotable"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_aguapotable > 50 &&
                  slotProps.data.med_aguapotable < 103
                "
                severity="info"
                :value="slotProps.data.med_aguapotable"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_aguapotable"
              ></Badge>
            </template>
          </Column>

          <Column field="med_transporte" header="TRANSPORTE">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_transporte > 203"
                severity="danger"
                :value="slotProps.data.med_transporte"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_transporte > 153 &&
                  slotProps.data.med_transporte < 204
                "
                severity="warning"
                :value="slotProps.data.med_transporte"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_transporte > 102 &&
                  slotProps.data.med_transporte < 154
                "
                severity="success"
                :value="slotProps.data.med_transporte"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_transporte > 50 &&
                  slotProps.data.med_transporte < 103
                "
                severity="info"
                :value="slotProps.data.med_transporte"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_transporte"
              ></Badge>
            </template>
          </Column>

          <Column field="med_guicertificado" header="GUIANZA CERTIFICADA">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_guicertificado > 203"
                severity="danger"
                :value="slotProps.data.med_guicertificado"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guicertificado > 153 &&
                  slotProps.data.med_guicertificado < 204
                "
                severity="warning"
                :value="slotProps.data.med_guicertificado"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guicertificado > 102 &&
                  slotProps.data.med_guicertificado < 154
                "
                severity="success"
                :value="slotProps.data.med_guicertificado"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guicertificado > 50 &&
                  slotProps.data.med_guicertificado < 103
                "
                severity="info"
                :value="slotProps.data.med_guicertificado"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_guicertificado"
              ></Badge>
            </template>
          </Column>

          <Column field="med_guilocal" header="GUIANZA LOCAL">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_guilocal > 203"
                severity="danger"
                :value="slotProps.data.med_guilocal"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guilocal > 153 &&
                  slotProps.data.med_guilocal < 204
                "
                severity="warning"
                :value="slotProps.data.med_guilocal"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guilocal > 102 &&
                  slotProps.data.med_guilocal < 154
                "
                severity="success"
                :value="slotProps.data.med_guilocal"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guilocal > 50 &&
                  slotProps.data.med_guilocal < 103
                "
                severity="info"
                :value="slotProps.data.med_guilocal"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_guilocal"
              ></Badge>
            </template>
          </Column>

          <Column
            field="med_guisegidioma"
            header="GUIANZA EN UN SEGUNDO IDIOMA"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_guisegidioma > 203"
                severity="danger"
                :value="slotProps.data.med_guisegidioma"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guisegidioma > 153 &&
                  slotProps.data.med_guisegidioma < 204
                "
                severity="warning"
                :value="slotProps.data.med_guisegidioma"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guisegidioma > 102 &&
                  slotProps.data.med_guisegidioma < 154
                "
                severity="success"
                :value="slotProps.data.med_guisegidioma"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_guisegidioma > 50 &&
                  slotProps.data.med_guisegidioma < 103
                "
                severity="info"
                :value="slotProps.data.med_guisegidioma"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_guisegidioma"
              ></Badge>
            </template>
          </Column>

          <Column field="med_esptrabajo" header="ESPACIOS DE TRABAJO">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_esptrabajo > 203"
                severity="danger"
                :value="slotProps.data.med_esptrabajo"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_esptrabajo > 153 &&
                  slotProps.data.med_esptrabajo < 204
                "
                severity="warning"
                :value="slotProps.data.med_esptrabajo"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_esptrabajo > 102 &&
                  slotProps.data.med_esptrabajo < 154
                "
                severity="success"
                :value="slotProps.data.med_esptrabajo"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_esptrabajo > 50 &&
                  slotProps.data.med_esptrabajo < 103
                "
                severity="info"
                :value="slotProps.data.med_esptrabajo"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_esptrabajo"
              ></Badge>
            </template>
          </Column>

          <Column field="med_equipos" header="EQUIPOS">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_equipos > 203"
                severity="danger"
                :value="slotProps.data.med_equipos"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_equipos > 153 &&
                  slotProps.data.med_equipos < 204
                "
                severity="warning"
                :value="slotProps.data.med_equipos"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_equipos > 102 &&
                  slotProps.data.med_equipos < 154
                "
                severity="success"
                :value="slotProps.data.med_equipos"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_equipos > 50 &&
                  slotProps.data.med_equipos < 103
                "
                severity="info"
                :value="slotProps.data.med_equipos"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_equipos"
              ></Badge>
            </template>
          </Column>

          <Column field="med_materiales" header="MATERIALES">
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_materiales > 203"
                severity="danger"
                :value="slotProps.data.med_materiales"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_materiales > 153 &&
                  slotProps.data.med_materiales < 204
                "
                severity="warning"
                :value="slotProps.data.med_materiales"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_materiales > 102 &&
                  slotProps.data.med_materiales < 154
                "
                severity="success"
                :value="slotProps.data.med_materiales"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_materiales > 50 &&
                  slotProps.data.med_materiales < 103
                "
                severity="info"
                :value="slotProps.data.med_materiales"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_materiales"
              ></Badge>
            </template>
          </Column>

          <Column
            field="med_actcomplementarias"
            header="ACTIVIDADES COMPLEMENTARIAS"
          >
            <template #body="slotProps">
              <Badge
                v-if="slotProps.data.med_actcomplementarias > 203"
                severity="danger"
                :value="slotProps.data.med_actcomplementarias"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_actcomplementarias > 153 &&
                  slotProps.data.med_actcomplementarias < 204
                "
                severity="warning"
                :value="slotProps.data.med_actcomplementarias"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_actcomplementarias > 102 &&
                  slotProps.data.med_actcomplementarias < 154
                "
                severity="success"
                :value="slotProps.data.med_actcomplementarias"
              ></Badge>
              <Badge
                v-else-if="
                  slotProps.data.med_actcomplementarias > 50 &&
                  slotProps.data.med_actcomplementarias < 103
                "
                severity="info"
                :value="slotProps.data.med_actcomplementarias"
              ></Badge>
              <Badge
                v-else
                severity="secondary"
                :value="slotProps.data.med_actcomplementarias"
              ></Badge>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="col-12">
        <Toast />
      </div>
    </div>
    
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "Cliente",
  data() {
    return {
      criterios: [],
      sumatorias: [],
      sumatoriasclasificadas: [],
      cargaskeletonsumatorias: false,
      cargaskeletoncriterios: false,
      cargaskeletonsumatoriasclasificadas: false,
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    getcriterios: async function () {
      try {
        this.cargaskeletoncriterios = true;
        let self = this;

        await this.axios
          .post("demanda.php?dato=getcriterios")
          .then(function (response) {
            if (response.status === 200) {
              self.criterios = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletoncriterios = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getsumatorias: async function () {
      try {
        this.cargaskeletonsumatorias = true;
        let self = this;

        await this.axios
          .post("demanda.php?dato=getsumatorias")
          .then(function (response) {
            if (response.status === 200) {
              self.sumatorias = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonsumatorias = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getsumatoriasclasificadas: async function () {
      try {
        this.cargaskeletonsumatoriasclasificadas = true;
        let self = this;

        await this.axios
          .post("demanda.php?dato=getsumatoriasclasificadas")
          .then(function (response) {
            if (response.status === 200) {
              self.sumatoriasclasificadas = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonsumatoriasclasificadas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },
  },

  computed: {},

  created: function () {
    this.getcriterios();
    this.getsumatorias();
    this.getsumatoriasclasificadas();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>
