<template>
  <div>
    <NavbarMain />

    <div class="container mt-4">
      <div class="grid">
        <div class="col-12">
          <DataTable
            :value="registrosdeforestacion"
            v-show="!cargaskeletonregistrosdef"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
          >
            <template #header>
              <div class="table-header">
                Registros de Deforestación

                <div class="p-inputgroup mr-4">
                  <InputText
                    placeholder="Buscar x nombre de finca"
                    v-model="registrodef.filtro"
                    @keyup.enter="filtrarregistrosdef"
                  />
                  <Button
                    icon="pi pi-search"
                    class="p-button-success"
                    @click="filtrarregistrosdef"
                  />
                </div>

                <Button icon="pi pi-plus" @click="openmodalregistrodef()" />
              </div>
            </template>

            <Column field="regdef_causa" header="Causa"></Column>
            <Column field="regdef_fecha" header="Fecha"></Column>
            <Column field="finca.fin_nombre" header="finca"></Column>
            <Column>
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-primary mr-2"
                  @click="listarmodalregistrodef(slotProps.data)"
                /> </template
            ></Column>
          </DataTable>
        </div>
        <div class="col-12">
          <DataTable :value="skeletons" v-show="cargaskeletonregistrosdef">
            <Column field="ske1" header="Deforestación">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske2" header="Fecha">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske3" header="Finca">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske4" header="">
              <template #body>
                <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible.sync="showmodalregistrodef"
      position="center"
      :modal="true"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ registrodef.titulomodal }} registro deforestación</h4>
      </template>
      <div>
        <div class="grid" v-show="!registrodef.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Finca</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Dropdown
                v-model="registrodef.regdef_finca"
                :options="fincascombo"
                optionLabel="fin_nombre"
                placeholder="Seleccionar una finca"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!registrodef.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Latitud</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Latitud: 11.106014"
                v-model="registrodef.regdef_latitud"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!registrodef.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Longitud</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Longitud: -74.1217839"
                v-model="registrodef.regdef_longitud"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!registrodef.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Fecha de registro</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <!--
              <Calendar
                id="icon"
                placeholder="Fecha de registro: 2022-01-01"
                :maxDate="today"
                v-model="registrodef.regdef_fecha"
                :showIcon="true"
                dateFormat="yy-mm-dd"
                autocomplete="off"
              />
              -->
              <b-form-datepicker
                v-model="registrodef.regdef_fecha"
                class="mb-0"
                label-no-date-selected="Fecha registro"
                label-help="Use las teclas del cursor para navegar por las fechas del calendario"
              >
              </b-form-datepicker>
            </div>
          </div>
        </div>

        <div class="grid" v-show="!registrodef.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Hectareas</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Hectareas: 100"
                v-model="registrodef.regdef_hectareas"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!registrodef.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Causa</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Causa: Incendio"
                v-model="registrodef.regdef_causa"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="registrodef.viewlink">
          <div class="col-12">
            <div class="p-inputgroup">
              <b-link :href="registrodef.linkgoogle" target="_blank"
                >Ver Mapa</b-link
              >
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="registrodef.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="registrodef.boton"
          icon="pi pi-check"
          autofocus
          @click="registrarnuevadeforestacion"
        />
      </template>
    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
import * as moment from "moment";

export default {
  name: "Registrodef",
  data() {
    return {
      registrodef: {
        regdef_id: 0,
        regdef_latitud: null,
        regdef_longitud: null,
        regdef_fecha: "",
        regdef_hectareas: null,
        regdef_causa: "",
        regdef_finca: null,

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      },

      skeletons: this.global.skeletons,
      meses: this.global.meses,

      today: new Date(this.global.today),

      cargaskeletonregistrosdef: false,
      showmodalregistrodef: false,

      registrosdeforestacion: [],
      fincascombo: [],
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    registrarnuevadeforestacion: async function () {
      try {
        var mensaje = this.validarRegistrarDef;

        if (mensaje === "") {
          this.registrodef.boton = " Guardando... ";
          this.registrodef.wait = true;

          var parametros = {
            regdef_id: this.registrodef.regdef_id,
            regdef_latitud: this.registrodef.regdef_latitud,
            regdef_longitud: this.registrodef.regdef_longitud,
            regdef_fecha: this.registrodef.regdef_fecha,
            regdef_hectareas: this.registrodef.regdef_hectareas,
            regdef_causa: this.registrodef.regdef_causa,
            regdef_finca: this.registrodef.regdef_finca.fin_id,
          };

          await this.axios
            .post(
              "deforestacion.php?dato=nuevoregistrodef",
              JSON.stringify(parametros)
            )
            .then((response) => {
              if (response.status === 200) {
                if (response.data.includes(this.global.transaccionOK)) {
                  this.mensajeOK(response.data);
                  this.limpiarCamposNuevoRegistro();
                  this.filtrarregistrosdef();
                } else if (this.global.buscarapierror(response.data) !== "") {
                  let msj_user = this.global.buscarapierror(response.data);
                  this.mensajeWar(msj_user);
                } else {
                  this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                }
              } else {
                this.global.mensajeErr(this.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              this.registrodef.boton = " Guardar ";
              this.registrodef.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtrarregistrosdef: async function () {
      try {
        this.cargaskeletonregistrosdef = true;
        let self = this;

        var parametros = {
          filtro: this.registrodef.filtro,
        };

        await this.axios
          .post(
            "deforestacion.php?dato=getregistrosdef",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.registrosdeforestacion = response.data;
              if (self.registrosdeforestacion.length === 0) {
                self.mensajeWar(
                  "No se encontraron registros de deforestaciones"
                );
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosdef = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getfincas: async function () {
      try {
        this.cargaskeletonregistrosdef = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getfincascombo")
          .then(function (response) {
            if (response.status === 200) {
              self.fincascombo = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosdef = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.registrodef = {
        regdef_id: 0,
        regdef_latitud: null,
        regdef_longitud: null,
        regdef_fecha: "",
        regdef_hectareas: null,
        regdef_causa: "",
        regdef_finca: null,

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      };

      this.showmodalregistrodef = false;
    },

    openmodalregistrodef: function () {
      this.registrodef = {
        regdef_id: 0,
        regdef_latitud: null,
        regdef_longitud: null,
        regdef_fecha: "",
        regdef_hectareas: null,
        regdef_causa: "",
        regdef_finca: null,

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,

        viewlink: false,
        linkgoogle: "",
      };

      this.showmodalregistrodef = true;
    },

    listarmodalregistrodef: async function (data) {
      let fechaparcial = data.regdef_fecha;
      let fecha = new Date(fechaparcial);
      this.registrodef = {
        titulomodal: " Editar ",
        boton: "Editar",
        accion: 1,
        nuevo: false,

        regdef_id: data.regdef_id,
        regdef_latitud: data.regdef_latitud,
        regdef_longitud: data.regdef_longitud,
        regdef_fecha: fecha,
        regdef_hectareas: data.regdef_hectareas,
        regdef_causa: data.regdef_causa,

        viewlink: true,
        linkgoogle:
          "https://www.google.com/maps/@" +
          data.regdef_latitud +
          "," +
          data.regdef_longitud +
          ",15z",

        regdef_finca: this.fincascombo.find(
          (p) => p.fin_id === data.finca.fin_id
        ),
      };

      this.showmodalregistrodef = true;
    },
  },

  computed: {
    validarRegistrarDef() {
      var mensajeAlerta = "";
      if (this.registrodef.regdef_finca === null) {
        mensajeAlerta = " Debe seleccionar una finca ";
      } else if (
        this.registrodef.regdef_latitud === null ||
        Number.isNaN(Number(this.registrodef.regdef_latitud)) ||
        this.registrodef.regdef_latitud > 180 ||
        this.registrodef.regdef_latitud < -180 ||
        this.registrodef.regdef_latitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Latitud válido ej: 11.144433 separando decimales con punto (.) ";
      } else if (
        this.registrodef.regdef_longitud === null ||
        Number.isNaN(Number(this.registrodef.regdef_longitud)) ||
        this.registrodef.regdef_longitud > 180 ||
        this.registrodef.regdef_longitud < -180 ||
        this.registrodef.regdef_longitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Longitud válido ej: -74.118597 separando decimales con punto (.) ";
      } else if (
        !moment(this.registrodef.regdef_fecha, "YYYY-MM-DD", true).isValid()
      ) {
        mensajeAlerta = "Debe digitar una fecha válida";
      } else if (
        !this.global.validarNumeroEntero(this.registrodef.regdef_hectareas)
      ) {
        mensajeAlerta = "Debe digitar un N° de hectareas válido";
      } else if (
        this.registrodef.regdef_hectareas === null ||
        !this.global.validarNumeroEntero(this.registrodef.regdef_hectareas) ||
        this.registrodef.regdef_hectareas < 0 ||
        this.registrodef.regdef_hectareas > 10000 ||
        this.registrodef.regdef_hectareas === ""
      ) {
        mensajeAlerta = "Debe digitar un N° de hectareas válido";
      } else if (
        this.registrodef.regdef_causa === "" ||
        this.registrodef.regdef_causa.length > 100
      ) {
        mensajeAlerta =
          "Debe digitar una causa válida, no debe superar los 100 caracteres";
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    this.getfincas();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
}

.uno {
  margin-right: 20px;
  flex-grow: 10;
}

.dos {
  margin-left: 20px;
  flex-grow: 5;
}

.tres {
  flex-grow: 5;
}

.imagenes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
}

.article {
  height: 100%;
  display: grid;
  place-content: center;
}

.img {
  width: 300px;
  height: 300px;
}
</style>
