<template>
  <div>
    <NavbarMain />
    <div class="container"></div>
    <div class="grid">
        <div class="col-12">
          <Toast />
        </div>
      </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "Index",
  data() {
    return {};
  },
  components: {
    Toast,
    NavbarMain
  },

  methods: {
    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },
  },

  computed: {},

  created: function () {
      this.mensajeOK('Bienvenido: ' + localStorage.getItem('name'));
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }else{
        this.mensajeOK('Bienvenido: ' + localStorage.getItem('name'));
    }
  },
};
</script>
