const project = "https://www.solincosta.com/apiswisscontact/model/";

const transaccionOK = "TRANSACCIÓN EXITOSA";
const transaccionFail = "HA OCURRIDO UN ERROR";

const respuestaInvalida = "La respuesta del servidor no es válida";
const respuestaValidaSinTransaccion =
  "El servidor respondió pero la transacción No es válida";
const errorJavaScript = "Se ha presentado un error en el cliente";

const TYPE_FILE_IMAGE = 1;
const TYPE_FILE_VIDEO = 2;

const today = Date();

const Message = {
  type_success: "success",
  msg_confim: "Mensaje de confirmación",

  type_warm: "warn",
  msg_warm: "Mensaje de alerta",

  type_error: "error",
  msg_error: "Mensaje de error",

  time_short: 2000,
  time_medium: 3000,
  time_long: 400,
};

const skeletons = [
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
  {
    ske1: "...",
    ske2: "...",
    ske3: "...",
    ske4: "...",
  },
];

const meses = [
  { nombre: "Enero", numero: "1" },
  { nombre: "Febrero", numero: "2" },
  { nombre: "Marzo", numero: "3" },
  { nombre: "Abril", numero: "4" },
  { nombre: "Mayo", numero: "5" },
  { nombre: "Junio", numero: "6" },
  { nombre: "Julio", numero: "7" },
  { nombre: "Agosto", numero: "8" },
  { nombre: "Septiembre", numero: "9" },
  { nombre: "Octubre", numero: "10" },
  { nombre: "Noviembre", numero: "11" },
  { nombre: "Diciembre", numero: "12" },
];

function catching(error) {
  console.log("ERROR: " + error);
  console.log("ERROR responseText: " + error.responseText);
  console.log("ERROR STATUS: " + error.status);
  console.log("ERROR TEXT_STATUS: " + error.textStatus);
}

function hello() {
  alert("HELLO");
}

function buscarapierror(msj) {
  const api = [
    {
      code: 700,
      message_error: "llave duplicada viola restricción de unicidad",
      message_ux:
        "El registro que está intentando guardar ya existe, revise los datos ingresados e intente nuevamente",
    },

    {
      code: 701,
      message_error: "duplicate key value violates unique constraint",
      message_ux:
        "El registro que está intentando guardar ya existe, revise los datos ingresados e intente nuevamente",
    },

    {
      code: 800,
      message_error: "permission denied for sequence",
      message_ux: "Error de privilegios en la base de datos",
    },
    {
      code: 900,
      message_error: "FORMATO DE ARCHIVO INVALIDO",
      message_ux: "Está subiendo un formato de archivo inválido",
    },
  ];

  let salida = api.find(({ message_error }) => msj.includes(message_error));

  if (salida !== undefined) {
    return salida.message_ux;
  } else {
    return "";
  }
}

function apierror() {
  const basicData = [
    {
      code: 700,
      message_error: "llave duplicada viola restricción de unicidad",
      message_ux:
        "El registro que está intentando guardar ya existe, revise los datos ingresados e intente nuevamente",
    },
    {
      code: 701,
      message_error: "Menssage here!",
      message_ux: "Menssage user api here!",
    },
  ];

  return basicData;
}

function validarTelefono(telefono) {
  var numero = parseInt(telefono, 10);
  if (typeof numero === "number") {
    if (numero % 1 == 0) {
      if (numero > 1111111 && numero <= 4000000000) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function validarNumeroEntero(num) {
  var numero = parseInt(num, 10);
  if (typeof numero === "number") {
    if (numero % 1 == 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function validarNumeroDecimal(valor) {
  var RE = /^\d*\.?\d*$/;
  if (RE.test(valor)) {
    return true;
  } else {
    return false;
  }
}

function validarCorreo(correo) {
  var emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  if (emailRegex.test(correo)) {
    return true;
  } else {
    return false;
  }
}

function validarCelular(telefono) {
  var numero = parseInt(telefono, 10);
  if (typeof numero === "number") {
    if (numero % 1 == 0) {
      if (numero > 3000000000 && numero <= 3259999999) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export default {
  catching,
  apierror,
  transaccionOK,
  transaccionFail,
  respuestaInvalida,
  respuestaValidaSinTransaccion,
  errorJavaScript,
  Message,
  buscarapierror,
  validarTelefono,
  validarCorreo,
  validarCelular,
  hello,
  project,
  validarNumeroEntero,
  TYPE_FILE_IMAGE,
  TYPE_FILE_VIDEO,
  skeletons,
  today,
  validarNumeroDecimal,
  meses,
};
