<template>
  <div id="app">
    <Menubar :model="items">
      <template #start>
        <img
          alt="logo"
          src="@/assets/logomonoreduce.png"
          height="40"
          class="mr-2"
        />
      </template>
      <template #end>
        <InputText
          placeholder=""
          :disabled="true"
          v-model="username"
          type="text"
        />
        <Button
          label="Salir"
          icon="pi pi-power-off"
          class="p-button-danger"
          :style="{ 'margin-left': '0 .5em' }"
          @click="cerrarsesion"
        />
      </template>
    </Menubar>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  data() {
    return {
      username: "",
      items: [],
    };
  },

  methods: {
    cerrarsesion: function () {
      //localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("name");

      this.$router.push("/login");
    },
  },

  created: function () {
    this.username = localStorage.getItem("name");
    this.items = JSON.parse(localStorage.getItem("data") || "[]");
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fire-custom {
  background: url("../assets/fire.svg");
  fill: #42b983;
  width: 20px;
  height: 20px;
}

.forest-custom {
  background: url("../assets/forest.svg");
  fill: #42b983;
  width: 20px;
  height: 20px;
}

.florist-custom {
  background: url("../assets/florist.svg");
  fill: #42b983;
  width: 20px;
  height: 20px;
}

.spa-custom {
  background: url("../assets/spa.svg");
  fill: #42b983;
  width: 20px;
  height: 20px;
}

.bug-custom {
  background: url("../assets/bug.svg");
  fill: #42b983;
  width: 20px;
  height: 20px;
}

.shopping-custom {
  background: url("../assets/shopping.svg");
  fill: #42b983;
  width: 20px;
  height: 20px;
}

</style>
