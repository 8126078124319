<template>
  <div>
    <NavbarMain />
    <div class="ppal">
      <div class="child uno ml-4">
        <div class="grid">
          <div class="col-12">
            <div class="unointo">
              <b-button-group vertical>
                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getanioscapacidadesmax"
                >
                  <b-icon class="mr-1 mb-1" icon="bar-chart-fill"></b-icon>T.
                  Capacidades Máximas</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosnumcursossalidas"
                >
                  <b-icon class="mr-1 mb-1" icon="binoculars-fill"></b-icon>N°
                  Cursos/Salidas</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosatencionesdiaria"
                >
                  <b-icon class="mr-1 mb-1" icon="calendar3"></b-icon>N°
                  Atención diaria</b-button
                >
                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosvisitasinv"
                >
                  <b-icon class="mr-1 mb-1" icon="eyedropper"></b-icon>N°
                  Visitas Investigación</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosvisitas"
                >
                  <b-icon class="mr-1 mb-1" icon="house-door-fill"></b-icon>N°
                  Visitas</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosaliados"
                >
                  <b-icon class="mr-1 mb-1" icon="hand-thumbs-up-fill"></b-icon
                  >N° Aliados estratégicos</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosbanios"
                >
                  <b-icon class="mr-1 mb-1" icon="droplet-fill"></b-icon>N° de
                  baños</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosacueducto"
                >
                  <b-icon class="mr-1 mb-1" icon="droplet-half"></b-icon>N° de
                  acueductos alternativos</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosenergia"
                >
                  <b-icon class="mr-1 mb-1" icon="plug"></b-icon>N° de T.
                  energia alternativas</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosalcantarillado"
                >
                  <b-icon class="mr-1 mb-1" icon="bezier2"></b-icon>N° de T.
                  alcantarillados alternativos</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosestufa"
                >
                  <b-icon class="mr-1 mb-1" icon="egg-fried"></b-icon>N° de T.
                  estufas alternativas</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosproductos"
                >
                  <b-icon class="mr-1 mb-1" icon="egg"></b-icon>N° de
                  productos</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosbienesyservicios"
                >
                  <b-icon class="mr-1 mb-1" icon="building"></b-icon>N° de
                  bienes y servicios</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosmedioscomercializacion"
                >
                  <b-icon class="mr-1 mb-1" icon="megaphone-fill"></b-icon>N° de
                  medios de comercialización</b-button
                >

                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="getaniosniveles"
                >
                  <b-icon class="mr-1 mb-1" icon="award-fill"></b-icon>N° de
                  niveles educativos</b-button
                >
              </b-button-group>
            </div>
          </div>
        </div>
      </div>

      <div class="child dos">
        <div class="grid">
          <div class="col-12">
            <div class="grid">
              <div class="col-6">
                <div class="unointo">
                  <Skeleton
                    v-show="skeletoncombos"
                    height="2rem"
                    class="mb-2"
                  ></Skeleton>
                  <Dropdown
                    v-show="!skeletoncombos"
                    v-model="anioselected"
                    :options="kpianios"
                    optionLabel="anios"
                    placeholder="Seleccionar año"
                    @change="onChangeAnio()"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="unointo">
                  <Skeleton
                    v-show="skeletoncombos"
                    height="2rem"
                    class="mb-2"
                  ></Skeleton>
                  <Dropdown
                    v-show="!skeletoncombos"
                    v-model="fincaselected"
                    :options="fincas"
                    optionLabel="fin_nombre"
                    placeholder="Seleccionar finca"
                    @change="onChangeFinca($event)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="grid">
              
                <div class="col-12" v-if="option === 'CapacidadMax' && fincaselected !== null">
                  <CapacidadMax
                    :anio="anioselected.anios"
                    :finca="fincaselected.fin_id"
                    :nombrefinca="namefinca"
                  />
                </div>
                <div class="col-12" 
                  v-else-if="
                    option === 'NumCursosSalidas' && fincaselected !== null
                  "
                >
                  <NumCursosSalidas
                    :anio="anioselected.anios"
                    :finca="fincaselected.fin_id"
                    :nombrefinca="namefinca"
                  />
                </div>
                <div class="col-12" 
                  v-else-if="
                    option === 'AtencionDiaria' && fincaselected !== null
                  "
                >
                  <AtencionDiaria
                    :anio="anioselected.anios"
                    :finca="fincaselected.fin_id"
                    :nombrefinca="namefinca"
                  />
                </div>
                <div class="col-12" 
                  v-else-if="option === 'VisitasInv' && fincaselected !== null"
                >
                  <VisitasInv
                    :anio="anioselected.anios"
                    :finca="fincaselected.fin_id"
                    :nombrefinca="namefinca"
                  />
                </div>
                <div class="col-12"  v-else-if="option === 'Visitas' && fincaselected !== null">
                  <Visitas
                    :anio="anioselected.anios"
                    :finca="fincaselected.fin_id"
                    :nombrefinca="namefinca"
                  />
                </div>
                <div class="col-12" 
                  v-else-if="
                    option === 'AliadoEstrategico' && fincaselected !== null
                  "
                >
                  <AliadoEstrategico
                    :anio="anioselected.anios"
                    :finca="fincaselected.fin_id"
                    :nombrefinca="namefinca"
                  />
                </div>

                <div class="col-12" 
                  v-else-if="
                    option === 'Banios' && fincaselected !== null
                  "
                >
                  <Banios
                    :anio="anioselected.anios"
                    :finca="fincaselected.fin_id"
                    :nombrefinca="namefinca"
                  />
                </div>

                <div class="col-12" 
                  v-else-if="
                    option === 'Energias' && anioselected !== null
                  "
                >
                  <Energias
                   :anio="anioselected.anios"
                  />
                </div>

                <div class="col-12" 
                  v-else-if="
                    option === 'Acueducto' && anioselected !== null
                  "
                >
                  <Acueducto
                   :anio="anioselected.anios"
                  />
                </div>

                <div class="col-12" 
                  v-else-if="
                    option === 'Alcantarillado' && anioselected !== null
                  "
                >
                  <Alcantarillado
                   :anio="anioselected.anios"
                  />
                </div>

                <div class="col-12" 
                  v-else-if="
                    option === 'Estufa' && anioselected !== null
                  "
                >
                  <Estufa
                   :anio="anioselected.anios"
                  />
                </div>

                <div class="col-12" 
                  v-else-if="
                    option === 'Productos' && anioselected !== null
                  "
                >
                  <Productos
                   :anio="anioselected.anios"
                  />
                </div>
                
              <div class="col-12" 
                  v-else-if="
                    option === 'Bienesyservicios' && anioselected !== null
                  "
                >
                  <BienesServicios
                   :anio="anioselected.anios"
                  />
                </div>

                <div class="col-12" 
                  v-else-if="
                    option === 'MediosComercializacion' && anioselected !== null
                  "
                >
                  <MediosComercializacion
                   :anio="anioselected.anios"
                  />
                </div>

                <div class="col-12" 
                  v-else-if="
                    option === 'NivelesEducativos' && anioselected !== null
                  "
                >
                  <NivelesEducativos
                   :anio="anioselected.anios"
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
import CapacidadMax from "@/components/CapacidadMax.vue";
import NumCursosSalidas from "@/components/NumCursosSalidas.vue";
import AtencionDiaria from "@/components/AtencionDiaria.vue";
import VisitasInv from "@/components/VisitasInv.vue";
import Visitas from "@/components/Visitas.vue";
import AliadoEstrategico from "@/components/AliadoEstrategico.vue";
import Banios from "@/components/Banios.vue";
import Energias from "@/components/Energias.vue";
import Acueducto from "@/components/Acueducto.vue";
import Alcantarillado from "@/components/Alcantarillado.vue";
import Estufa from "@/components/Estufa.vue";
import Productos from "@/components/Productos.vue";
import BienesServicios from "@/components/BienesServicios.vue";
import MediosComercializacion from "@/components/MediosComercializacion.vue";
import NivelesEducativos from "@/components/NivelesEducativos.vue";

export default {
  name: "Historicos",
  data() {
    return {
      meses: this.global.meses,
      capacidadmaxhistorico: [],
      option: "",

      kpianios: [],
      anioselected: null,

      fincas: [],
      fincaselected: null,

      dataTable: [],
      namefinca: "",

      basicData: [],

      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },

      skeletons: this.global.skeletons,
      skeletoncombos: false,

      filtrarobjeto: "",
      arreglogenerico: [],
      cargaskeletonarreglogenerico: false,
    };
  },
  components: {
    Toast,
    NavbarMain,
    CapacidadMax,
    NumCursosSalidas,
    AtencionDiaria,
    VisitasInv,
    Visitas,
    AliadoEstrategico,
    Banios,
    Energias,
    Acueducto,
    Alcantarillado,
    Estufa,
    Productos,
    BienesServicios,
    MediosComercializacion,
    NivelesEducativos,
  },

  methods: {
    getaniosgeneric() {},

    onChangeFinca(event) {
      if (this.anioselected !== null) {
        this.fincaselected = event.value;
        this.namefinca = event.value.fin_nombre;
      } else {
        this.mensajeWar("Debe seleccionar un año");
      }
    },

    onChangeAnio() {
      this.fincaselected = null;
    },

    getanioscapacidadesmax: async function () {
      this.fincaselected = null;
      this.option = "CapacidadMax";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getanioscapacidadmaxhistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosnumcursossalidas: async function () {
      this.fincaselected = null;
      this.option = "NumCursosSalidas";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosnumcursossalidashistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosatencionesdiaria: async function () {
      this.fincaselected = null;
      this.option = "AtencionDiaria";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosatenciondiariahistorica")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosvisitasinv: async function () {
      this.fincaselected = null;
      this.option = "VisitasInv";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosvisitasinvhistorica")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosvisitas: async function () {
      this.fincaselected = null;
      this.option = "Visitas";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosvisitashistorica")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosaliados: async function () {
      this.fincaselected = null;
      this.option = "AliadoEstrategico";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosaliadoshistoricos")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosbanios: async function () {
      this.fincaselected = null;
      console.log("Get Banios");
      console.log(this.anioselected);
      this.option = "Banios";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosbanioshistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosenergia: async function () {
            
      this.fincaselected = null;
      this.option = "Energias";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosenergiahistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;

          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
      
    },

    getaniosacueducto: async function () {
            
      this.fincaselected = null;
      this.option = "Acueducto";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosacueductohistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;

          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
      
    },

    getaniosalcantarillado: async function () {
            
      this.fincaselected = null;
      this.option = "Alcantarillado";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosalcantarilladohistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosestufa: async function () {
            
      this.fincaselected = null;
      this.option = "Estufa";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosestufahistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosproductos: async function () {
            
      this.fincaselected = null;
      this.option = "Productos";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosproductoshistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosbienesyservicios: async function () {
            
      this.fincaselected = null;
      this.option = "Bienesyservicios";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosbienesyservicioshistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosmedioscomercializacion: async function () {
            
      this.fincaselected = null;
      this.option = "MediosComercializacion";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosmedioscomercializacionhistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaniosniveles: async function () {
            
      this.fincaselected = null;
      this.option = "NivelesEducativos";
      try {
        this.cargaskeletonarreglogenerico = true;
        this.skeletoncombos = true;
        let self = this;
        await this.axios
          .post("historicos.php?dato=getaniosniveleshistorico")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
            this.skeletoncombos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    getfincas: async function () {
      try {
        this.cargaskeletonarreglogenerico = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getfincascombo")
          .then(function (response) {
            if (response.status === 200) {
              self.fincas = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarreglogenerico = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    scrolldiv: function () {
      var div = document.getElementById("finaldiv");
      div.scrollTop = "9999";
    },
  },

  computed: {},

  created: function () {
    this.getfincas();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
}

.uno {
  margin-right: 20px;
  flex-grow: 3;
}

.unointo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
}

.dos {
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 12;
}

</style>
