<template>
  <div>
    <NavbarMain />

    <div class="container mt-4">
      <div class="grid">
        <div class="col-12">
          <DataTable
            :value="fincas"
            v-show="!cargaskeletonfincas"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
          >
            <template #header>
              <div class="table-header">
                Fincas
                <div class="p-inputgroup mr-4 ml-4">
                  <InputText
                    placeholder="Buscar x nombre de finca"
                    v-model="finca.filtro"
                    @keyup.enter="filtrarfincas"
                  />
                  <Button
                    icon="pi pi-search"
                    class="p-button-success"
                    @click="filtrarfincas"
                  />
                </div>

              </div>
            </template>

            <Column field="fin_nombre" header="Nombre"></Column>
            <Column
              field="ubicacionfinca.ub_detalle"
              header="Ubicacion"
            ></Column>
            <Column field="fin_hectareas" header="Hectareas"></Column>
            <Column>
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-primary mr-2"
                  @click="filtrarfinca(slotProps.data.fin_id)"
                /> </template
            ></Column>
          </DataTable>
        </div>
        <div class="col-12">
          <DataTable :value="skeletons" v-show="cargaskeletonfincas">
            <Column field="ske1" header="Finca">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske2" header="Fecha">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske3" header="Finca">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske4" header="">
              <template #body>
                <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible.sync="showmodalfinca"
      position="center"
      :modal="true"
      :containerStyle="{ width: '100vw' }"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ finca.titulomodal }} finca</h4>
      </template>
      <div class="p-fluid grid formgrid">

              <div class="field col-12">
        <div class="card">
          <div class="card-header">Datos básicos de la finca</div>
        </div>
      </div>

        <div class="field col-12 md:col-3">
          <label for="fin_ubicacion" v-show="!finca.nuevo">Ubicación</label>
          <Dropdown
            id="fin_ubicacion"
            v-model="finca.fin_ubicacion"
            :options="ubicacionesfincas"
            optionLabel="ub_detalle"
            placeholder="Seleccionar una ubicación"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_latitud" v-show="!finca.nuevo">Latitud</label>
          <InputText
            id="fin_latitud"
            placeholder="Latitud: 11.106014"
            v-model="finca.fin_latitud"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_longitud" v-show="!finca.nuevo">Longitud</label>
          <InputText
            id="fin_longitud"
            placeholder="Longitud: -74.1217839"
            v-model="finca.fin_longitud"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_hectareas" v-show="!finca.nuevo">Hectareas</label>
          <InputText
            id="fin_hectareas"
            placeholder="Hectareas: 100"
            v-model="finca.fin_hectareas"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_nombre" v-show="!finca.nuevo">Nombre</label>
          <InputText
            id="fin_nombre"
            placeholder="Nombre: Villa Luisa"
            v-model="finca.fin_nombre"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_actividadeconomica" v-show="!finca.nuevo"
            >Actividad Principal</label
          >
          <Dropdown
            id="fin_actividadeconomica"
            v-model="finca.fin_actividadeconomica"
            :options="actividadeseconomicas"
            optionLabel="detalle"
            placeholder="Seleccionar una actividad"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_categoriaobservadora" v-show="!finca.nuevo"
            >Enfoque económico</label
          >
          <Dropdown
            id="fin_categoriaobservadora"
            v-model="finca.fin_categoriaobservadora"
            :options="categoriasobservadoras"
            optionLabel="detalle"
            placeholder="Seleccionar una categoría"
          />
        </div>

         <Divider />

             <div class="field col-12">
        <div class="card">
          <div class="card-header">Datos Económicos</div>
        </div>
      </div>

        <div class="field col-12 md:col-4">
          <label for="fin_tiposdeenergia" v-show="!finca.nuevo"
            >Tipos de energia</label
          >
          <MultiSelect
            id="fin_tiposdeenergia"
            v-model="tiposenergiaselected"
            :options="tiposenergia"
            optionLabel="detalle"
            placeholder="Seleccionar tipos de energia"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_tiposdealcantarillado" v-show="!finca.nuevo"
            >Tipos de alcantarillado</label
          >
          <MultiSelect
            id="fin_tiposdealcantarillado"
            v-model="tiposdealcantarilladoselected"
            :options="tiposdealcantarillado"
            optionLabel="detalle"
            placeholder="Seleccionar tipos de alcantarillado"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_tiposdeestufa" v-show="!finca.nuevo"
            >Tipos de estufa</label
          >
          <MultiSelect
            id="fin_tiposdeestufa"
            v-model="tiposdeestufaselected"
            :options="tiposdeestufa"
            optionLabel="detalle"
            placeholder="Seleccionar tipos de estufa"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_productos" v-show="!finca.nuevo"
            >Productos cultivados</label
          >
          <MultiSelect
            id="fin_productos"
            v-model="productosselected"
            :options="productos"
            optionLabel="detalle"
            placeholder="Seleccionar productos"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_bienesyservicios" v-show="!finca.nuevo"
            >Bienes y servicios</label
          >
          <MultiSelect
            id="fin_bienesyservicios"
            v-model="bienesyserviciosselected"
            :options="bienesyservicios"
            optionLabel="detalle"
            placeholder="Seleccionar bienes y servicios"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_tiporiesgo" v-show="!finca.nuevo"
            >Tipos de riesgos</label
          >
          <MultiSelect
            id="fin_tiporiesgo"
            v-model="tiporiesgoselected"
            :options="tiporiesgos"
            optionLabel="detalle"
            placeholder="Seleccionar riesgos de vivienda"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_tipoacueducto" v-show="!finca.nuevo"
            >Tipos de acueductos</label
          >
          <MultiSelect
            id="fin_tipoacueducto"
            v-model="tipoacueductoselected"
            :options="tipoacueductos"
            optionLabel="detalle"
            placeholder="Seleccionar acueductos"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_estabilidadinternet" v-show="!finca.nuevo"
            >Estabilidad señal Internet</label
          >

          <Dropdown
            id="fin_estabilidadinternet"
            v-model="finca.fin_senalinternet"
            :options="estabilidadesinternet"
            optionLabel="detalle"
            placeholder="Seleccionar un nivel de señal"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_estabilidadtelefono" v-show="!finca.nuevo"
            >¿Buena señal Telefonica?</label
          >

          <Dropdown
            id="fin_estabilidadtelefono"
            v-model="finca.fin_senaltelefonica"
            :options="estabilidadestelefono"
            optionLabel="detalle"
            placeholder="¿La señal telefónica es estable?"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_mediocomercio" v-show="!finca.nuevo"
            >Medios de comercialización</label
          >
          <MultiSelect
            id="fin_mediocomercio"
            v-model="mediocomercioselected"
            :options="mediosdecomercio"
            optionLabel="detalle"
            placeholder="Seleccione medios de comercialización"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_objetoprincipal" v-show="!finca.nuevo"
            >Actividades de la finca</label
          >
          <MultiSelect
            id="fin_objetoprincipal"
            v-model="objetoppalselected"
            :options="objetosppales"
            optionLabel="detalle"
            placeholder="Seleccione actividades"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_proyectosfuturos" v-show="!finca.nuevo"
            >Proyectos a futuro</label
          >
          <MultiSelect
            id="fin_proyectosfuturos"
            v-model="proyectosfuturosselected"
            :options="proyectosfuturos"
            optionLabel="detalle"
            placeholder="Seleccione proyectos"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_niveleducativo" v-show="!finca.nuevo"
            >Niveles educativos</label
          >
          <MultiSelect
            id="fin_niveleducativo"
            v-model="niveleducativoselected"
            :options="nivelesducativos"
            optionLabel="detalle"
            placeholder="Seleccione niveles educativos"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_capacidaddiaria" v-show="!finca.nuevo"
            >Capacidad diaria</label
          >
          <InputText
            id="fin_capacidaddiaria"
            placeholder="Cap. diaria: 50"
            v-model="finca.fin_capacidaddiaria"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_participacionfemenina" v-show="!finca.nuevo"
            >Participación femenina</label
          >
          <Dropdown
            id="fin_participacionfemenina"
            v-model="finca.fin_participacionfemenina"
            :options="nivelesdeparticipacion"
            optionLabel="detalle"
            placeholder="Seleccionar un nivel de participación"
          />
        </div>

       <div class="field col-12 md:col-6">
          <label for="fin_entidades" v-show="!finca.nuevo"
            >Actores con quién ha trabajado</label
          >
          <MultiSelect
            id="fin_entidades"
            v-model="entidadesselected"
            :options="entidades"
            optionLabel="entpro_detalle"
            placeholder="Seleccionar entidades"
            display="chip"
          />
      </div>

      <div class="field col-12 md:col-6">
          <label for="fin_autoridades" v-show="!finca.nuevo"
            >Autoridades con las que ha trabajado</label
          >
          <MultiSelect
            id="fin_autoridades"
            v-model="autoridadesselected"
            :options="autoridades"
            optionLabel="aut_nombre"
            placeholder="Seleccionar autoridades"
            display="chip"
          />
      </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="fin_extranjero">Extranjero: </label>
          <InputSwitch id="fin_extranjero" v-model="finca.fin_extranjero" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="fin_nacional">Nacional: </label>
          <InputSwitch id="fin_nacional" v-model="finca.fin_nacional" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="fin_nacional">Nativo: </label>
          <InputSwitch id="fin_nativo" v-model="finca.fin_nativo" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="fin_grupoetnico">Etnico: </label>
          <InputSwitch id="fin_grupoetnico" v-model="finca.fin_grupoetnico" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="fin_contableyadmin"
            >Procesos contable y admin:
          </label>
          <InputSwitch
            id="fin_contableyadmin"
            v-model="finca.fin_contableyadmin"
          />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="fin_participacionproyectos"
            >Participación en proyectos:
          </label>
          <InputSwitch
            id="fin_participacionproyectos"
            v-model="finca.fin_participacionproyectos"
          />
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="finca.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="finca.boton"
          icon="pi pi-check"
          autofocus
          @click="actualizareconomico"
        />
      </template>
    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";

export default {
  name: "Registrodef",
  data() {
    return {
      finca: {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,

        fin_actividadeconomica: null,
        fin_categoriaobservadora: null,
        fin_participacionfemenina: null,

        fin_senalinternet: null,
        fin_senaltelefonica: null,

        fin_capacidaddiaria: null,
        fin_tiposinvestigacion: [],

        fin_extranjero: false,
        fin_nacional: false,
        fin_nativo: false,
        fin_grupoetnico: false,

        fin_contableyadmin: false,
        fin_participacionproyectos: false,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      },

      skeletons: this.global.skeletons,
      cargaskeletonfincas: false,
      showmodalfinca: false,

      fincas: [],
      ubicacionesfincas: [],
      actividadeseconomicas: [],
      categoriasobservadoras: [],
      nivelesdeparticipacion: [],

      tiposenergia: [],
      tiposdealcantarillado: [],
      tiposdeestufa: [],
      productos: [],
      bienesyservicios: [],
      tiporiesgos: [],
      tipoacueductos: [],
      estabilidadesinternet: [],
      estabilidadestelefono: [],
      mediosdecomercio: [],
      objetosppales: [],
      proyectosfuturos: [],
      nivelesducativos: [],
      entidades: [],
      autoridades: [],

      tiposenergiaselected: null,
      tiposdealcantarilladoselected: null,
      tiposdeestufaselected: null,
      productosselected: null,
      bienesyserviciosselected: null,
      tiporiesgoselected: null,
      tipoacueductoselected: null,
      mediocomercioselected: null,
      objetoppalselected: null,
      proyectosfuturosselected: null,
      niveleducativoselected: null,
      entidadesselected: null,
      autoridadesselected: null,
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    actualizareconomico: async function () {
      try {
        var mensaje = this.validarActualizarEconomico;

        if (mensaje === "") {
          this.finca.boton = " Actualizando oferta... ";
          this.finca.wait = true;

          var parametros = {
            fin_id: this.finca.fin_id,
            fin_nombre: this.finca.fin_nombre,
            fin_latitud: this.finca.fin_latitud,
            fin_longitud: this.finca.fin_longitud,

            fin_hectareas: this.finca.fin_hectareas,
            fin_ubicacion: this.finca.fin_ubicacion.ub_id,

            fin_actividadeconomica: this.finca.fin_actividadeconomica.id,
            fin_categoriaobservadora: this.finca.fin_categoriaobservadora.id,
            fin_participacionfemenina: this.finca.fin_participacionfemenina.id,

            fin_estabilidadtelefono: this.finca.fin_senaltelefonica.id,
            fin_estabilidadinternet: this.finca.fin_senalinternet.id,
            fin_capacidaddiaria: this.finca.fin_capacidaddiaria,

            fin_tiposinvestigacion:
              this.finca.fin_tiposinvestigacion.toString(),

            fin_extranjero: this.finca.fin_extranjero,
            fin_nacional: this.finca.fin_nacional,
            fin_nativo: this.finca.fin_nativo,
            fin_grupoetnico: this.finca.fin_grupoetnico,
            fin_contableyadmin: this.finca.fin_contableyadmin,
            fin_participacionproyectos: this.finca.fin_participacionproyectos,

            tiposenergiaselected: this.tiposenergiaselected,
            tiposdealcantarilladoselected: this.tiposdealcantarilladoselected,
            tiposdeestufaselected: this.tiposdeestufaselected,
            productosselected: this.productosselected,
            bienesyserviciosselected: this.bienesyserviciosselected,
            tiporiesgoselected: this.tiporiesgoselected,
            tipoacueductoselected: this.tipoacueductoselected,
            mediocomercioselected: this.mediocomercioselected,
            objetoppalselected: this.objetoppalselected,
            proyectosfuturosselected: this.proyectosfuturosselected,
            niveleducativoselected: this.niveleducativoselected,
            entidadesselected: this.entidadesselected,
            autoridadesselected: this.autoridadesselected,
          };

          await this.axios
            .post("fincas.php?dato=updateeconomico", JSON.stringify(parametros))
            .then((response) => {
              if (response.status === 200) {
                if (response.data.includes(this.global.transaccionOK)) {
                  this.mensajeOK(response.data);
                  this.limpiarCamposNuevoRegistro();
                  this.filtrarfincas();
                } else if (this.global.buscarapierror(response.data) !== "") {
                  let msj_user = this.global.buscarapierror(response.data);
                  this.mensajeWar(msj_user);
                } else {
                  this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                }
              } else {
                this.global.mensajeErr(this.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              this.finca.boton = " Guardar ";
              this.finca.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtrarfincas: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          filtro: this.finca.filtro,
        };

        await this.axios
          .post(
            "fincas.php?dato=getfincaseconomico",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.fincas = response.data;
              if (self.fincas.length === 0) {
                self.mensajeWar("No se encontraron fincas");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    filtrarfinca: async function (id) {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          fin_id: id,
        };

        await this.axios
          .post(
            "fincas.php?dato=getfincaeconomicoconparametros",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.listarmodalfinca(response.data[0]);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getubicaciones: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getubicacionesfincas")
          .then(function (response) {
            if (response.status === 200) {
              self.ubicacionesfincas = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getactividadeseconomicas: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "actividadeconomica",
          namefield_id: "acteco_id",
          namefield_name: "acteco_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.actividadeseconomicas = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getcategoriasobservadoras: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "categoriaobservadora",
          namefield_id: "cat_id",
          namefield_name: "cat_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.categoriasobservadoras = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    //GETTERS

   getentidades: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("entidades.php?dato=getentidadescombo")
          .then(function (response) {
            if (response.status === 200) {
              self.entidades = response.data;
            } else {
              self.mensajeErr("Error al cargar las entidades");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getautoridades: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("autoridades.php?dato=getautoridadescombo")
          .then(function (response) {
            if (response.status === 200) {
              self.autoridades = response.data;
            } else {
              self.mensajeErr("Error al cargar las autoridades");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getenergias: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        var parametros = {
          nametable: "tipoenergia",
          namefield_id: "tpen_id",
          namefield_name: "tpen_detalle",
          namefield_bool: "tpen_renovable",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.tiposenergia = response.data;
            } else {
              self.mensajeErr("Error al cargar las getenergias");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposalcantarillado: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        var parametros = {
          nametable: "tipoalcantarillado",
          namefield_id: "tpal_id",
          namefield_name: "tpal_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.tiposdealcantarillado = response.data;
            } else {
              self.mensajeErr("Error al cargar las gettiposalcantarillado");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposestufa: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        var parametros = {
          nametable: "tipoestufa",
          namefield_id: "tpes_id",
          namefield_name: "tpes_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.tiposdeestufa = response.data;
            } else {
              self.mensajeErr("Error al cargar las getactividadespaquetes");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposacueducto: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        var parametros = {
          nametable: "tipoacueducto",
          namefield_id: "tpac_id",
          namefield_name: "tpac_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.tipoacueductos = response.data;
            } else {
              self.mensajeErr("Error al cargar las medidasdeseguridad");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposniveles: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        var parametros = {
          nametable: "nivel",
          namefield_id: "niv_id",
          namefield_name: "niv_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.nivelesdeparticipacion = response.data;
              self.estabilidadesinternet = response.data;
            } else {
              self.mensajeErr("Error al cargar las entidades");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposproducto: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        var parametros = {
          nametable: "producto",
          namefield_id: "pro_id",
          namefield_name: "pro_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.productos = response.data;
            } else {
              self.mensajeErr("Error al cargar los serviciostecnologicos");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposbienservicios: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        var parametros = {
          nametable: "bienservicio",
          namefield_id: "bs_id",
          namefield_name: "bs_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.bienesyservicios = response.data;
            } else {
              self.mensajeErr("Error al cargar los gettiposbienservicios");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposriesgo: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "tiporiesgo",
          namefield_id: "tprg_id",
          namefield_name: "tprg_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.tiporiesgos = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getmedioscomercio: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "mediocomercio",
          namefield_id: "medcor_id",
          namefield_name: "medcor_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.mediosdecomercio = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getobjetoprincipal: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "objetoprincipal",
          namefield_id: "obpal_id",
          namefield_name: "obpal_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.objetosppales = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getproyectosfuturos: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "proyectosfuturos",
          namefield_id: "profut_id",
          namefield_name: "profut_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.proyectosfuturos = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getniveleducativo: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "niveleducativo",
          namefield_id: "nived_id",
          namefield_name: "nived_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.nivelesducativos = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getindicadorcumplimiento: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "indicadorcumplimiento",
          namefield_id: "ind_id",
          namefield_name: "ind_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.estabilidadestelefono = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.finca = {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,

        fin_actividadeconomica: 0,
        fin_categoriaobservadora: 0,
        fin_participacionfemenina: 0,

        fin_senalinternet: null,
        fin_senaltelefonica: null,

        fin_capacidaddiaria: null,
        fin_tiposinvestigacion: [],

        fin_extranjero: false,
        fin_nacional: false,
        fin_nativo: false,
        fin_grupoetnico: false,

        fin_contableyadmin: false,
        fin_participacionproyectos: false,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      };

      this.showmodalfinca = false;
    },

    openmodalfinca: function () {
      this.finca = {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,

        fin_actividadeconomica: 0,
        fin_categoriaobservadora: 0,
        fin_participacionfemenina: 0,

        fin_senalinternet: null,
        fin_senaltelefonica: null,

        fin_capacidaddiaria: null,
        fin_tiposinvestigacion: [],

        fin_extranjero: false,
        fin_nacional: false,
        fin_nativo: false,
        fin_grupoetnico: false,

        fin_contableyadmin: false,
        fin_participacionproyectos: false,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      };

      this.showmodalfinca = true;
    },

    listarmodalfinca: async function (data) {
      var tiposinvestigacion = [];

      if (
        data.fin_tiposinvestigacion !== "" &&
        data.fin_tiposinvestigacion !== null
      ) {
        tiposinvestigacion = data.fin_tiposinvestigacion.toString().split(",");
      }


      this.finca = {
        titulomodal: " Editar datos económicos ",
        boton: "Editar",
        accion: 1,
        nuevo: false,

        fin_id: data.fin_id,
        fin_nombre: data.fin_nombre,
        fin_latitud: data.fin_latitud,
        fin_longitud: data.fin_longitud,
        fin_hectareas: data.fin_hectareas,

        fin_capacidaddiaria: data.fin_capacidaddiaria,

        fin_tiposinvestigacion: tiposinvestigacion,

        fin_extranjero: data.fin_extranjero,
        fin_nacional: data.fin_nacional,
        fin_nativo: data.fin_nativo,
        fin_grupoetnico: data.fin_grupoetnico,
        fin_contableyadmin: data.fin_contableyadmin,
        fin_participacionproyectos: data.fin_participacionproyectos,

        fin_ubicacion: this.ubicacionesfincas.find(
          (p) => p.ub_id === data.ubicacionfinca.ub_id
        ),

        fin_actividadeconomica: this.actividadeseconomicas.find(
          (p) => p.id === data.actividadeconomica.acteco_id
        ),

        fin_categoriaobservadora: this.categoriasobservadoras.find(
          (p) => p.id === data.categoriaobservadora.cat_id
        ),

        fin_participacionfemenina: this.nivelesdeparticipacion.find(
          (p) => p.id === data.participacionfemenina.nivelpart_id
        ),

        fin_senalinternet: this.estabilidadesinternet.find(
          (p) => p.id === data.senalinternet.nivelsenal_id
        ),

        fin_senaltelefonica: this.estabilidadestelefono.find(
          (p) => p.id === data.senaltelefono.ind_id
        ),

        
      };

   
      this.tiposenergiaselected = data.energia;
      this.tiposdealcantarilladoselected = data.tipoalcantarillado;
      this.tiposdeestufaselected = data.tipoestufa;
      this.productosselected = data.producto;
      this.bienesyserviciosselected = data.bienservicio;
      this.tiporiesgoselected = data.tiporiesgo;
      this.tipoacueductoselected = data.tipoacueducto;
      this.mediocomercioselected = data.mediocomercio;
      this.objetoppalselected = data.objetoprincipal;
      this.proyectosfuturosselected = data.proyectosfuturos;
      this.niveleducativoselected = data.niveleducativo;
      this.entidadesselected = data.entidades;
      this.autoridadesselected = data.autoridades;

      this.showmodalfinca = true;
    },
  },

  computed: {
    validarActualizarEconomico() {
      var mensajeAlerta = "";
      if (this.finca.fin_ubicacion === null) {
        mensajeAlerta = " Debe seleccionar una ubicación ";
      } else if (
        this.finca.fin_nombre === "" ||
        this.finca.fin_nombre.length > 45
      ) {
        mensajeAlerta =
          "Debe digitar un nombre válido, no debe superar los 45 caracteres";
      } else if (
        this.finca.fin_latitud === null ||
        Number.isNaN(Number(this.finca.fin_latitud)) ||
        this.finca.fin_latitud > 180 ||
        this.finca.fin_latitud < -180 ||
        this.finca.fin_latitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Latitud válido ej: 11.144433 separando decimales con punto (.) ";
      } else if (
        this.finca.fin_longitud === null ||
        Number.isNaN(Number(this.finca.fin_longitud)) ||
        this.finca.fin_longitud > 180 ||
        this.finca.fin_longitud < -180 ||
        this.finca.fin_longitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Longitud válido ej: -74.118597 separando decimales con punto (.) ";
      } else if (
        this.finca.fin_hectareas === null ||
        !this.global.validarNumeroEntero(this.finca.fin_hectareas) ||
        this.finca.fin_hectareas < 0 ||
        this.finca.fin_hectareas > 10000 ||
        this.finca.fin_hectareas === ""
      ) {
        mensajeAlerta = "Debe digitar un N° de hectareas válido";
      } else if (this.finca.fin_senaltelefonica === null) {
        mensajeAlerta = " Debe seleccionar contestar si la señal telefónica es estable ";
      }

      return mensajeAlerta;
    },
  },

  

  created: function () {
    this.getubicaciones();
    this.getactividadeseconomicas();
    this.getcategoriasobservadoras();

    this.getenergias();
    this.gettiposalcantarillado();
    this.gettiposestufa();
    this.gettiposacueducto();
    this.gettiposniveles();

    this.gettiposproducto();
    this.gettiposbienservicios();
    this.gettiposriesgo();

    this.getautoridades();
    this.getentidades();

    this.getmedioscomercio();
    this.getobjetoprincipal();
    this.getproyectosfuturos();
    this.getniveleducativo();
    this.getindicadorcumplimiento();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
  background-color: aqua;
}

.uno {
  margin-right: 20px;
  flex-grow: 10;
}

.dos {
  margin-left: 20px;
  flex-grow: 5;
}

.tres {
  flex-grow: 5;
}

.imagenes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
}

.article {
  height: 100%;
  display: grid;
  place-content: center;
}

.img {
  width: 300px;
  height: 300px;
}
</style>
