<template>
  <div>
    <NavbarMain />
    <div class="container"></div>

    <Dialog
      header="Header"
      :visible.sync="showmodalusuario"
      position="center"
      :modal="true"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>Editar Información</h4>
      </template>

      <div class="p-fluid grid formgrid">
        <div class="field col-12">
          <label for="usu_telefono">Teléfono</label>
          <InputText
            id="usu_telefono"
            placeholder="Telefono: 310 3456677"
            v-model="usu_telefono"
          />
        </div>

        <div class="field col-12">
          <label for="usu_correo">Correo</label>
          <InputText
            id="usu_correo"
            placeholder="Correo: carlosjimenez@gmail.com"
            v-model="usu_correo"
          />
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="boton"
          icon="pi pi-check"
          autofocus
          @click="cambiardatosusuario"
        />
      </template>
    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "OpcionesUsuario",
  data() {
    return {
      showmodalusuario: true,
      usu_telefono: localStorage.getItem("phone"),
      usu_correo: localStorage.getItem("mail"),
      wait: false,
      boton: "Editar Información",
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    cambiardatosusuario: async function () {
      try {
        var mensaje = this.validarEditarInfo;

        if (mensaje === "") {
          this.wait = true;
          let self = this;

          this.boton = " Editando Información... ";

          var parametros = {
            alias: localStorage.getItem("alias"),
            telefono: this.telefono,
            correo: this.correo,
          };

          await this.axios
            .post(
              "usuarios.php?dato=editaropcionesuser",
              JSON.stringify(parametros)
            )
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.mensajeOK(response.data);
                  self.showmodalusuario = false;
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.wait = false;
              self.boton = " Cambiar clave ";
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log("ERROR JS: " + e);
      }
    },
  },

  computed: {
    validarEditarInfo() {
      var mensajeAlerta = "";

      if (this.telefono === "") {
        mensajeAlerta = "Teléfono Inválido";
      } else {
        if (this.global.validarTelefono(this.telefono)) {
          if (this.telefono.length > 10) {
            mensajeAlerta = "El telefono no debe tener mas de 10 caractéres";
          }
        } else {
          mensajeAlerta = "El teléfono digitado es inválido";
        }
      }

      if (this.correo === "") {
        mensajeAlerta = "El correo digitado es inválido";
      }
      else {
        if (this.global.validarCorreo(this.correo)) {
          if (this.correo.length > 75) {
            mensajeAlerta = "El correo no debe tener mas de 75 caractéres";
          }
        } else {
          mensajeAlerta = "El correo digitado es inválido";
        }
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    this.telefono = localStorage.getItem("phone");
    this.correo = localStorage.getItem("mail");
    this.showmodalusuario = true;
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>
