<template>
  <div>
    <NavbarMain />
    <div class="container">
      <div class="grid">
        <div class="col-12">
          <div v-show="showprogressppalespecies">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="col-12">
          <div class="grid">
            <div class="col-12">
              <DataTable
                :value="especies"
                v-show="!cargaskeletonespecies"
                responsiveLayout="scroll"
              >
                <template #header>
                  <div class="table-header">
                    Especies
                    <div class="p-inputgroup mr-4 ml-4">
                      <InputText
                        placeholder="Buscar x nombre"
                        v-model="especie.filtro"
                        @keyup.enter="filtrarespecie"
                      />
                      <Button
                        icon="pi pi-search"
                        class="p-button-success"
                        @click="filtrarespecie"
                      />
                    </div>

                    <Button icon="pi pi-plus" @click="openmodalespecie()" />
                    <Button
                      icon="pi pi-cloud-upload"
                      class="p-button-warning ml-2"
                    />

                    <Button
                      icon="pi pi-file-excel"
                      class="p-button-raised p-button-text p-button-plain ml-2"
                      @click="exportarEspecies"
                    />
                  </div>
                  <div>
                    <div class="flex mt-3">
                      <div
                        class="field-radiobutton flex-1 flex align-items-center justify-content-center"
                      >
                        <RadioButton
                          id="amenazadas"
                          name="clasificacionespecie"
                          value="amenazadas"
                          v-model="clasificacionespecie"
                        />
                        <label for="amenazadas">Amenazadas</label>
                      </div>

                      <div
                        class="field-radiobutton flex-1 flex align-items-center justify-content-center"
                      >
                        <RadioButton
                          id="endemicas"
                          name="clasificacionespecie"
                          value="endemicas"
                          v-model="clasificacionespecie"
                        />
                        <label for="endemicas">Endémicas</label>
                      </div>
                      <div
                        class="field-radiobutton flex-1 flex align-items-center justify-content-center"
                      >
                        <RadioButton
                          id="comercializadas"
                          name="clasificacionespecie"
                          value="comercializadas"
                          v-model="clasificacionespecie"
                        />
                        <label for="comercializadas">Comercializadas</label>
                      </div>
                      <div
                        class="field-radiobutton flex-1 flex align-items-center justify-content-center"
                      >
                        <RadioButton
                          id="ninguna"
                          name="clasificacionespecie"
                          value="ninguna"
                          v-model="clasificacionespecie"
                        />
                        <label for="ninguna">Ninguna</label>
                      </div>
                    </div>
                  </div>
                </template>

                <Column header="Apen.">
                  <template #body="slotProps">
                    <p class="font-italic">
                      {{ slotProps.data.esp_apendice }}
                    </p>
                  </template></Column
                >

                <Column header="Nombre científico | Nombre Común">
                  <template #body="slotProps">
                    <p class="font-italic">
                      {{
                        slotProps.data.esp_especie +
                        " | " +
                        slotProps.data.esp_nombrecomun
                      }}
                    </p>
                  </template></Column
                >

                <Column header="Amenazada">
                  <template #body="slotProps">
                    <InputSwitch
                      v-model="slotProps.data.esp_amenazada"
                      :disabled="true"
                    /> </template
                ></Column>
                <Column header="Endémico">
                  <template #body="slotProps">
                    <InputSwitch
                      v-model="slotProps.data.esp_endemico"
                      :disabled="true"
                    /> </template
                ></Column>
                <Column header="Comercializada">
                  <template #body="slotProps">
                    <InputSwitch
                      v-model="slotProps.data.esp_comercializada"
                      :disabled="true"
                    /> </template
                ></Column>

                <Column>
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-pencil"
                      class="p-button-rounded p-button-primary mr-2"
                      @click="listarmodalespecie(slotProps.data)"
                    /> </template
                ></Column>
              </DataTable>
            </div>
            <div class="col-12">
              <DataTable :value="skeletons" v-show="cargaskeletonespecies">
                <Column field="ske1" header="Cargando...">
                  <template #body>
                    <Skeleton></Skeleton>
                  </template>
                </Column>
                <Column field="ske2" header="Cargando...">
                  <template #body>
                    <Skeleton></Skeleton>
                  </template>
                </Column>
                <Column field="ske3" header="Cargando...">
                  <template #body>
                    <Skeleton></Skeleton>
                  </template>
                </Column>
                <Column field="ske4" header="Cargando...">
                  <template #body>
                    <Skeleton></Skeleton>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="col-12">
          <Toast />
        </div>
      </div>

      <Dialog
        header="Header"
        :visible.sync="showmodalespecie"
        position="center"
        :modal="true"
        :containerStyle="{ width: '100vw' }"
      >
        <template #header>
          <i class="pi pi-check" style="font-size: 2rem"></i>
          <h4>{{ especie.titulomodal }}</h4>
        </template>
        <div>
          <div class="grid">
            
            <div class="md:col-6 col-12">
              <div class="p-inputgroup" v-show="!especie.nuevo">
                <label >Categoría</label>
              </div>

              <div class="p-inputgroup">
                <Dropdown
                  class="w-100"
                  v-model="especie.esp_categoria"
                  :options="categorias"
                  optionLabel="catesp_nombre"
                  placeholder="Seleccionar una categoria"
                />
              </div>
            </div>

            <div class="md:col-6 col-12" >
              <div class="p-inputgroup" v-show="!especie.nuevo">
                <label>Clase</label>
              </div>
           
              <div class="p-inputgroup">
                <InputText placeholder="Clase" v-model="especie.esp_clase" />
              </div>
            </div>

            <div class="md:col-6 col-12">
              <div class="p-inputgroup" v-show="!especie.nuevo">
                <label>Orden</label>
              </div>
              <div class="p-inputgroup">
                <InputText placeholder="Orden" v-model="especie.esp_orden" />
              </div>
            </div>


            <div class="md:col-6 col-12">
              <div class="p-inputgroup" v-show="!especie.nuevo">
                <label>Familia</label>
              </div>
              <div class="p-inputgroup">
                <InputText
                  placeholder="Familia"
                  v-model="especie.esp_familia"
                />
              </div>
            </div>

            <div class="md:col-6 col-12">
              <div class="p-inputgroup" v-show="!especie.nuevo">
                <label>Género</label>
              </div>
              <div class="p-inputgroup">
                <InputText placeholder="Género" v-model="especie.esp_genero" />
              </div>
            </div>
            <div class="md:col-6 col-12">
              <div class="p-inputgroup" v-show="!especie.nuevo">
                <label>Apendice</label>
              </div>
              <div class="p-inputgroup">
                <Dropdown
                  v-model="especie.esp_apendice"
                  :options="apendices"
                  optionLabel="ape_detalle"
                  placeholder="Seleccionar un apéndice"
                />
              </div>
            </div>

            <div class="md:col-6 col-12">
              <div class="p-inputgroup" v-show="!especie.nuevo">
                <label>Nombre científico</label>
              </div>
              <div class="p-inputgroup">
                <InputText
                  placeholder="Nombre científico"
                  v-model="especie.esp_especie"
                />
              </div>
            </div>
            <div class="md:col-6 col-12">
              <div class="p-inputgroup" v-show="!especie.nuevo">
                <label>Nombre común</label>
              </div>
              <div class="p-inputgroup">
                <InputText
                  placeholder="Nombre común"
                  v-model="especie.esp_nombrecomun"
                />
              </div>
            </div>

          </div>

          <div class="flex-checkbox">
            <div class="p-inputgroup min-content-check">
              <label class="mr-2" for="iscAmenazada">Amenazada: </label>
              <InputSwitch id="iscAmenazada" v-model="especie.esp_amenazada" />
            </div>

            <div class="p-inputgroup min-content-check">
              <label class="mr-2" for="iscEndemico">Endémico: </label>
              <InputSwitch id="iscEndemico" v-model="especie.esp_endemico" />
            </div>

            <div class="p-inputgroup min-content-check">
              <label class="mr-2" for="isccomercializada"
                >Comercializada:
              </label>
              <InputSwitch
                id="isccomercializada"
                v-model="especie.esp_comercializada"
              />
            </div>
          </div>

          <div class="grid mt-4">
            <div class="col-6">
              <div
                class="p-inputgroup"
                v-show="!especie.nuevo && especie.esp_amenazada"
              >
                <label>Categoría de amenaza</label>
              </div>

              <div class="p-inputgroup">
                <InputText
                  v-show="especie.esp_amenazada"
                  placeholder="Categoría amenaza"
                  v-model="especie.esp_cat_amenaza"
                />
              </div>
            </div>
            <div class="col-6">
              <div
                class="p-inputgroup"
                v-show="!especie.nuevo && especie.esp_amenazada"
              >
                <label>Año categoría</label>
              </div>

              <div class="p-inputgroup">
                <InputText
                  v-show="especie.esp_amenazada"
                  placeholder="Año categoría"
                  v-model="especie.esp_cat_anio"
                />
              </div>
            </div>
          </div>
          <div
            class="grid"
            v-show="!especie.nuevo && especie.esp_comercializada"
          >
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Paises</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <Chips
                  v-show="especie.esp_comercializada"
                  v-model="especie.esp_paises"
                  placeholder="Paises (EEUU, Japon, Brasil)"
                />
              </div>
            </div>
          </div>

          <div
            class="grid"
            v-show="!especie.nuevo && especie.esp_comercializada"
          >
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Partes</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <Chips
                  v-show="especie.esp_comercializada"
                  v-model="especie.esp_partes"
                  placeholder="(Piel) (Cola) (vivo) "
                />
              </div>
            </div>
          </div>

          <div
            class="grid"
            v-show="!especie.nuevo && especie.esp_comercializada"
          >
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Popósito</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <Chips
                  v-show="especie.esp_comercializada"
                  v-model="especie.esp_proposito"
                  placeholder="(Comenrcial) (Científico) (Objeto personal)"
                />
              </div>
            </div>
          </div>

          <div
            class="grid"
            v-show="!especie.nuevo && especie.esp_comercializada"
          >
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Fuente</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <Chips
                  v-show="especie.esp_comercializada"
                  v-model="especie.esp_source"
                  placeholder="(Silvestre) (Criado en cautividad) (Decomisado)"
                />
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <ProgressSpinner
            class="mr-2"
            v-show="especie.wait"
            style="width: 40px; height: 40px"
            strokeWidth="8"
            fill="#FFFFFF"
            animationDuration=".5s"
          />
          <Button
            :label="especie.boton"
            :disabled="especie.activo"
            icon="pi pi-check"
            autofocus
            @click="listaraccionespecie"
          />
        </template>
      </Dialog>

      <Dialog
        header="Header"
        :visible.sync="showmodalimport"
        position="center"
        :modal="true"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '40vw' }"
      >
        <template #header>
          <i class="pi pi-check" style="font-size: 2rem"></i>
          <h4>{{ especie.titulomodal }}</h4>
        </template>
        <div>
          <div class="grid"></div>
        </div>
        <template #footer>
          <ProgressSpinner
            class="mr-2"
            v-show="especie.wait"
            style="width: 40px; height: 40px"
            strokeWidth="8"
            fill="#FFFFFF"
            animationDuration=".5s"
          />
          <Button
            :label="especie.boton"
            :disabled="especie.activo"
            icon="pi pi-check"
            autofocus
            @click="listaraccionespecie"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "Especies",
  data() {
    return {
      clasificacionespecie: "amenazadas",
      especie: {
        esp_id: 0,
        esp_clase: "",
        esp_orden: "",
        esp_familia: "",
        esp_genero: "",
        esp_especie: "",
        esp_nombrecomun: "",
        esp_apendice: 0,
        esp_amenazada: false,
        esp_cat_amenaza: "",
        esp_cat_anio: 0,
        esp_endemico: false,
        esp_comercializada: false,
        esp_paises: [],
        esp_partes: [],
        esp_proposito: [],
        esp_source: [],
        esp_categoria: 0,

        titulomodal: "",
        boton: "",
        accion: 0,
        nuevo: true,
        activo: false,
        filtro: "",
        wait: false,
      },

      skeletons: [
        {
          ske1: "...",
          ske2: "...",
          ske3: "...",
          ske4: "...",
        },
        {
          ske1: "...",
          ske2: "...",
          ske3: "...",
          ske4: "...",
        },
        {
          ske1: "...",
          ske2: "...",
          ske3: "...",
          ske4: "...",
        },
        {
          ske1: "...",
          ske2: "...",
          ske3: "...",
          ske4: "...",
        },
      ],

      cargaskeletonespecies: false,
      cargaskeletonperfiles: false,

      showmodalespecie: false,
      showmodalimport: false,

      showprogressppalespecies: false,

      categorias: [],
      apendices: [],
      categoriascombo: [],
      apendicescombo: [],
      especies: [],

      filtroamenazada: true,
      filtroendemico: true,
      filtrocomercializada: true,
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    openmodalespecie: function () {
      this.especie = {
        esp_id: 0,
        esp_clase: "",
        esp_orden: "",
        esp_familia: "",
        esp_genero: "",
        esp_especie: "",
        esp_nombrecomun: "",
        esp_apendice: 0,
        esp_amenazada: false,
        esp_cat_amenaza: "",
        esp_cat_anio: 0,
        esp_endemico: false,
        esp_comercializada: false,
        esp_paises: [],
        esp_partes: [],
        esp_proposito: [],
        esp_source: [],
        esp_categoria: 0,

        titulomodal: "Nueva especie",
        boton: "Guardar",
        accion: 0,
        filtro: "",
        nuevo: true,
        activo: false,
        wait: false,
      };

      this.showmodalespecie = true;
    },

    listarmodalespecie: function (data) {
      var paises = [];
      var partes = [];
      var proposito = [];
      var source = [];

      if (data.esp_paises !== "" && data.esp_paises !== null) {
        paises = data.esp_paises.toString().split(",");
      }
      if (data.esp_partes !== "" && data.esp_partes !== null) {
        partes = data.esp_partes.toString().split(",");
      }
      if (data.esp_proposito !== "" && data.esp_proposito !== null) {
        proposito = data.esp_proposito.toString().split(",");
      }
      if (data.esp_source !== "" && data.esp_source !== null) {
        source = data.esp_source.toString().split(",");
      }

      this.especie = {
        esp_id: data.esp_id,
        esp_clase: data.esp_clase,
        esp_orden: data.esp_orden,
        esp_familia: data.esp_familia,
        esp_genero: data.esp_genero,
        esp_especie: data.esp_especie,
        esp_nombrecomun: data.esp_nombrecomun,

        esp_amenazada: data.esp_amenazada,
        esp_cat_amenaza: data.esp_cat_amenaza,
        esp_cat_anio: data.esp_cat_anio,
        esp_endemico: data.esp_endemico,
        esp_comercializada: data.esp_comercializada,
        esp_paises: paises,
        esp_partes: partes,
        esp_proposito: proposito,
        esp_source: source,
        esp_categoria: this.categorias.find(
          (p) => p.catesp_id === data.categoria.catesp_id
        ),

        esp_apendice: this.apendices.find(
          (p) => p.ape_id === data.apendice.ape_id
        ),

        titulomodal: "Editar Especie",
        boton: "Editar",
        filtro: "",
        accion: 1,
        nuevo: false,
        activo: false,
        wait: false,
      };
      this.showmodalespecie = true;
    },

    listaraccionespecie: function () {
      if (this.especie.nuevo) {
        this.registrarnuevaespecie();
      } else {
        this.editarespecie();
      }
    },

    getcategorias: async function () {
      try {
        this.showprogressppalespecies = true;
        let self = this;
        await this.axios
          .post("especies.php?dato=getcategorias")
          .then(function (response) {
            if (response.status === 200) {
              self.categorias = response.data;
              self.categoriascombo = response.data;
            } else {
              //mensajeERR("ERROR: " + response.status + ": " + response.data);
              //console.log("RESPONSE: " + response.data);
              //console.log("STATUS: " + response.status);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.showprogressppalespecies = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getapendices: async function () {
      try {
        this.showprogressppalespecies = true;
        let self = this;
        await this.axios
          .post("especies.php?dato=getapendices")
          .then(function (response) {
            if (response.status === 200) {
              self.apendices = response.data;
              self.apendicescombo = response.data;
            } else {
              //mensajeERR("ERROR: " + response.status + ": " + response.data);
              //console.log("RESPONSE: " + response.data);
              //console.log("STATUS: " + response.status);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.showprogressppalespecies = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    filtrarespecie: async function () {
      try {
        this.showprogressppalespecies = true;
        this.cargaskeletonespecies = true;
        let self = this;

        var parametros = {
          especie: this.especie.filtro,
          seleccion: this.clasificacionespecie,
        };

        await this.axios
          .post("especies.php?dato=getespecies", JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.especies = response.data;

              if (self.especies.length === 0) {
                self.mensajeWar("No se encontraron registros");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.showprogressppalespecies = false;
            self.cargaskeletonespecies = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    registrarnuevaespecie: async function () {
      try {
        var mensaje = this.validarRegistrarEspecie;

        if (mensaje === "") {
          this.showprogressppalespecies = true;
          let self = this;

          this.especie.boton = " Guardando... ";
          this.especie.wait = true;
          this.especie.activo = true;

          var parametros = {
            esp_clase: this.especie.esp_clase,
            esp_orden: this.especie.esp_orden,
            esp_familia: this.especie.esp_familia,
            esp_genero: this.especie.esp_genero,
            esp_especie: this.especie.esp_especie,
            esp_nombrecomun: this.especie.esp_nombrecomun,

            esp_amenazada: this.especie.esp_amenazada,
            esp_cat_amenaza: this.especie.esp_cat_amenaza,
            esp_cat_anio: this.especie.esp_cat_anio,
            esp_endemico: this.especie.esp_endemico,
            esp_comercializada: this.especie.esp_comercializada,
            esp_paises: this.especie.esp_paises.toString(),
            esp_esp_partes: this.especie.esp_partes.toString(),
            esp_proposito: this.especie.esp_proposito.toString(),
            esp_source: this.especie.esp_source.toString(),
            esp_categoria: this.especie.esp_categoria.catesp_id,
            esp_apendice: this.especie.esp_apendice.ape_id,
          };

          await this.axios
            .post("especies.php?dato=nuevaespecie", JSON.stringify(parametros))
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.mensajeOK(response.data);
                  self.showmodalespecie = false;
                  //self.getespecies();
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.showprogressppalespecies = false;
              self.especie.boton = "Guardar";
              self.especie.wait = false;
              self.especie.activo = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    editarespecie: async function () {
      try {
        var mensaje = this.validarEditarEspecie;

        if (mensaje === "") {
          this.showprogressppalespecies = true;
          let self = this;

          this.especie.boton = " Editando... ";
          this.especie.wait = true;
          this.especie.activo = true;

          var parametros = {
            esp_id: this.especie.esp_id,
            esp_clase: this.especie.esp_clase,
            esp_orden: this.especie.esp_orden,
            esp_familia: this.especie.esp_familia,
            esp_genero: this.especie.esp_genero,
            esp_especie: this.especie.esp_especie,
            esp_nombrecomun: this.especie.esp_nombrecomun,

            esp_amenazada: this.especie.esp_amenazada,
            esp_cat_amenaza: this.especie.esp_cat_amenaza,
            esp_cat_anio: this.especie.esp_cat_anio,
            esp_endemico: this.especie.esp_endemico,
            esp_comercializada: this.especie.esp_comercializada,
            esp_paises: this.especie.esp_paises.toString(),
            esp_partes: this.especie.esp_partes.toString(),
            esp_proposito: this.especie.esp_proposito.toString(),
            esp_source: this.especie.esp_source.toString(),
            esp_categoria: this.especie.esp_categoria.catesp_id,
            esp_apendice: this.especie.esp_apendice.ape_id,
          };

          await this.axios
            .post("especies.php?dato=editarespecie", JSON.stringify(parametros))
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.mensajeOK(response.data);
                  self.showmodalespecie = false;
                  self.filtrarespecie();
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.showprogressppalespecies = false;
              self.especie.boton = " Editar ";
              self.especie.wait = false;
              self.especie.activo = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    exportarEspecies: function () {
      var link = this.global.project + "especies.php?dato=descargarespecies";
      window.open(link, "_blank");
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },
  },

  computed: {
    validarRegistrarEspecie() {
      var mensajeAlerta = "";
      if (this.especie.esp_categoria === 0) {
        mensajeAlerta = "Debe seleccionar una categoría válida";
      } else if (
        this.especie.esp_clase === "" ||
        this.especie.esp_clase.length > 75
      ) {
        mensajeAlerta =
          "Debe digitar un nombre de clase válido, NO mayor a 75 caractéres";
      } else if (
        this.especie.esp_orden === "" ||
        this.especie.esp_orden.length > 75
      ) {
        mensajeAlerta =
          "Debe digitar un nombre de orden válido, NO mayor a 75 caractéres";
      } else if (
        this.especie.esp_familia === "" ||
        this.especie.esp_familia.length > 75
      ) {
        mensajeAlerta =
          "Debe digitar un nombre de familia válido, NO mayor a 75 caractéres";
      } else if (
        this.especie.esp_genero === "" ||
        this.especie.esp_genero.length > 75
      ) {
        mensajeAlerta =
          "Debe digitar un nombre de género válido, NO mayor a 75 caractéres";
      } else if (
        this.especie.esp_especie === "" ||
        this.especie.esp_especie.length > 75
      ) {
        mensajeAlerta =
          "Debe digitar un nombre de especie válido, NO mayor a 75 caractéres";
      } else if (this.especie.esp_amenazada) {
        if (
          this.especie.esp_cat_amenaza === "" ||
          this.especie.esp_cat_amenaza.length > 40
        ) {
          mensajeAlerta =
            "Si usted selecciona especie amenazada debe completar los campos: Categoría de la amenaza y año de la categoría";
        }
      } else if (this.especie.esp_comercializada) {
        if (
          this.especie.esp_paises.toString() === "" ||
          this.especie.esp_paises.toString().length > 45 ||
          this.especie.esp_partes.toString() === "" ||
          this.especie.esp_partes.toString().length > 45 ||
          this.especie.esp_proposito.toString() === "" ||
          this.especie.esp_proposito.toString().length > 45 ||
          this.especie.esp_source.toString() === "" ||
          this.especie.esp_source.toString().length > 45
        ) {
          mensajeAlerta =
            "Si usted selecciona especie comercializada debe completar los campos: Paises, Partes, Propósito y Source, máximo 45 caractéres por cada campo";
        }
      } else if (this.especie.esp_apendice === 0) {
        mensajeAlerta = "Debe seleccionar un apéndice válido";
      }

      return mensajeAlerta;
    },

    validarEditarEspecie() {
      var mensajeAlerta = "";
      if (this.especie.esp_categoria === 0) {
        mensajeAlerta = "Debe seleccionar una categoría válida";
      } else if (this.especie.esp_clase.length > 75) {
        mensajeAlerta =
          "Debe digitar un nombre de clase válido, mayor a 75 caractéres";
      } else if (this.especie.esp_orden.length > 75) {
        mensajeAlerta =
          "Debe digitar un nombre de orden válido, mayor a 75 caractéres";
      } else if (this.especie.esp_familia.length > 75) {
        mensajeAlerta =
          "Debe digitar un nombre de familia válido, mayor a 75 caractéres";
      } else if (this.especie.esp_genero.length > 75) {
        mensajeAlerta =
          "Debe digitar un nombre de género válido, mayor a 75 caractéres";
      } else if (
        this.especie.esp_especie === "" ||
        this.especie.esp_especie.length > 75
      ) {
        mensajeAlerta =
          "Debe digitar un nombre de especie válido, mayor a 75 caractéres";
      } else if (this.especie.esp_nombrecomun.length > 75) {
        mensajeAlerta =
          "Debe digitar un nombre de especie válido, mayor a 75 caractéres";
      } else if (this.especie.esp_amenazada) {
        if (
          this.especie.esp_cat_amenaza === "" ||
          this.especie.esp_cat_amenaza.length > 40
        ) {
          mensajeAlerta =
            "Si usted selecciona especie amenazada debe completar los campos: Categoría de la amenaza y año de la categoría";
        }
      } else if (this.especie.esp_comercializada) {
        if (
          this.especie.esp_paises.toString() === "" ||
          this.especie.esp_paises.toString().length > 45 ||
          this.especie.esp_partes.toString() === "" ||
          this.especie.esp_partes.toString().length > 45 ||
          this.especie.esp_proposito.toString() === "" ||
          this.especie.esp_proposito.toString().length > 45 ||
          this.especie.esp_source.toString() === "" ||
          this.especie.esp_source.toString().length > 45
        ) {
          mensajeAlerta =
            "Si usted selecciona especie comercializada debe completar los campos: Paises, Partes, Propósito y Source, máximo 45 caractéres por cada campo";
        }
      } else if (this.especie.esp_apendice === 0) {
        mensajeAlerta = "Debe seleccionar un apéndice válido";
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    this.getcategorias();
    this.getapendices();
    //document.body.style.zoom = "90%";
  },

  mounted() {
    if (localStorage.getItem("name")) {
      this.getcategorias();
    } else {
      this.$router.push("/");
    }
  },
};
</script>


<style scoped>
.flex-checkbox{
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.min-content-check{
  width: max-content;
}
</style>