<template>
  <div>
    <NavbarMain />

    <div class="ppal">
      <div class="child uno ml-4">
        <div class="grid">
          <div class="col-12">
            <DataTable
              :value="registrosreforestacion"
              v-show="!cargaskeletonregistrosref"
              responsiveLayout="scroll"
              :paginator="true"
              :rows="10"
            >
              <template #header>
                <div class="table-header">
                  Registros de Reforestación
                  <div class="p-inputgroup ml-2 mr-4">
                    <Dropdown
                      v-model="registroref.fincafiltro"
                      :options="fincascombo"
                      optionLabel="fin_nombre"
                      placeholder="Filtre x finca"
                    />
                  </div>

                  <div class="p-inputgroup mr-4">
                    <InputText
                      placeholder="Buscar x nombre científico"
                      v-model="registroref.filtro"
                      @keyup.enter="filtrarregistrosref"
                    />
                    <Button
                      icon="pi pi-search"
                      class="p-button-success"
                      @click="filtrarregistrosref"
                    />
                  </div>

                  <Button
                    icon="pi pi-plus"
                    @click="limpiarCamposNuevoRegistro()"
                  />
                </div>
              </template>

              <Column
                field="especie.esp_especie"
                header="Nombre"
              ></Column>
              <Column field="regref_fecha" header="Fecha"></Column>
              <Column field="finca.fin_nombre" header="finca"></Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-primary mr-2"
                    @click="listarmodalregistroref(slotProps.data)"
                  /> </template
              ></Column>
            </DataTable>
          </div>
          <div class="col-12">
            <DataTable :value="skeletons" v-show="cargaskeletonregistrosref">
              <Column field="ske1" header="Nombre científico">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
              <Column field="ske2" header="Fecha">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
              <Column field="ske3" header="Finca">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
              <Column field="ske4" header="">
                <template #body>
                  <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>

      <div class="child dos">
        <form>
          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Nombre especie</label>
              </div>
            </div>
          </div>

           <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <AutoComplete
                  class="font-italic"
                  id="selectedEspecie"
                  placeholder="Digite especie a buscar: Mangifera indica "
                  v-model="selectedEspecie"
                  :suggestions="filteredEspecies"
                  @complete="buscarEspecie($event)"
                  field="esp_especie"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Finca</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <Dropdown
                  v-model="registroref.regref_finca"
                  :options="fincascombo"
                  optionLabel="fin_nombre"
                  placeholder="Seleccionar una finca"
                  @change="onChangeFinca($event)"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Área de cobertura</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <Dropdown
                  v-model="registroref.regref_cobertura"
                  :options="coberturascombo"
                  optionLabel="cobref_detalle"
                  placeholder="Seleccionar una cobertura"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Fecha de registro</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <Calendar
                  id="icon"
                  placeholder="Fecha de registro: 2022-01-01"
                  :maxDate="today"
                  v-model="registroref.regref_fecha"
                  :showIcon="true"
                  dateFormat="yy-mm-dd"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Latitud</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <InputText
                  placeholder="Latitud: 11.106014"
                  v-model="registroref.regref_latitud"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Longitud</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <InputText
                  placeholder="Longitud: -74.1217839"
                  v-model="registroref.regref_longitud"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Altura (mts)</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <InputText
                  placeholder="Altura SNM: 665"
                  v-model="registroref.regref_asnm"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Cantidad individuos</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <InputText
                  placeholder="Cantidad de Individuos 14"
                  v-model="registroref.regref_cantidadindividuos"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Serial Inventario</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <InputText
                  placeholder="Rango seriales: 2022052313-2022052350"
                  v-model="registroref.regref_inventario"
                />
              </div>
            </div>
          </div>

          <div class="grid" v-show="!registroref.nuevo">
            <div class="col-12">
              <div class="p-inputgroup">
                <label>Observaciones generales</label>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <textarea
                  class="form-control"
                  v-model="registroref.regref_observaciones"
                  rows="3"
                  placeholder="Observaciones generales"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="grid" v-show="registroref.viewlink">
            <div class="col-12">
              <div class="p-inputgroup">
                <b-link :href="registroref.linkgoogle" target="_blank"
                  >Ver Mapa</b-link
                >
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <input
                  type="file"
                  class="form-control-file"
                  id="file_registroref"
                  ref="ref_files"
                  @change="onFileChange"
                  multiple
                />
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-12">
              <div class="p-inputgroup">
                <ProgressSpinner
                  class="mr-2"
                  v-show="registroref.wait"
                  style="width: 40px; height: 40px"
                  strokeWidth="8"
                  fill="#FFFFFF"
                  animationDuration=".5s"
                />
                <Button
                  :label="registroref.boton"
                  :disabled="registroref.activo"
                  icon="pi pi-check"
                  autofocus
                  @click="listaraccionref"
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="child tres">
        <div class="imagenes">
          <div class="article" v-for="imagen in images" :key="imagen.regmul_id">
            <b-img
              class="img"
              :src="imagen.img"
              :alt="imagen.regmul_nombre"
            ></b-img>
            <b-link :href="imagen.img" target="_blank">{{
              imagen.regmul_nombre
            }}</b-link>
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-secondary hijoarticle"
              @click="listarEliminarArchivo(imagen)"
            />
          </div>

          <div class="article" v-for="video in videos" :key="video.regmul_id">
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="video.source"
              allowfullscreen
            ></b-embed>
          </div>
        </div>

        <div v-show="cargaskeletonmultimedia">
          <div class="custom-skeleton p-4">
            <Skeleton width="100%" height="150px"></Skeleton>
            <div class="flex justify-content-center mt-3">
              <Skeleton width="4rem" height="2rem"></Skeleton>
              <Skeleton width="4rem" height="2rem"></Skeleton>
            </div>
          </div>

          <div class="custom-skeleton p-4">
            <Skeleton width="100%" height="150px"></Skeleton>
            <div class="flex justify-content-center mt-3">
              <Skeleton width="4rem" height="2rem"></Skeleton>
              <Skeleton width="4rem" height="2rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ppal">
      <div class="child uno ml-5">
        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Dropdown
                v-model="anioselected"
                :options="kpianios"
                optionLabel="anios"
                placeholder="Seleccionar año"
                @change="onChangeAnioReport($event)"
              />
            </div>
          </div>
        </div>
        <DataTable :value="kpiregistrosdepurados" responsiveLayout="scroll">
          <Column field="mes" header="Mes"></Column>
          <Column field="cantidad" header="Cantidad de registros"></Column>
        </DataTable>
      </div>

      <div class="child dos">
        <div>
          <div class="card">
            <h5>Registros de reforestación por mes</h5>
            <Chart
              type="bar"
              :data="basicData"
              :options="basicOptions"
              v-show="!cargaskeletonkpiregistros"
              :responsive="true"
            />
          </div>
        </div>
        <div v-show="cargaskeletonkpiregistros">
          <div class="card">
            <Skeleton height="2rem" class="mb-2"></Skeleton>
            <Skeleton width="100%" height="150px"></Skeleton>
          </div>
        </div>
      </div>

      <div class="child tres"></div>
    </div>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>

    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import NavbarMain from "@/components/NavbarMain.vue";
import * as moment from "moment";

export default {
  name: "Registroref",
  data() {
    return {
      registroref: {
        regref_id: 0,
        regref_especie: null,
        regref_nombrecientifico: "",
        regref_nombrecomun: "",
        regref_latitud: null,
        regref_longitud: null,
        regref_asnm: null,
        regref_cantidadindividuos: null,
        regref_fecha: "",
        regref_inventario: "",
        regref_finca: null,
        regref_cobertura: null,
        regref_observaciones: "",

        titulomodal: "",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        activo: false,
        filtro: "",
        fincafiltro: null,
        wait: false,
        viewlink: false,
        linkgoogle: "",
      },

      selectedEspecie: null,
      filteredEspecies: [],

      images: [],
      videos: [],

      skeletons: this.global.skeletons,
      meses: this.global.meses,
      kpiregistroscrudos: [],
      kpiregistrosdepurados: [],
      labels: [],
      data: [],
      kpianios: [],
      anioselected: null,

      today: new Date(this.global.today),

      cargaskeletonkpiregistros: false,
      cargaskeletonregistrosref: false,
      cargaskeletonmultimedia: false,
      showmodalregistroref: false,

      registrosreforestacion: [],
      especiescombo: [],
      fincascombo: [],
      fincascompletas: [],
      coberturascombo: [],

      selectedFiles: [],
      sizeSelectedFiles: 0,

      basicData: {
        labels: this.labels,
        datasets: [
          {
            label: "Cantidad de individuos",
            data: this.data,
            fill: false,
            borderColor: "#42A5F5",
            tension: 0.4,
          },
        ],
      },

      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
    };
  },
  components: {
    Toast,
    NavbarMain,
    ConfirmDialog,
  },

  methods: {
    onChangeAnioReport(event) {
      this.anioselected = event.value.anios;
      this.getkpi();
    },

    onChangeFinca(event) {
      var object = this.fincascompletas.find(
        (element) => element.fin_id === event.value.fin_id
      );
      this.registroref.regref_asnm = object.fin_altura;
      this.registroref.regref_latitud = object.fin_latitud;
      this.registroref.regref_longitud = object.fin_longitud;
    },

    getespecies: async function () {
      try {
        this.cargaskeletonregistrosbio = true;
        let self = this;
        await this.axios
          .post("especies.php?dato=getespeciescombo")
          .then(function (response) {
            if (response.status === 200) {
              self.especiescombo = response.data;
            } else {
              self.mensajeErr("Error al cargar las especies");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosbio = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    registrarnuevareforestacion: async function () {
      try {
        var mensaje = this.validarRegistrarRef;

        if (mensaje === "") {
          this.registroref.boton = " Guardando... ";
          this.registroref.wait = true;
          this.registroref.activo = true;

          let formData = new FormData();
          var nombresLargos = false;

          for (var i = 0; i < this.sizeSelectedFiles; i++) {
            formData.append("file[]", this.selectedFiles[i]);
            if (this.selectedFiles[i].name.length > 45) {
              nombresLargos = true;
            }
          }

          let day = this.registroref.regref_fecha.getDate();
          let month = this.registroref.regref_fecha.getMonth() + 1;
          let year = this.registroref.regref_fecha.getFullYear();


          let fecha =
            year +
            "-" +
            (month < 10 ? "0" + month : month) +
            "-" +
            (day < 10 ? "0" + day : day);
          
          formData.append(
            "regref_nombrecomun",
            this.registroref.regref_nombrecomun
          );
          formData.append(
            "regref_nombrecientifico",
            this.registroref.regref_nombrecientifico
          );

          formData.append("regref_latitud", this.registroref.regref_latitud);
          formData.append("regref_longitud", this.registroref.regref_longitud);
          formData.append("regref_asnm", this.registroref.regref_asnm);
          formData.append("regref_cantidadindividuos", this.registroref.regref_cantidadindividuos);

          formData.append("regref_fecha", this.registroref.regref_fecha);

          formData.append("regref_fecha", fecha);
          formData.append(
            "regref_inventario",
            this.registroref.regref_inventario
          );

          formData.append(
            "regref_observaciones",
            this.registroref.regref_observaciones
          );

          formData.append("regref_finca", this.registroref.regref_finca.fin_id);
          formData.append("regref_especie", this.selectedEspecie.esp_id);

          formData.append(
            "regref_cobertura",
            this.registroref.regref_cobertura.cobref_id
          );

          if (!nombresLargos) {
            await this.axios({
              method: "post",
              url: "reforestacion.php?dato=nuevoregistroref",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.includes(this.global.transaccionOK)) {
                    this.mensajeOK(response.data);
                    this.limpiarCamposNuevoRegistro();
                    this.filtrarregistrosref();
                  } else if (this.global.buscarapierror(response.data) !== "") {
                    let msj_user = this.global.buscarapierror(response.data);
                    this.mensajeWar(msj_user);
                  } else {
                    this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                  }
                } else {
                  this.global.mensajeErr(this.global.respuestaInvalida);
                }
              })
              .catch((error) => {
                this.$utilidad.printConsole(error);
              })
              .finally(() => {
                this.registroref.boton = " Guardar ";
                this.registroref.wait = false;
                this.registroref.activo = false;
              });
          } else {
            this.mensajeWar(
              "El nombre del archivo adjunto es muy largo, se permiten máximo 45 caracteres"
            );
            this.registroref.boton = " Guardar ";
            this.registroref.wait = false;
          }
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    editarregistroreforestacion: async function () {
      try {
        var mensaje = this.validarRegistrarRef;

        if (mensaje === "") {
          this.registroref.boton = " Editando... ";
          this.registroref.wait = true;
          this.registroref.activo = true;

          let formData = new FormData();
          var nombresLargos = false;

          for (var i = 0; i < this.sizeSelectedFiles; i++) {
            formData.append("file[]", this.selectedFiles[i]);
            if (this.selectedFiles[i].name.length > 45) {
              nombresLargos = true;
            }
          }

          let day = this.registroref.regref_fecha.getDate();
          let month = this.registroref.regref_fecha.getMonth() + 1;
          let year = this.registroref.regref_fecha.getFullYear();


          let fecha =
            year +
            "-" +
            (month < 10 ? "0" + month : month) +
            "-" +
            (day < 10 ? "0" + day : day);

          formData.append(
            "regref_nombrecomun",
            this.registroref.regref_nombrecomun
          );
          formData.append(
            "regref_nombrecientifico",
            this.registroref.regref_nombrecientifico
          );

          formData.append("regref_latitud", this.registroref.regref_latitud);
          formData.append("regref_longitud", this.registroref.regref_longitud);
          formData.append("regref_asnm", this.registroref.regref_asnm);
          formData.append("regref_cantidadindividuos", this.registroref.regref_cantidadindividuos);

          formData.append("regref_fecha", fecha);
          formData.append(
            "regref_inventario",
            this.registroref.regref_inventario
          );

          formData.append(
            "regref_observaciones",
            this.registroref.regref_observaciones
          );

          formData.append("regref_finca", this.registroref.regref_finca.fin_id);
          formData.append("regref_especie", this.selectedEspecie.esp_id);

          formData.append(
            "regref_cobertura",
            this.registroref.regref_cobertura.cobref_id
          );

          formData.append("regref_id", this.registroref.regref_id);
          if (!nombresLargos) {
            await this.axios({
              method: "post",
              url: "reforestacion.php?dato=editarregistroref",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.includes(this.global.transaccionOK)) {
                    this.mensajeOK(response.data);
                    this.limpiarCamposNuevoRegistro();
                    this.filtrarregistrosref();
                  } else if (this.global.buscarapierror(response.data) !== "") {
                    let msj_user = this.global.buscarapierror(response.data);
                    this.mensajeWar(msj_user);
                  } else {
                    this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                  }
                } else {
                  this.global.mensajeErr(this.global.respuestaInvalida);
                }
              })
              .catch((error) => {
                this.$utilidad.printConsole(error);
              })
              .finally(() => {
                this.registroref.boton = " Guardar ";
                this.registroref.wait = false;
                this.registroref.activo = false;
              });
          } else {
            this.mensajeWar(
              "El nombre del archivo adjunto es muy largo, se permiten máximo 45 caracteres"
            );
            this.registroref.boton = " Guardar ";
                this.registroref.wait = false;
          }
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtrarregistrosref: async function () {
      try {
        this.cargaskeletonregistrosref = true;
        let self = this;

         var apirest = "reforestacion.php?dato=getregistrosref";

        var parametros = {
          especie: this.registroref.filtro,
        };

        if (this.registroref.fincafiltro !== null) {
          apirest = "reforestacion.php?dato=getregistrosrefxfinca";
          parametros = {
            especie: this.registroref.filtro,
            finca: this.registroref.fincafiltro.fin_id,
          };
        }

        await this.axios
          .post(
            apirest,
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.registrosreforestacion = response.data;
              if (self.registrosreforestacion.length === 0) {
                self.mensajeWar(
                  "No se encontraron registros de reforestaciones"
                );
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosref = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getfincas: async function () {
      try {
        this.cargaskeletonregistrosref = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getfincascombo")
          .then(function (response) {
            if (response.status === 200) {
              self.fincascombo = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosref = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getfincascomplete: async function () {
      try {
        this.cargaskeletonregistrosbio = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getfincascombocomplete")
          .then(function (response) {
            if (response.status === 200) {
              self.fincascompletas = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosbio = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getcoberturas: async function () {
      try {
        this.cargaskeletonregistrosref = true;
        let self = this;
        await this.axios
          .post("reforestacion.php?dato=getcoberturasref")
          .then(function (response) {
            if (response.status === 200) {
              self.coberturascombo = response.data;
            } else {
              self.mensajeErr("Error al cargar las coberturas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonregistrosref = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getkpi: async function () {
      try {
        this.cargaskeletonkpiregistros = true;
        let self = this;

        var parametros = {
          year: this.anioselected,
        };

        await this.axios
          .post(
            "reforestacion.php?dato=getkpiregistrosref",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.kpiregistroscrudos = response.data;

              self.data = [];
              self.labels = [];
              self.kpiregistrosdepurados = [];

              for (var registro in self.kpiregistroscrudos) {
                var val = self.meses.find(
                  (m) => m.numero === self.kpiregistroscrudos[registro].mesnum
                );

                self.data.push(self.kpiregistroscrudos[registro].cantidad);
                self.labels.push(val.nombre);

                self.kpiregistrosdepurados.push({
                  mes: val.nombre,
                  cantidad: self.kpiregistroscrudos[registro].cantidad,
                });
              }

              self.basicData = {
                labels: self.labels,
                datasets: [
                  {
                    label: "Cantidad de individuos",
                    data: self.data,
                    fill: false,
                    borderColor: "#42A5F5",
                    backgroundColor: "#42A5F5",
                    tension: 0.4,
                  },
                ],
              };
            } else {
              self.mensajeErr("Error al cargar KPIs reforestación");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonkpiregistros = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getanios: async function () {
      try {
        this.cargaskeletonkpiregistros = true;
        let self = this;
        await this.axios
          .post("reforestacion.php?dato=getanioskpiref")
          .then(function (response) {
            if (response.status === 200) {
              self.kpianios = response.data;
            } else {
              self.mensajeErr("Error al cargar KPIs años disponibles");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonkpiregistros = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.registroref = {
        regref_id: 0,
        regref_latitud: null,
        regref_longitud: null,
        regref_especie: null,
        regref_nombrecomun: "",
        regref_nombrecientifico: "",
        regref_asnm: null,
        regref_cantidadindividuos: null,
        regref_fecha: "",
        regref_inventario: "",
        regref_finca: null,
        regref_cobertura: null,
        regref_observaciones: "",
        titulomodal: "",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        fincafiltro: null,
        wait: false,
        activo: false,
      };

      this.selectedEspecie = null;
      this.selectedFiles = [];
      this.sizeSelectedFiles = 0;
      this.$refs.ref_files.value = null;

      this.images = [];
      this.videos = [];
    },

    openmodalregistroref: function () {
      this.registroref = {
        regref_id: 0,
        regref_latitud: 0.0,
        regref_longitud: 0.0,
        regref_especie: null,
        regref_nombrecomun: "",
        regref_nombrecientifico: "",
        regref_asnm: null,
        regref_cantidadindividuos: null,
        regref_fecha: "",
        regref_inventario: "",
        regref_finca: 0,
        regref_cobertura: 0,
        regref_observaciones: "",

        titulomodal: "Nuevo registro",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        activo: false,
        filtro: "",
        fincafiltro: null,
        wait: false,
      };

      this.showmodalregistroref = true;
    },

    elminarArchivo: async function (regmul_id) {
      try {
        this.cargaskeletonmultimedia = true;
        let self = this;

        var parametros = {
          regmul_id: regmul_id,
        };

        await this.axios
          .post(
            "reforestacion.php?dato=eliminararchivoref",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.mensajeOK(response.data);
              self.limpiarCamposNuevoRegistro();
            } else {
              self.mensajeErr("Error al eliminar archivo");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonmultimedia = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    listarEliminarArchivo: function (data) {
      this.$confirm.require({
        message: "Estas seguro que deseas eliminar la imagen?",
        header: "Alerta",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.elminarArchivo(data.regmul_id);
        },
        reject: () => {
          this.mensajeWar("No se eliminaron archivos");
        },
      });
    },

    listarmodalregistroref: async function (data) {
      this.images = [];
      this.videos = [];

      let fechaparcial = data.regref_fecha;
      let fecha = new Date(fechaparcial);
      this.registroref = {
        titulomodal: "Nuevo registro de reforestacion",
        boton: "Editar",
        accion: 1,
        nuevo: false,
        activo: false,
        filtro: "",
        fincafiltro: null,
        wait: false,

        regref_id: data.regref_id,
        regref_latitud: data.regref_latitud,
        regref_longitud: data.regref_longitud,

        regref_nombrecomun: data.regref_nombrecomun,
        regref_nombrecientifico: data.regref_nombrecientifico,

        regref_asnm: data.regref_asnm,
        regref_cantidadindividuos: data.regref_cantidadindividuos,

        regref_fecha: fecha,

        regref_inventario: data.regref_inventario,
        regref_observaciones: data.regref_observaciones,
        viewlink: true,
        linkgoogle:
          "https://www.google.com/maps/@" +
          data.regref_latitud +
          "," +
          data.regref_longitud +
          ",15z",

        regref_finca: this.fincascombo.find(
          (p) => p.fin_id === data.finca.fin_id
        ),

        regref_especie: this.especiescombo.find(
          (p) => p.esp_id === data.especie.esp_id
        ),

        regref_cobertura: this.coberturascombo.find(
          (p) => p.cobref_id === data.cobertura.cobref_id
        ),
      };

      this.selectedEspecie = {
        esp_id: data.especie.esp_id,
        esp_especie: data.especie.esp_especie,
      };

      try {
        this.cargaskeletonmultimedia = true;
        let self = this;

        var parametros = {
          regref_id: data.regref_id,
        };

        await this.axios
          .post(
            "reforestacion.php?dato=getmultimediaxregistrosref",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.images = response.data.filter(
                (multimedia) =>
                  multimedia.regmul_tipoarchivo === self.global.TYPE_FILE_IMAGE
              );
              self.videos = response.data.filter(
                (multimedia) =>
                  multimedia.regmul_tipoarchivo === self.global.TYPE_FILE_VIDEO
              );
              if (self.images.length === 0 && self.videos.length === 0) {
                self.mensajeWar("No se encontraron registros multimedia");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonmultimedia = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    buscarEspecie(event) {
      if (event.query.length > 3) {
        this.filteredEspecies = this.especiescombo.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(event.query.toLowerCase())
          )
        );
      }
    },

    listaraccionref: function () {
      if (this.registroref.nuevo) {
        this.registrarnuevareforestacion();
      } else {
        this.editarregistroreforestacion();
      }
    },

    onFileChange(event) {
      this.sizeSelectedFiles = event.target.files.length;
      this.selectedFiles = event.target.files;
    },
  },

  computed: {
    validarRegistrarRef() {
      var mensajeAlerta = "";
      if (this.selectedEspecie === null) {
        mensajeAlerta =
          "Debe seleccionar una especie existente en la base de datos";
      } else if (this.selectedEspecie.esp_id === undefined) {
        mensajeAlerta =
          "Debe seleccionar una especie existente en la base de datos";
      }  else if (this.registroref.regref_finca === null) {
        mensajeAlerta = " Debe seleccionar una finca ";
      } else if (this.registroref.regref_cobertura === null) {
        mensajeAlerta = "Debe seleccionar un area de cobertura";
      } else if (
        this.registroref.regref_latitud === null ||
        Number.isNaN(Number(this.registroref.regref_latitud)) ||
        this.registroref.regref_latitud > 180 ||
        this.registroref.regref_latitud < -180 ||
        this.registroref.regref_latitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Latitud válido ej: 11.144433 separando decimales con punto (.) ";
      } else if (
        this.registroref.regref_longitud === null ||
        Number.isNaN(Number(this.registroref.regref_longitud)) ||
        this.registroref.regref_longitud > 180 ||
        this.registroref.regref_longitud < -180 ||
        this.registroref.regref_longitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Longitud válido ej: -74.118597 separando decimales con punto (.) ";
      } else if (
        this.registroref.regref_asnm === null ||
        !this.global.validarNumeroEntero(this.registroref.regref_asnm) ||
        this.registroref.regref_asnm < 0 ||
        this.registroref.regref_asnm > 10000 ||
        this.registroref.regref_asnm === ""
      ) {
        mensajeAlerta = "Debe digitar una ASNM válida";
      } else if (
        this.registroref.regref_cantidadindividuos === null ||
        !this.global.validarNumeroEntero(this.registroref.regref_cantidadindividuos) ||
        this.registroref.regref_cantidadindividuos < 0 ||
        this.registroref.regref_cantidadindividuos === ""
      ) {
        mensajeAlerta = "Debe digitar una Cantidad de individuos sembrados válida";
      }
       else if (
        !moment(this.registroref.regref_fecha, "YYYY/MM/DD", true).isValid()
      ) {
        mensajeAlerta = "Debe digitar una fecha válida";
      } else if (
        this.registroref.regref_inventario.length > 35
      ) {
        mensajeAlerta = "Debe digitar un rango de seriales de inventario válido, máximo 35 caracteres";
      } else if (this.registroref.regref_observaciones.length > 955) {
        mensajeAlerta =
          "Las observaciones generales no deben superar los 955 caracteres";
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    this.getespecies();
    this.getfincas();
    this.getfincascomplete();
    this.getcoberturas();
    this.getanios();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
}

.uno {
  margin-right: 20px;
  flex-grow: 10;
}

.dos {
  margin-left: 20px;
  flex-grow: 5;
}

.tres {
  flex-grow: 5;
}

.imagenes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
}

.article {
  height: 100%;
  display: grid;
  place-content: center;
}

.hijoarticle {
  left: 50%;
  top: 5%;
}

.img {
  width: 300px;
  height: 300px;
}
</style>
