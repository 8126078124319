import Vue from "vue";
import VueRouter from "vue-router";
//import Home from '../views/Home.vue'
import Login from "../views/Login.vue";
import Usuarios from "../views/Usuarios.vue";
import Especies from "../views/Especies.vue";
import Index from "../views/Index.vue";
import Parametrizacion from "../views/Parametrizacion.vue";
import Registrobio from "../views/Registrobio.vue";
import Registroref from "../views/Registroref.vue";
import Registrodef from "../views/Registrodef.vue";
import Fincas from "../views/Fincas.vue";
import Oferta from "../views/Oferta.vue";
import Economico from "../views/Economico.vue";
import Historicos from "../views/Historicos.vue";
import Agencias from "../views/Agencias.vue";
import GuiasLocales from "../views/GuiasLocales.vue";
import Cliente from "../views/Cliente.vue";

import AdminArchivos from "../views/AdminArchivos.vue";
import Archivos from "../views/Archivos.vue";
import Instructivos from "../views/Instructivos.vue";
import CambioClave from "../views/CambioClave.vue";
import OpcionesUsuario from "../views/OpcionesUsuario.vue";
import IndexFrameTest from "../views/IndexFrameTest.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "*",
    name: "Index",
    component: Index,
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
  },
  {
    path: "/especies",
    name: "Especies",
    component: Especies,
  },
  {
    path: "/parametrizacion",
    name: "Parametrizacion",
    component: Parametrizacion,
  },
  {
    path: "/registrobio",
    name: "Registrobio",
    component: Registrobio,
  },
  {
    path: "/registroref",
    name: "Registroref",
    component: Registroref,
  },
  {
    path: "/registrodef",
    name: "Registrodef",
    component: Registrodef,
  },
  {
    path: "/fincas",
    name: "Fincas",
    component: Fincas,
  },
  {
    path: "/oferta",
    name: "Oferta",
    component: Oferta,
  },
  {
    path: "/economico",
    name: "Economico",
    component: Economico,
  },
  {
    path: "/historico",
    name: "Historicos",
    component: Historicos,
  },

  {
    path: "/agencias",
    name: "Agencias",
    component: Agencias,
  },

  {
    path: "/tablero-riqueza",
    name: "IndexFrameTest",
    component: IndexFrameTest,
  },

  {
    path: "/guiaslocales",
    name: "GuiasLocales",
    component: GuiasLocales,
  },

  {
    path: "/adminarchivos",
    name: "AdminArchivos",
    component: AdminArchivos,
  },

  {
    path: "/archivos",
    name: "Archivos",
    component: Archivos,
  },

  {
    path: "/instructivos",
    name: "Instructivos",
    component: Instructivos,
  },

  {
    path: "/elcliente",
    name: "elcliente",
    component: Cliente,
  },

  {
    path: "/opcionesusuario",
    name: "opcionesusuario",
    component: OpcionesUsuario,
  },

  {
    path: "/cambioclave",
    name: "cambioclave",
    component: CambioClave,
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
