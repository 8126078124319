<template>
  <div>
    <NavbarMain />

    <div class="container mt-4">
      <div class="grid">
        <div class="col-12">
          <DataTable
            :value="fincas"
            v-show="!cargaskeletonfincas"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
          >
            <template #header>
              <div class="table-header">
                Fincas
                <div class="p-inputgroup mr-4 ml-4">
                  <InputText
                    placeholder="Buscar x nombre de finca"
                    v-model="finca.filtro"
                    @keyup.enter="filtrarfincas"
                  />
                  <Button
                    icon="pi pi-search"
                    class="p-button-success"
                    @click="filtrarfincas"
                  />
                </div>

                <Button icon="pi pi-plus" @click="openmodalfinca()" />
              </div>
            </template>

            <Column field="fin_nombre" header="Nombre"></Column>
            <Column
              field="ubicacionfinca.ub_detalle"
              header="Ubicacion"
            ></Column>
            <Column field="fin_hectareas" header="Hectareas"></Column>
            <Column>
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-primary mr-2"
                  @click="filtrarfinca(slotProps.data.fin_id)"
                /> </template
            ></Column>
          </DataTable>
        </div>
        <div class="col-12">
          <DataTable :value="skeletons" v-show="cargaskeletonfincas">
            <Column field="ske1" header="Finca">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske2" header="Fecha">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske3" header="Finca">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske4" header="">
              <template #body>
                <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible.sync="showmodalfinca"
      position="center"
      :modal="true"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ finca.titulomodal }} finca</h4>
      </template>
      <div>
        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Ubicación</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Dropdown
                v-model="finca.fin_ubicacion"
                :options="ubicacionesfincas"
                optionLabel="ub_detalle"
                placeholder="Seleccionar una ubicación"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Ruta</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <Dropdown
                v-model="finca.fin_ruta"
                :options="rutas"
                optionLabel="rut_nombre"
                placeholder=" . . . . . . . . . Seleccionar una ruta . . . . . . . . . . "
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Nombre</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Nombre: Villa Luisa"
                v-model="finca.fin_nombre"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Latitud</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Latitud: 11.106014"
                v-model="finca.fin_latitud"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Longitud</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Longitud: -74.1217839"
                v-model="finca.fin_longitud"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Hectareas</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText
                placeholder="Hectareas: 100"
                v-model="finca.fin_hectareas"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Altura (ASNM)</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <InputText placeholder="ASNM: 800" v-model="finca.fin_altura" />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Quebradas</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <MultiSelect
                v-model="quebradasselected"
                :options="quebradas"
                optionLabel="que_detalle"
                placeholder="Seleccionar quebradas"
                display="chip"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="!finca.nuevo">
          <div class="col-12">
            <div class="p-inputgroup">
              <label>Tipos de bosques</label>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <div class="p-inputgroup">
              <MultiSelect
                v-model="tiposdebosquesselected"
                :options="tiposdebosques"
                optionLabel="tpb_detalle"
                placeholder="Seleccionar tipos de bosques"
                display="chip"
              />
            </div>
          </div>
        </div>

        <div class="grid" v-show="finca.viewlink">
          <div class="col-12">
            <div class="p-inputgroup">
              <b-link :href="finca.linkgoogle" target="_blank">Ver Mapa</b-link>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="finca.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="finca.boton"
          icon="pi pi-check"
          autofocus
          @click="registrarnuevafinca"
        />
      </template>
    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";

export default {
  name: "Fincas",
  data() {
    return {
      finca: {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,
        fin_ruta: null,

        fin_altura: null,

        fin_actividadeconomica: 1,
        fin_categoriaobservadora: 1,
        fin_participacionfemenina: 1,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      },

      skeletons: this.global.skeletons,
      cargaskeletonfincas: false,
      showmodalfinca: false,

      fincas: [],
      quebradas: [],
      tiposdebosques: [],
      ubicacionesfincas: [],
      rutas: [],
      quebradasselected: null,
      tiposdebosquesselected: null,
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    registrarnuevafinca: async function () {
      try {
        var mensaje = this.validarRegistrarFin;

        if (mensaje === "") {
          this.finca.boton = " Guardando... ";
          this.finca.wait = true;

          var parametros = {
            fin_id: this.finca.fin_id,
            fin_nombre: this.finca.fin_nombre,
            fin_latitud: this.finca.fin_latitud,
            fin_longitud: this.finca.fin_longitud,

            fin_hectareas: this.finca.fin_hectareas,
            fin_ubicacion: this.finca.fin_ubicacion.ub_id,
            fin_ruta: this.finca.fin_ruta.rut_id,
            fin_altura: this.finca.fin_altura,

            fin_actividadeconomica: this.finca.fin_actividadeconomica,
            fin_categoriaobservadora: this.finca.fin_categoriaobservadora,
            fin_participacionfemenina: this.finca.fin_participacionfemenina,

            tiposdebosquesselected: this.tiposdebosquesselected,
            quebradasselected: this.quebradasselected,
          };

          await this.axios
            .post("fincas.php?dato=nuevafinca", JSON.stringify(parametros))
            .then((response) => {
              if (response.status === 200) {
                if (response.data.includes(this.global.transaccionOK)) {
                  this.mensajeOK(response.data);
                  this.limpiarCamposNuevoRegistro();
                  this.filtrarfincas();
                } else if (this.global.buscarapierror(response.data) !== "") {
                  let msj_user = this.global.buscarapierror(response.data);
                  this.mensajeWar(msj_user);
                } else {
                  this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                }
              } else {
                this.global.mensajeErr(this.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              this.finca.boton = " Guardar ";
              this.finca.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtrarfincas: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          filtro: this.finca.filtro,
        };

        await this.axios
          .post("fincas.php?dato=getfincas", JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.fincas = response.data;
              if (self.fincas.length === 0) {
                self.mensajeWar("No se encontraron fincas");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getubicaciones: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getubicacionesfincas")
          .then(function (response) {
            if (response.status === 200) {
              self.ubicacionesfincas = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getRutas: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("rutas.php?dato=getrutas")
          .then(function (response) {
            if (response.status === 200) {
              self.rutas = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getquebradas: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("quebradas.php?dato=getquebradascombo")
          .then(function (response) {
            if (response.status === 200) {
              self.quebradas = response.data;
            } else {
              self.mensajeErr("Error al cargar quebradas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposdebosques: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("tiposdebosque.php?dato=gettiposdebosquescombo")
          .then(function (response) {
            if (response.status === 200) {
              self.tiposdebosques = response.data;
            } else {
              self.mensajeErr("Error al cargar tipos de bosques");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    filtrarfinca: async function (id) {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          fin_id: id,
        };

        await this.axios
          .post(
            "fincas.php?dato=getfincabasicoconparametros",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.listarmodalfinca(response.data[0]);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.finca = {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,
        fin_ruta: null,

        fin_altura: null,

        fin_actividadeconomica: 1,
        fin_categoriaobservadora: 1,
        fin_participacionfemenina: 1,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      };

      this.showmodalfinca = false;
    },

    openmodalfinca: function () {
      this.finca = {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,
        fin_ruta: null,

        fin_altura: null,

        fin_actividadeconomica: 1,
        fin_categoriaobservadora: 1,
        fin_participacionfemenina: 1,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      };

       this.quebradasselected = [],
      this.tiposdebosquesselected = [];

      this.showmodalfinca = true;
    },

    listarmodalfinca: async function (data) {
      this.finca = {
        titulomodal: " Editar ",
        boton: "Editar",
        accion: 1,
        nuevo: false,
        filtro: "",

        fin_id: data.fin_id,
        fin_nombre: data.fin_nombre,
        fin_latitud: data.fin_latitud,
        fin_longitud: data.fin_longitud,
        fin_altura: data.fin_altura,

        fin_hectareas: data.fin_hectareas,

        viewlink: true,
        linkgoogle:
          "https://www.google.com/maps/@" +
          data.fin_latitud +
          "," +
          data.fin_longitud +
          ",15z",

        fin_ubicacion: this.ubicacionesfincas.find(
          (p) => p.ub_id === data.ubicacionfinca.ub_id
        ),
        
        fin_ruta: this.rutas.find(
          (p) => p.rut_id === data.ruta.rut_id
        ),
        


        fin_actividadeconomica: data.fin_actividadeconomica,
        fin_categoriaobservadora: data.fin_categoriaobservadora,
        fin_participacionfemenina: data.fin_participacionfemenina,
      };

      this.quebradasselected = data.quebradas,
      this.tiposdebosquesselected = data.tiposdebosques;
      this.showmodalfinca = true;
    },
  },

  computed: {
    validarRegistrarFin() {
      var mensajeAlerta = "";
      if (this.finca.fin_ubicacion === null) {
        mensajeAlerta = " Debe seleccionar una ubicación ";
      } 
      else if (this.finca.fin_ruta === null) {
        mensajeAlerta = " Debe seleccionar una ruta ";
      } 
       else if (
        this.finca.fin_nombre === "" ||
        this.finca.fin_nombre.length > 45
      ) {
        mensajeAlerta =
          "Debe digitar un nombre válido, no debe superar los 45 caracteres";
      }  else if (
        this.finca.fin_latitud === null ||
        Number.isNaN(Number(this.finca.fin_latitud)) ||
        this.finca.fin_latitud > 180 ||
        this.finca.fin_latitud < -180 ||
        this.finca.fin_latitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Latitud válido ej: 11.144433 separando decimales con punto (.) ";
      } else if (
        this.finca.fin_longitud === null ||
        Number.isNaN(Number(this.finca.fin_longitud)) ||
        this.finca.fin_longitud > 180 ||
        this.finca.fin_longitud < -180 ||
        this.finca.fin_longitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Longitud válido ej: -74.118597 separando decimales con punto (.) ";
      } else if (
        this.finca.fin_hectareas === null ||
        !this.global.validarNumeroEntero(this.finca.fin_hectareas) ||
        this.finca.fin_hectareas < 0 ||
        this.finca.fin_hectareas > 10000 ||
        this.finca.fin_hectareas === ""
      ) {
        mensajeAlerta = "Debe digitar un N° de hectareas válido";
      } else if (
        this.finca.fin_altura === null ||
        !this.global.validarNumeroEntero(this.finca.fin_altura) ||
        this.finca.fin_altura < 0 ||
        this.finca.fin_altura > 10000 ||
        this.finca.fin_altura === ""
      ) {
        mensajeAlerta = "Debe digitar un N° mts de altura válido";
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    this.getubicaciones();
    this.getRutas();
    this.getquebradas();
    this.gettiposdebosques();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
}

.uno {
  margin-right: 20px;
  flex-grow: 10;
}

.dos {
  margin-left: 20px;
  flex-grow: 5;
}

.tres {
  flex-grow: 5;
}

.imagenes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
}

.article {
  height: 100%;
  display: grid;
  place-content: center;
}

.img {
  width: 300px;
  height: 300px;
}
</style>
