<template>
  <div id="app">
    <router-view />
    <footer>
      <img
          alt="logo"
          src="@/assets/footer_2.png"
          class="responsive"
        />
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  data() {
    return {
      
    };
  },

  methods: {
  },

    created: function () {
    document.body.style.zoom = "85%";

  },

};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body{
  margin: 0;

  position: relative;
  padding-bottom: 6em;
  min-height: 120vh;
}

.responsive {
  width: 70%;
  height: auto;
}

footer { 

text-align: center;

position: absolute;
bottom: 0;
width: 100%;
} 

input {
 caret-color: darkblue;
}

.console{
  border: solid red 2px;
}
</style>
