<template>
  <div>
    <NavbarMain />

    <div class="container mt-4">
      <div class="grid">
        <div class="col-12">
          <DataTable
            :value="archivos"
            v-show="!cargaskeletonarchivos"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
          >
            <template #header>
              <div class="table-header">
                Busqueda de Archivos
                <div class="p-inputgroup mr-4 ml-4">
                  <InputText
                    placeholder="Buscar x nombre de archivo local"
                    v-model="archivo.filtro"
                    @keyup.enter="filtrararchivos"
                    autocomplete="off"
                  />
                  <Button
                    icon="pi pi-search"
                    class="p-button-success"
                    @click="filtrararchivos"
                  />
                </div>

                <Button icon="pi pi-plus" @click="openmodalarchivo()" />
              </div>
            </template>

            <Column field="arc_nombre" header="Nombre"></Column>

            <Column>
              <template #body="slotProps">
                <b-link :href="slotProps.data.link_evento" target="_blank">{{
                  slotProps.data.arc_nombrefile
                }}</b-link>
              </template></Column
            >
          </DataTable>
        </div>
        <div class="col-12">
          <DataTable :value="skeletons" v-show="cargaskeletonarchivos">
            <Column field="ske1" header="Nombre">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske2" header="Link descarga">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible.sync="showmodalarchivo"
      position="center"
      :modal="true"
      :containerStyle="{ width: '100vw' }"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ archivo.titulomodal }} archivo</h4>
      </template>

      <form>
      <div class="p-fluid grid formgrid">
        <div class="field col-12">
          <label for="arc_nombre" v-show="!archivo.nuevo">Nombre</label>
          <InputText
            id="arc_nombre"
            placeholder="Nombre: Manual de especies"
            v-model="archivo.arc_nombre"
          />
        </div>

 
          <div class="field col-12">
              <input
                type="file"
                class="form-control-file"
                id="arc_file"
                ref="arc_file"
                @change="onFileChange"
              />
          </div>

      </div>
      </form>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="archivo.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="archivo.boton"
          icon="pi pi-check"
          autofocus
          @click="registrarnuevaarchivo"
        />
      </template>


    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "AdminArchivos",

  data() {
    return {
      archivo: {
        arc_id: 0,
        arc_nombre: "",
        arc_nombrefile: "",
        arc_ruta: "public_ftp/archivosgenerales",

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      },

      skeletons: this.global.skeletons,
      cargaskeletonarchivos: false,
      showmodalarchivo: false,

      archivos: [],
      selectedFiles: [],
      sizeSelectedFiles: 0,
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    registrarnuevaarchivo: async function () {
      try {
        var mensaje = this.validarRegistrarArchivo;

        if (mensaje === "") {
          this.archivo.boton = " Guardando... ";
          this.archivo.wait = true;

          let formData = new FormData();
          var nombresLargos = false;

          for (var i = 0; i < this.sizeSelectedFiles; i++) {
            formData.append("file[]", this.selectedFiles[i]);
            if (this.selectedFiles[i].name.length > 100) {
              nombresLargos = true;
            }
          }

          formData.append("arc_id", this.archivo.arc_id);
          formData.append("arc_nombre", this.archivo.arc_nombre);
          formData.append("arc_nombrefile", this.archivo.arc_nombrefile);
          formData.append("arc_ruta", this.archivo.arc_ruta);


       if (!nombresLargos) {
          await this.axios({
              method: "post",
              url: "archivos.php?dato=subirarchivo",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.includes(this.global.transaccionOK)) {
                  this.mensajeOK(response.data);
                  this.limpiarCamposNuevoRegistro();
                  this.filtrararchivos();
                } else if (this.global.buscarapierror(response.data) !== "") {
                  let msj_user = this.global.buscarapierror(response.data);
                  this.mensajeWar(msj_user);
                } else {
                  this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                }
              } else {
                this.global.mensajeErr(this.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              this.archivo.boton = " Guardar ";
              this.archivo.wait = false;
            });

            } else {
            this.mensajeWar(
              "El nombre del archivo adjunto es muy largo, se permiten máximo 100 caracteres"
            );
            this.archivo.boton = " Guardar ";
              this.archivo.wait = false;
          }
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtrararchivos: async function () {
      try {
        this.cargaskeletonarchivos = true;
        let self = this;

        var parametros = {
          filtro: this.archivo.filtro,
        };

        await this.axios
          .post(
            "archivos.php?dato=getarchivosconvinculo",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.archivos = response.data;
              if (self.archivos.length === 0) {
                self.mensajeWar(
                  "No se encontraron archivos con ese criterio de busqueda"
                );
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarchivos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.archivo = {
        arc_id: 0,
        arc_nombre: "",
        arc_nombrefile: "",
        arc_ruta: "",

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      };

            this.selectedFiles = [];
      this.sizeSelectedFiles = 0;

      this.showmodalarchivo = false;
    },

    openmodalarchivo: function () {
      this.archivo = {
        arc_id: 0,
        arc_nombre: "",
        arc_nombrefile: "",
        arc_ruta: "",

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      };

      this.showmodalarchivo = true;
    },

    listarmodalarchivo: async function (data) {
      this.archivo = {
        titulomodal: " Editar ",
        boton: "Editar",
        accion: 1,
        nuevo: false,

        arc_id: data.arc_id,
        arc_nombre: data.arc_nombre,
        arc_nombrefile: data.arc_nombrefile,
        arc_ruta: data.arc_ruta,
      };

      this.showmodalarchivo = true;
    },

    onFileChange(event) {
      //console.log(event);
      this.sizeSelectedFiles = event.target.files.length;
      this.selectedFiles = event.target.files;
    },
  },

  computed: {
    validarRegistrarArchivo() {
      var mensajeAlerta = "";
      if (
        this.archivo.arc_nombre === "" ||
        this.archivo.arc_nombre.length > 100
      ) {
        mensajeAlerta =
          "Debe digitar un nombre válido, no debe superar los 100 caracteres";
      } 

      return mensajeAlerta;
    },
  },

  created: function () {},

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>
