import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
//axios.defaults.baseURL = "http://localhost/apiswisscontact/model/";
//axios.defaults.baseURL = "https://www.solincosta.com/apiswisscontact/model/";
//axios.defaults.baseURL = "https://api.turismocientificominca.com/model/";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'primeflex/primeflex.css'

import 'primevue/resources/themes/saga-green/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                          //icons

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

import PrimeVue from 'primevue/config';

//Components PrimeVue
import ProgressBar from 'primevue/progressbar';
import Message from 'primevue/message';
import Menubar from 'primevue/menubar';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ToastService from 'primevue/toastservice';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import InputSwitch from 'primevue/inputswitch';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import Chips from 'primevue/chips';
import RadioButton from 'primevue/radiobutton';
import AutoComplete from 'primevue/autocomplete';
import SelectButton from 'primevue/selectbutton';
import MultiSelect from 'primevue/multiselect';
import Badge from 'primevue/badge';
//import Image from 'primevue/image';
import Chart from 'primevue/chart';
import Panel from 'primevue/panel'
import Divider from 'primevue/divider';
import ConfirmationService from 'primevue/confirmationservice';

//import util from 'util/util';
//Vue.config.globalProperties.$utilidad = util;
 //console.log('VUE CONFIG: ' + JSON.stringify(Vue.config));
import utilidad from './util/util';
Vue.prototype.global = utilidad;

//Use Components
Vue.component("ProgressBar", ProgressBar);
Vue.component("Message", Message);
Vue.component("Menubar", Menubar);
Vue.component("InputText", InputText);
Vue.component("Button", Button);
Vue.component("Dropdown", Dropdown);
Vue.component("Editor", Editor);
Vue.component("Calendar", Calendar);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("Dialog", Dialog);
Vue.component("Checkbox", Checkbox);
Vue.component("Password", Password);
Vue.component("Skeleton", Skeleton);
Vue.component("ProgressSpinner", ProgressSpinner);
Vue.component("InputSwitch", InputSwitch);
Vue.component("RadioButton", RadioButton);
Vue.component("SelectButton", SelectButton);
Vue.component("Badge", Badge);
//Vue.component("Image", Image);
Vue.component("Chart", Chart);
Vue.component("Chips", Chips);
Vue.component("MultiSelect", MultiSelect);
Vue.component("AutoComplete", AutoComplete);
Vue.component("Panel", Panel);
Vue.component("Divider", Divider);




Vue.use(VueAxios, axios);
Vue.use(ToastService);
Vue.use(ConfirmationService);
//Vue.use(utilidad);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(PrimeVue, {
  locale: {
    startsWith: 'Iniciar con',
    contains: 'Contiene',
    notContains: 'No contiene',
    endsWith: 'Finaliza con',
    equals: 'Igual',
    notEquals: 'No es igual',
    noFilter: 'No Filter',
    lt: 'Less than',
    lte: 'Less than or equal to',
    gt: 'Greater than',
    gte: 'Greater than or equal to',
    dateIs: 'Date is',
    dateIsNot: 'Date is not',
    dateBefore: 'Date is before',
    dateAfter: 'Date is after',
    clear: 'Limpiar',
    apply: 'Aplicar',
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Add Rule',
    removeRule: 'Remove Rule',
    accept: 'Si',
    reject: 'No',
    choose: 'Elegir',
    upload: 'Subir',
    cancel: 'Cancelar',
    dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    dayNamesMin: ["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
    monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Deciembre"],
    monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    today: 'Hoy',
    weekHeader: 'Wk',
    firstDayOfWeek: 0,
    dateFormat: 'mm/dd/yy',
    weak: 'Débil',
    medium: 'Normal',
    strong: 'Fuerte',
    passwordPrompt: 'Ingrese una clave',
    emptyFilterMessage: 'No se encontraron resultados',
    emptyMessage: 'No available options'

    // ... más configuraciones
  },
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
