<template>
  <div>
    <NavbarMain />

    <div class="container mt-4">
      <div class="grid">
        <div class="col-12">
          <DataTable
            :value="fincas"
            v-show="!cargaskeletonfincas"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
          >
            <template #header>
              <div class="table-header">
                Fincas
                <div class="p-inputgroup mr-4 ml-4">
                  <InputText
                    placeholder="Buscar x nombre de finca"
                    v-model="finca.filtro"
                    @keyup.enter="filtrarfincas"
                  />
                  <Button
                    icon="pi pi-search"
                    class="p-button-success"
                    @click="filtrarfincas"
                  />
                </div>
              </div>
            </template>

            <Column field="fin_nombre" header="Nombre"></Column>
            <Column
              field="ubicacionfinca.ub_detalle"
              header="Ubicacion"
            ></Column>
            <Column field="fin_hectareas" header="Hectareas"></Column>
            <Column>
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-primary mr-2"
                  @click="filtrarfinca(slotProps.data.fin_id)"
                /> </template
            ></Column>
          </DataTable>
        </div>
        <div class="col-12">
          <DataTable :value="skeletons" v-show="cargaskeletonfincas">
            <Column field="ske1" header="Finca">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske2" header="Fecha">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske3" header="Finca">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske4" header="">
              <template #body>
                <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible.sync="showmodalfinca"
      position="center"
      :modal="true"
      :containerStyle="{ width: '100vw' }"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ finca.titulomodal }} finca</h4>
      </template>
      <div class="field col-12">
        <div class="card">
          <div class="card-header">Datos básicos de la finca</div>
        </div>
      </div>

      <div class="p-fluid grid formgrid">
        <div class="field col-12 md:col-3">
          <label for="fin_ubicacion" v-show="!finca.nuevo">Ubicación</label>
          <Dropdown
            id="fin_ubicacion"
            v-model="finca.fin_ubicacion"
            :options="ubicacionesfincas"
            optionLabel="ub_detalle"
            placeholder="Seleccionar una ubicación"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_latitud" v-show="!finca.nuevo">Latitud</label>
          <InputText
            id="fin_latitud"
            placeholder="Latitud: 11.106014"
            v-model="finca.fin_latitud"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_longitud" v-show="!finca.nuevo">Longitud</label>
          <InputText
            id="fin_longitud"
            placeholder="Longitud: -74.1217839"
            v-model="finca.fin_longitud"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_hectareas" v-show="!finca.nuevo">Hectareas</label>
          <InputText
            id="fin_hectareas"
            placeholder="Hectareas: 100"
            v-model="finca.fin_hectareas"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_nombre" v-show="!finca.nuevo">Nombre</label>
          <InputText
            id="fin_nombre"
            placeholder="Nombre: Villa Luisa"
            v-model="finca.fin_nombre"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_actividadeconomica" v-show="!finca.nuevo"
            >Actividad principal</label
          >
          <Dropdown
            id="fin_actividadeconomica"
            v-model="finca.fin_actividadeconomica"
            :options="actividadeseconomicas"
            optionLabel="detalle"
            placeholder="Seleccionar una actividad"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_actividadeconomica" v-show="!finca.nuevo"
            >Enfoque económico</label
          >
          <Dropdown
            id="fin_categoriaobservadora"
            v-model="finca.fin_categoriaobservadora"
            :options="categoriasobservadoras"
            optionLabel="detalle"
            placeholder="Seleccionar una categoría"
          />
        </div>

        <Divider />

             <div class="field col-12">
        <div class="card">
          <div class="card-header">Datos de la oferta</div>
        </div>
      </div>

        <div class="field col-12 md:col-6">
          <label for="fin_otrosservicios" v-show="!finca.nuevo"
            >Otros servicios</label
          >
          <Chips
            v-model="finca.fin_otrosservicios"
            placeholder="Servicios (Venta panela, mermelada...)"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_senalizacion" v-show="!finca.nuevo"
            >Señalización</label
          >
          <InputText
            id="fin_senalizacion"
            placeholder="Señalización: Mapa predial"
            v-model="finca.fin_senalizacion"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_caphabitaciones" v-show="!finca.nuevo"
            >Capacidad Max Habitaciones</label
          >
          <InputText
            id="fin_caphabitaciones"
            placeholder="Cap. max habitaciones: 10"
            v-model="finca.fin_caphabitaciones"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_caphamacas" v-show="!finca.nuevo"
            >Capacidad Max Hamacas</label
          >
          <InputText
            id="fin_caphamacas"
            placeholder="Cap. max hamacas: 10"
            v-model="finca.fin_caphamacas"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_capcampings" v-show="!finca.nuevo">N° Campings</label>
          <InputText
            id="fin_capcampings"
            placeholder="N° de campings: 10"
            v-model="finca.fin_capcampings"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_capcampingsdispon" v-show="!finca.nuevo"
            >Capacidad Max Campings</label
          >
          <InputText
            id="fin_capcampingsdispon"
            placeholder="Cap. max campings: 10"
            v-model="finca.fin_capcampingsdispon"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_capcamas" v-show="!finca.nuevo"
            >Capacidad Max Camas</label
          >
          <InputText
            id="fin_capcamas"
            placeholder="Cap. max camas: 12"
            v-model="finca.fin_capcamas"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_capmesas" v-show="!finca.nuevo"
            >Capacidad Max Mesas</label
          >
          <InputText
            id="fin_capmesas"
            placeholder="Cap. max mesas: 21"
            v-model="finca.fin_capmesas"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_capsillas" v-show="!finca.nuevo"
            >Capacidad Max Silla</label
          >
          <InputText
            id="fin_capsillas"
            placeholder="Cap. max sillas: 45"
            v-model="finca.fin_capsillas"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="fin_numbanios" v-show="!finca.nuevo">N° de Baños</label>
          <InputText
            id="fin_numbanios"
            placeholder="N°. de baños: 9"
            v-model="finca.fin_numbanios"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label for="fin_capmesasinve" v-show="!finca.nuevo"
            >Cap. max. mesas (Investigación)</label
          >
          <InputText
            id="fin_capmesasinve"
            placeholder="Cap. max. mesas (Investigación)"
            v-model="finca.fin_capmesasinve"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_capsillasinve" v-show="!finca.nuevo"
            >Cap. max. sillas (Investigación)</label
          >
          <InputText
            id="fin_capsillasinve"
            placeholder="Cap. max. sillas (Investigación)"
            v-model="finca.fin_capsillasinve"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_comidaofertada" v-show="!finca.nuevo"
            >Tipos de comida ofertada</label
          >
          <Chips
            v-model="finca.fin_comidaofertada"
            placeholder="Comida ofertada (Vegetariana, carnes y asados) "
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_accesibilidad" v-show="!finca.nuevo"
            >Accesibilidad al sitio</label
          >
          <Chips
            v-model="finca.fin_accesibilidad"
            placeholder="Accesibilidad (Moto, Camioneta, Caminando)"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_numcursoscampo" v-show="!finca.nuevo"
            >N° cursos de campo</label
          >
          <InputText
            id="fin_numcursoscampo"
            placeholder="N° cursos de campo: 4"
            v-model="finca.fin_numcursoscampo"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_numpersonascursocampo" v-show="!finca.nuevo"
            >N° personas x curso</label
          >
          <InputText
            id="fin_numpersonascursocampo"
            placeholder="N° personas x curso: 6"
            v-model="finca.fin_numpersonascursocampo"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_numsalidascampo" v-show="!finca.nuevo"
            >N° salidas de campo</label
          >
          <InputText
            id="fin_numsalidascampo"
            placeholder="N° salidas de campo: 4"
            v-model="finca.fin_numsalidascampo"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="fin_numpersonassalidacampo" v-show="!finca.nuevo"
            >N° pers. x salida de campo</label
          >
          <InputText
            id="fin_numpersonassalidacampo"
            placeholder="N° pers. x salida de campo: 8"
            v-model="finca.fin_numpersonassalidacampo"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_numpersonasindividual" v-show="!finca.nuevo"
            >N° investigadores individual</label
          >
          <InputText
            id="fin_numpersonasindividual"
            placeholder="N° investigadores individual: 6"
            v-model="finca.fin_numpersonasindividual"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_porcentajeinv" v-show="!finca.nuevo"
            >% personas x actividades de Investigación</label
          >
          <InputText
            id="fin_porcentajeinv"
            placeholder="% personas x actividades de Investigación: 40%"
            v-model="finca.fin_porcentajeinv"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="fin_numvisitas" v-show="!finca.nuevo"
            >N° personas visitas mensualmente</label
          >
          <InputText
            id="fin_numvisitas"
            placeholder="N° personas visitas mensualmente: 67"
            v-model="finca.fin_numvisitas"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_lugaresguianza" v-show="!finca.nuevo"
            >Lugares de guianza</label
          >
          <MultiSelect
            id="fin_lugaresguianza"
            v-model="lugaresdeguianzaselected"
            :options="lugaresdeguianza"
            optionLabel="lg_detalle"
            placeholder="Seleccionar lugares de guianza"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_tiposdeturismo" v-show="!finca.nuevo"
            >Tipos de turismo</label
          >
          <MultiSelect
            id="fin_tiposdeturismo"
            v-model="tiposdeturismoselected"
            :options="tiposdeturismo"
            optionLabel="tptur_detalle"
            placeholder="Seleccionar tipos de turismo"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_serviciostecnologicos" v-show="!finca.nuevo"
            >Servicios tecnológicos</label
          >
          <MultiSelect
            id="fin_serviciostecnologicos"
            v-model="serviciostecnologicosselected"
            :options="serviciostecnologicos"
            optionLabel="ser_detalle"
            placeholder="Seleccionar tipos de turismo"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_aliados" v-show="!finca.nuevo"
            >Aliados estratégicos</label
          >
          <MultiSelect
            id="fin_aliados"
            v-model="aliadosestrategicosselected"
            :options="aliadosestrategicos"
            optionLabel="ali_detalle"
            placeholder="Seleccionar aliados estratégicos"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_actividadpaquete" v-show="!finca.nuevo"
            >Actividades o paquetes ya diseñados</label
          >
          <MultiSelect
            id="fin_actividadpaquete"
            v-model="actividadespaquetesselected"
            :options="actividadespaquetes"
            optionLabel="act_detalle"
            placeholder="Seleccionar actividades paquetes"
            display="chip"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="fin_medidaseguridad" v-show="!finca.nuevo"
            >Medidas de seguridad</label
          >
          <MultiSelect
            id="fin_medidaseguridad"
            v-model="medidasdeseguridadselected"
            :options="medidasdeseguridad"
            optionLabel="med_detalle"
            placeholder="Seleccionar medidas de seguridad"
            display="chip"
          />
        </div>

        <div class="field col-6">
          <label for="fin_proyectos" v-show="!finca.nuevo"
            >Participación en investigación o proyectos</label
          >
          <MultiSelect
            id="fin_proyectos"
            v-model="proyectosselected"
            :options="proyectos"
            optionLabel="proinv_detalle"
            placeholder="Seleccionar proyectos"
            display="chip"
          />
        </div>


        <div class="field col-6">
          <label for="fin_espaciossenderos" v-show="!finca.nuevo"
            >Espacios, senderos, recursos o atractivos turísticos</label
          >
          <Chips
            v-model="finca.fin_espaciossenderos"
            placeholder="Espacios, senderos, recursos o atractivos turísticos. (EL chorrito de la coca, Senderos indígenas...)"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label class="mr-2" for="fin_alojamiento">Alojamiento: </label>
          <InputSwitch id="fin_alojamiento" v-model="finca.fin_alojamiento" />
        </div>
        <div class="field col-12 md:col-3">
          <label class="mr-2" for="fin_alimentosbebidas"
            >Alimentos y bebidas:
          </label>
          <InputSwitch
            id="fin_alimentosbebidas"
            v-model="finca.fin_alimentosbebidas"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label class="mr-2" for="fin_guianza">Servicios de guianza: </label>
          <InputSwitch id="fin_guianza" v-model="finca.fin_guianza" />
        </div>
        <div class="field col-12 md:col-3">
          <label class="mr-2" for="fin_numerortn">Numero RNT: </label>
          <InputSwitch id="fin_numerortn" v-model="finca.fin_numerortn" />
        </div>

        <div class="field col-12 md:col-3">
          <label class="mr-2" for="fin_polizas">Póliza accidentes: </label>
          <InputSwitch id="fin_polizas" v-model="finca.fin_polizas" />
        </div>

        <div class="field col-12 md:col-3">
          <label class="mr-2" for="fin_botiquin">Botiquín: </label>
          <InputSwitch id="fin_botiquin" v-model="finca.fin_botiquin" />
        </div>

        <div class="field col-12 md:col-3">
          <label class="mr-2" for="fin_primerosaux">Primeros Auxilios: </label>
          <InputSwitch id="fin_primerosaux" v-model="finca.fin_primerosaux" />
        </div>

        <div class="field col-12 md:col-3">
          <label class="mr-2" for="fin_segurotodorg"
            >Seguro todo riesgo:
          </label>
          <InputSwitch id="fin_segurotodorg" v-model="finca.fin_segurotodorg" />
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="finca.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="finca.boton"
          icon="pi pi-check"
          autofocus
          @click="actualizaroferta"
        />
      </template>
    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";

export default {
  name: "Registrodef",
  data() {
    return {
      finca: {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,

        fin_actividadeconomica: null,
        fin_categoriaobservadora: null,

        fin_alojamiento: false,
        fin_alimentosbebidas: false,
        fin_guianza: false,
        fin_numerortn: false,

        fin_otrosservicios: [],
        fin_senalizacion: null,

        fin_caphabitaciones: null,
        fin_caphamacas: null,
        fin_capcampings: null,
        fin_capcampingsdispon: null,

        fin_capcamas: null,
        fin_capmesas: null,
        fin_capsillas: null,
        fin_numbanios: null,

        fin_capmesasinve: null,
        fin_capsillasinve: null,

        fin_comidaofertada: [],
        fin_accesibilidad: [],

        fin_numcursoscampo: null,
        fin_numpersonascursocampo: null,
        fin_numsalidascampo: null,
        fin_numpersonassalidacampo: null,

        fin_numpersonasindividual: null,
        fin_porcentajeinv: null,
        fin_numvisitas: null,

        fin_espaciossenderos: null,

        fin_polizas: false,
        fin_botiquin: false,
        fin_primerosaux: false,
        fin_segurotodorg: false,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      },

      skeletons: this.global.skeletons,
      cargaskeletonfincas: false,
      showmodalfinca: false,

      fincas: [],
      ubicacionesfincas: [],
      actividadeseconomicas: [],
      categoriasobservadoras: [],
      nivelesdeparticipacion: [],

      lugaresdeguianza: [],
      tiposdeturismo: [],
      actividadespaquetes: [],
      medidasdeseguridad: [],
      
      proyectos: [],
      serviciostecnologicos: [],
      aliadosestrategicos: [],

      lugaresdeguianzaselected: null,
      tiposdeturismoselected: null,
      actividadespaquetesselected: null,
      medidasdeseguridadselected: null,
      
      proyectosselected: null,
      serviciostecnologicosselected: null,
      aliadosestrategicosselected: null,
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    actualizaroferta: async function () {
      try {
        var mensaje = this.validarActualizarOferta;

        if (mensaje === "") {
          this.finca.boton = " Actualizando oferta... ";
          this.finca.wait = true;

          var parametros = {
            fin_id: this.finca.fin_id,
            fin_nombre: this.finca.fin_nombre,
            fin_latitud: this.finca.fin_latitud,
            fin_longitud: this.finca.fin_longitud,

            fin_hectareas: this.finca.fin_hectareas,
            fin_ubicacion: this.finca.fin_ubicacion.ub_id,

            fin_actividadeconomica: this.finca.fin_actividadeconomica.id,
            fin_categoriaobservadora: this.finca.fin_categoriaobservadora.id,

            fin_otrosservicios: this.finca.fin_otrosservicios.toString(),
            fin_senalizacion: this.finca.fin_senalizacion,
            fin_caphabitaciones: this.finca.fin_caphabitaciones,
            fin_caphamacas: this.finca.fin_caphamacas,
            fin_capcampings: this.finca.fin_capcampings,
            fin_capcampingsdispon: this.finca.fin_capcampingsdispon,
            fin_capcamas: this.finca.fin_capcamas,
            fin_capmesas: this.finca.fin_capmesas,
            fin_capsillas: this.finca.fin_capsillas,
            fin_numbanios: this.finca.fin_numbanios,
            fin_capmesasinve: this.finca.fin_capmesasinve,
            fin_capsillasinve: this.finca.fin_capsillasinve,
            fin_comidaofertada: this.finca.fin_comidaofertada.toString(),
            fin_accesibilidad: this.finca.fin_accesibilidad.toString(),
            fin_numcursoscampo: this.finca.fin_numcursoscampo,
            fin_numpersonascursocampo: this.finca.fin_numpersonascursocampo,
            fin_numsalidascampo: this.finca.fin_numsalidascampo,
            fin_numpersonassalidacampo: this.finca.fin_numpersonassalidacampo,
            fin_numpersonasindividual: this.finca.fin_numpersonasindividual,
            fin_porcentajeinv: this.finca.fin_porcentajeinv,
            fin_numvisitas: this.finca.fin_numvisitas,
            fin_espaciossenderos: this.finca.fin_espaciossenderos.toString(),
            fin_alojamiento: this.finca.fin_alojamiento,
            fin_alimentosbebidas: this.finca.fin_alimentosbebidas,
            fin_guianza: this.finca.fin_guianza,
            fin_numerortn: this.finca.fin_numerortn,
            fin_polizas: this.finca.fin_polizas,
            fin_botiquin: this.finca.fin_botiquin,
            fin_primerosaux: this.finca.fin_primerosaux,
            fin_segurotodorg: this.finca.fin_segurotodorg,

            lugaresdeguianzaselected: this.lugaresdeguianzaselected,
            tiposdeturismoselected: this.tiposdeturismoselected,
            actividadespaquetesselected: this.actividadespaquetesselected,
            medidasdeseguridadselected: this.medidasdeseguridadselected,
            
            proyectosselected: this.proyectosselected,
            serviciostecnologicosselected: this.serviciostecnologicosselected,
            aliadosestrategicosselected: this.aliadosestrategicosselected,
          };

          console.log(this.aliadosestrategicosselected);

          await this.axios
            .post("fincas.php?dato=updateoferta", JSON.stringify(parametros))
            .then((response) => {
              if (response.status === 200) {
                if (response.data.includes(this.global.transaccionOK)) {
                  this.mensajeOK(response.data);
                  this.limpiarCamposNuevoRegistro();
                  this.filtrarfincas();
                } else if (this.global.buscarapierror(response.data) !== "") {
                  let msj_user = this.global.buscarapierror(response.data);
                  this.mensajeWar(msj_user);
                } else {
                  this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                }
              } else {
                this.global.mensajeErr(this.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              this.finca.boton = " Guardar ";
              this.finca.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtrarfincas: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          filtro: this.finca.filtro,
        };

        await this.axios
          .post("fincas.php?dato=getfincasoferta", JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.fincas = response.data;
              if (self.fincas.length === 0) {
                self.mensajeWar("No se encontraron fincas");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    filtrarfinca: async function (id) {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          fin_id: id,
        };

        await this.axios
          .post(
            "fincas.php?dato=getfincaconparametros",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.listarmodalfinca(response.data[0]);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getubicaciones: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("fincas.php?dato=getubicacionesfincas")
          .then(function (response) {
            if (response.status === 200) {
              self.ubicacionesfincas = response.data;
            } else {
              self.mensajeErr("Error al cargar las fincas");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getlugaresdeguianza: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("lugaresdeguianza.php?dato=getlugaresguianzacombo")
          .then(function (response) {
            if (response.status === 200) {
              self.lugaresdeguianza = response.data;
            } else {
              self.mensajeErr("Error al cargar las getlugaresdeguianza");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    gettiposdeturismo: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("tiposdeturismo.php?dato=gettiposdeturismocombo")
          .then(function (response) {
            if (response.status === 200) {
              self.tiposdeturismo = response.data;
            } else {
              self.mensajeErr("Error al cargar las gettiposdeturismocombo");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getactividadespaquetes: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("actividadpaquete.php?dato=getactividadespaquetescombo")
          .then(function (response) {
            if (response.status === 200) {
              self.actividadespaquetes = response.data;
            } else {
              self.mensajeErr("Error al cargar las getactividadespaquetes");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getmedidasdeseguridad: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("medidasdeseguridad.php?dato=getmedidasdeseguridadcombo")
          .then(function (response) {
            if (response.status === 200) {
              self.medidasdeseguridad = response.data;
            } else {
              self.mensajeErr("Error al cargar las medidasdeseguridad");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

 

    getproyectos: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("proyectos.php?dato=getproyectoscombo")
          .then(function (response) {
            if (response.status === 200) {
              self.proyectos = response.data;
            } else {
              self.mensajeErr("Error al cargar los proyectos");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getserviciostecnologicos: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("serviciostecnologicos.php?dato=getserviciostecnologicoscombo")
          .then(function (response) {
            if (response.status === 200) {
              self.serviciostecnologicos = response.data;
            } else {
              self.mensajeErr("Error al cargar los serviciostecnologicos");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getaliadosestrategicos: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;
        await this.axios
          .post("aliadosestrategicos.php?dato=getaliadoscombo")
          .then(function (response) {
            if (response.status === 200) {
              self.aliadosestrategicos = response.data;
            } else {
              self.mensajeErr("Error al cargar los aliadosestrategicos");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getactividadeseconomicas: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "actividadeconomica",
          namefield_id: "acteco_id",
          namefield_name: "acteco_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.actividadeseconomicas = response.data;
              console.log("Actividades econom");
              console.log(self.actividadeseconomicas);
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getcategoriasobservadoras: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "categoriaobservadora",
          namefield_id: "cat_id",
          namefield_name: "cat_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.categoriasobservadoras = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getnivelesdeparticipacion: async function () {
      try {
        this.cargaskeletonfincas = true;
        let self = this;

        var parametros = {
          nametable: "nivel",
          namefield_id: "niv_id",
          namefield_name: "niv_detalle",
          filtro: "",
        };

        let linkrest = "parametrizacion.php?dato=getcomboparametros";

        await this.axios
          .post(linkrest, JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.nivelesdeparticipacion = response.data;
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonfincas = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.finca = {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,

        fin_actividadeconomica: 0,
        fin_categoriaobservadora: 0,

        fin_alojamiento: false,
        fin_alimentosbebidas: false,
        fin_guianza: false,
        fin_numerortn: false,

        fin_otrosservicios: [],
        fin_senalizacion: null,

        fin_caphabitaciones: null,
        fin_caphamacas: null,
        fin_capcampings: null,
        fin_capcampingsdispon: null,

        fin_capcamas: null,
        fin_capmesas: null,
        fin_capsillas: null,
        fin_numbanios: null,

        fin_capmesasinve: null,
        fin_capsillasinve: null,

        fin_comidaofertada: [],
        fin_accesibilidad: [],

        fin_numcursoscampo: null,
        fin_numpersonascursocampo: null,
        fin_numsalidascampo: null,
        fin_numpersonassalidacampo: null,

        fin_numpersonasindividual: null,
        fin_porcentajeinv: null,
        fin_numvisitas: null,

        fin_espaciossenderos: null,

        fin_polizas: false,
        fin_botiquin: false,
        fin_primerosaux: false,
        fin_segurotodorg: false,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      };

      this.showmodalfinca = false;
    },

    openmodalfinca: function () {
      this.finca = {
        fin_id: 0,
        fin_nombre: "",
        fin_latitud: null,
        fin_longitud: null,

        fin_hectareas: null,
        fin_ubicacion: null,

        fin_actividadeconomica: 0,
        fin_categoriaobservadora: 0,

        fin_alojamiento: false,
        fin_alimentosbebidas: false,
        fin_guianza: false,
        fin_numerortn: false,

        fin_otrosservicios: [],
        fin_senalizacion: null,

        fin_caphabitaciones: null,
        fin_caphamacas: null,
        fin_capcampings: null,
        fin_capcampingsdispon: null,

        fin_capcamas: null,
        fin_capmesas: null,
        fin_capsillas: null,
        fin_numbanios: null,

        fin_capmesasinve: null,
        fin_capsillasinve: null,

        fin_comidaofertada: [],
        fin_accesibilidad: [],

        fin_numcursoscampo: null,
        fin_numpersonascursocampo: null,
        fin_numsalidascampo: null,
        fin_numpersonassalidacampo: null,

        fin_numpersonasindividual: null,
        fin_porcentajeinv: null,
        fin_numvisitas: null,

        fin_espaciossenderos: null,

        fin_polizas: false,
        fin_botiquin: false,
        fin_primerosaux: false,
        fin_segurotodorg: false,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      };

      this.showmodalfinca = true;
    },

    listarmodalfinca: async function (data) {
      console.log(data);
      console.log(data.fin_nombre);
      var otrosservicios = [];
      var tiposdecomida = [];
      var accesibilidad = [];
      var espaciossenderos = [];

      if (data.fin_otrosservicios !== "" && data.fin_otrosservicios !== null) {
        otrosservicios = data.fin_otrosservicios.toString().split(",");
      }

      if (data.fin_comidaofertada !== "" && data.fin_comidaofertada !== null) {
        tiposdecomida = data.fin_comidaofertada.toString().split(",");
      }

      if (data.fin_accesibilidad !== "" && data.fin_accesibilidad !== null) {
        accesibilidad = data.fin_accesibilidad.toString().split(",");
      }


      if (
        data.fin_espaciossenderos !== "" &&
        data.fin_espaciossenderos !== null
      ) {
        espaciossenderos = data.fin_espaciossenderos.toString().split(",");
      }

      this.finca = {
        titulomodal: " Editar oferta ",
        boton: "Editar",
        accion: 1,
        nuevo: false,

        fin_id: data.fin_id,
        fin_nombre: data.fin_nombre,
        fin_latitud: data.fin_latitud,
        fin_longitud: data.fin_longitud,

        fin_hectareas: data.fin_hectareas,

        fin_alojamiento: data.fin_alojamiento,
        fin_alimentosbebidas: data.fin_alimentosbebidas,
        fin_guianza: data.fin_guianza,
        fin_numerortn: data.fin_numerortn,

        fin_otrosservicios: otrosservicios,
        fin_senalizacion: data.fin_senalizacion,

        fin_caphabitaciones: data.fin_caphabitaciones,
        fin_caphamacas: data.fin_caphamacas,
        fin_capcampings: data.fin_capcampings,
        fin_capcampingsdispon: data.fin_capcampingsdispon,

        fin_capcamas: data.fin_capcamas,
        fin_capmesas: data.fin_capmesas,
        fin_capsillas: data.fin_capsillas,
        fin_numbanios: data.fin_numbanios,

        fin_capmesasinve: data.fin_capmesasinve,
        fin_capsillasinve: data.fin_capsillasinve,

        fin_comidaofertada: tiposdecomida,
        fin_accesibilidad: accesibilidad,

        fin_numcursoscampo: data.fin_numcursoscampo,
        fin_numpersonascursocampo: data.fin_numpersonascursocampo,
        fin_numsalidascampo: data.fin_numsalidascampo,
        fin_numpersonassalidacampo: data.fin_numpersonassalidacampo,

        fin_numpersonasindividual: data.fin_numpersonasindividual,
        fin_porcentajeinv: data.fin_porcentajeinv,
        fin_numvisitas: data.fin_numvisitas,

        fin_espaciossenderos: espaciossenderos,

        fin_polizas: data.fin_polizas,
        fin_botiquin: data.fin_botiquin,
        fin_primerosaux: data.fin_primerosaux,
        fin_segurotodorg: data.fin_segurotodorg,

        viewlink: true,
        linkgoogle:
          "https://www.google.com/maps/@" +
          data.fin_latitud +
          "," +
          data.fin_longitud +
          ",15z",

        fin_ubicacion: this.ubicacionesfincas.find(
          (p) => p.ub_id === data.ubicacionfinca.ub_id
        ),

        fin_actividadeconomica: this.actividadeseconomicas.find(
          (p) => p.id === data.actividadeconomica.acteco_id
        ),

        fin_categoriaobservadora: this.categoriasobservadoras.find(
          (p) => p.id === data.categoriaobservadora.cat_id
        ),
      };

      this.aliadosestrategicosselected = data.aliados;

      this.lugaresdeguianzaselected = data.lugaresdeguianza;
      this.tiposdeturismoselected = data.tiposdeturismo;
      this.actividadespaquetesselected = data.actividadespaquetes;
      this.medidasdeseguridadselected = data.medidasdeseguridad;

      this.proyectosselected = data.proyectos;
      this.serviciostecnologicosselected = data.serviciostecnologicos;

      this.showmodalfinca = true;
    },
  },

  computed: {
    validarActualizarOferta() {
      var mensajeAlerta = "";
      if (this.finca.fin_ubicacion === null) {
        mensajeAlerta = " Debe seleccionar una ubicación ";
      } else if (
        this.finca.fin_nombre === "" ||
        this.finca.fin_nombre.length > 45
      ) {
        mensajeAlerta =
          "Debe digitar un nombre válido, no debe superar los 45 caracteres";
      } else if (
        this.finca.fin_latitud === null ||
        Number.isNaN(Number(this.finca.fin_latitud)) ||
        this.finca.fin_latitud > 180 ||
        this.finca.fin_latitud < -180 ||
        this.finca.fin_latitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Latitud válido ej: 11.144433 separando decimales con punto (.) ";
      } else if (
        this.finca.fin_longitud === null ||
        Number.isNaN(Number(this.finca.fin_longitud)) ||
        this.finca.fin_longitud > 180 ||
        this.finca.fin_longitud < -180 ||
        this.finca.fin_longitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Longitud válido ej: -74.118597 separando decimales con punto (.) ";
      } else if (
        this.finca.fin_hectareas === null ||
        !this.global.validarNumeroEntero(this.finca.fin_hectareas) ||
        this.finca.fin_hectareas < 0 ||
        this.finca.fin_hectareas > 10000 ||
        this.finca.fin_hectareas === ""
      ) {
        mensajeAlerta = "Debe digitar un N° de hectareas válido";
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    this.getubicaciones();
    this.getactividadeseconomicas();
    this.getcategoriasobservadoras();
    this.getnivelesdeparticipacion();

    this.getlugaresdeguianza();
    this.gettiposdeturismo();
    this.getactividadespaquetes();
    this.getmedidasdeseguridad();
    this.getproyectos();
    this.getserviciostecnologicos();
    this.getaliadosestrategicos();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
  background-color: aqua;
}

.uno {
  margin-right: 20px;
  flex-grow: 10;
}

.dos {
  margin-left: 20px;
  flex-grow: 5;
}

.tres {
  flex-grow: 5;
}

.imagenes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
}

.article {
  height: 100%;
  display: grid;
  place-content: center;
}

.img {
  width: 300px;
  height: 300px;
}
</style>
