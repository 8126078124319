<template>
  <div>
    <NavbarMain />
    <div class="container">

      <div class="card mt-4">
        <div class="flex flex-column card-container green-container">
          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_especies" target="_blank"
              >Especies: (Agregar, consutar y editar especies)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_biodiversidad" target="_blank"
              >Riqueza Natural: (Registrar, consutar y editar registros de biodiversidad)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_reforestacion" target="_blank"
              >La siembra: (Registrar, consutar y editar registros de reforestación)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_deforestacion" target="_blank"
              >La Tala/Quema: (Registrar, consutar y editar registros de deforestación)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_usuarios" target="_blank"
              >Módulo de Usuarios: (Gestión General de usuarios)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_parametrizacion" target="_blank"
              >Módulo de Parametrizaciones: (Gestión de la parametrización general)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_adminarchivos" target="_blank"
              >Módulo de carga de archivos: (Gestión de archivos generales)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_investigadores" target="_blank"
              >Prioridades: (El cliente)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_fincas" target="_blank"
              >La Finca: (Registrar, consutar y editar fincas)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_economico" target="_blank"
              >La Compra: (Registrar, consutar y editar información económica de las fincas)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_oferta" target="_blank"
              >El Rancho: (Registrar, consutar y editar información de la oferta de las fincas)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_agencias" target="_blank"
              >Agencias: (Registrar, consutar y editar agencias de viaje)</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_historicos" target="_blank"
              >Módulo de Consulta de históricos</b-link
            >
          </div>

          <div
            class="flex align-items-center justify-content-center h-4rem bg-white-500 font-bold text-white border-round m-2"
          >
            <b-link :href="vid_descargaarchivos" target="_blank"
              >Módulo de descarga de archivos</b-link
            >
          </div>
          
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "Instructivos",
  data() {
    return {
      vid_especies: "https://drive.google.com/file/d/1LrlBAGeQ0srbv9oUAA1b7yRwaIeDSR27/view?usp=sharing",
      vid_biodiversidad: "https://drive.google.com/file/d/1DpT9BzFalLTqPYK0dbjqFsmSJCqVAQJJ/view",
      vid_reforestacion: "",
      vid_deforestacion: "",
      vid_usuarios: "https://drive.google.com/file/d/1vl18ROmt0T_3ymr1H3bzv4mjCeyQh4wV/view?usp=sharing",
      vid_adminarchivos: "",
      vid_investigadores: "",
      vid_fincas: "https://drive.google.com/file/d/12mmyUdvVYS2S1EGMyjh_gJUnn5JqkQsk/view?usp=sharing",
      vid_economico: "https://drive.google.com/file/d/1a9huvETvuJVzMMdZ8boM-Z5n_2GEBfT2/view?usp=sharing",
      vid_oferta: "https://drive.google.com/file/d/1NCZVbU1K1tTW-ZdriodhlwUH4-_yVIpg/view?usp=sharing",
      vid_agencias: "",
      vid_parametrizacion: "https://drive.google.com/file/d/1-r-TB1oocx3kIJxtHE681BkEXF9yfIG3/view?usp=sharing",
      vid_historicos: "",
      vid_descargaarchivos: ""

    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },
  },

  computed: {},

  created: function () {
    this.mensajeOK("Bienvenido: " + localStorage.getItem("name"));
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    } else {
      this.mensajeOK("Bienvenido: " + localStorage.getItem("name"));
    }
  },
};
</script>
