<template>
  <div>
    <NavbarMain />
    <div class="ppal">
      <div class="child uno ml-4">
        <div class="grid">
          <div class="col-12">
            <div class="unointo">
              <b-button-group vertical>
                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="onObjectSelect(1, false)"
                >
                  <b-icon class="mr-1 mb-1" icon="github"></b-icon>Cat.
                  Especies</b-button
                >
                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="onObjectSelect(2, false)"
                >
                  <b-icon class="mr-1 mb-1" icon="tree-fill"></b-icon>Cobertura
                  Ref</b-button
                >
                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="onObjectSelect(3, false)"
                >
                  <b-icon class="mr-1 mb-1" icon="bug-fill"></b-icon>Cobertura
                  Bio</b-button
                >
                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="onObjectSelect(4, false)"
                >
                  <b-icon class="mr-1 mb-1" icon="signpost2-fill"></b-icon
                  >Ubicaciones finca</b-button
                >
                <b-button
                  class="mr-1 mb-1"
                  variant="success"
                  @click="onObjectSelect(5, false)"
                >
                  <b-icon class="mr-1 mb-1" icon="egg"></b-icon>Productos</b-button
                >
              </b-button-group>
            </div>
              <div class="unointo">
                  <b-button-group vertical>
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(6, true)"
                    >
                      <b-icon class="mr-1 mb-1" icon="droplet-half"></b-icon>T.
                      Acueductos</b-button
                    >
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(7, true)"
                    >
                      <b-icon class="mr-1 mb-1" icon="plug"></b-icon>T.
                      Energia</b-button
                    >
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(8, true)"
                    >
                      <b-icon class="mr-1 mb-1" icon="bezier2"></b-icon>T.
                      Alcantarillados</b-button
                    >
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(9, true)"
                    >
                      <b-icon class="mr-1 mb-1" icon="egg-fried"></b-icon>T.
                      Estufas</b-button
                    >
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(10, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="rss-fill"></b-icon>S.
                      Tecnológicos</b-button
                    >
                  </b-button-group>

              </div>

              <div class="unointo">
                  <b-button-group vertical>
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(11, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="shield-shaded"></b-icon>
                      Autoridades</b-button
                    >
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(12, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="hand-thumbs-up-fill"></b-icon
                      >Aliados</b-button
                    >
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(13, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="wallet-fill"></b-icon>
                      Actores territoriales
                    </b-button>
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(14, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="cash-coin"></b-icon>Act.
                      Principales
                    </b-button>
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(15, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="building"></b-icon>S. Bienes y
                      serv.</b-button
                    >
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(16, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="megaphone-fill"></b-icon>S.
                      Med. Comercio</b-button
                    >
                  </b-button-group>

              </div>
  
              <div class="unointo">
                  <b-button-group vertical>
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(17, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="camera"></b-icon>T.
                      Turismo</b-button
                    >
                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(18, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="binoculars-fill"></b-icon>Lug.
                      Guianza</b-button
                    >

                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(19, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="bag-fill"></b-icon>Act. Paquete
                    </b-button>

                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(20, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="lock-fill"></b-icon>Med.
                      Seguridad</b-button
                    >

                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(21, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="exclamation-triangle"></b-icon
                      >T. Riesgos</b-button
                    >

                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(22, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="award-fill"></b-icon>Niv.
                      Educativos</b-button
                    >

                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(23, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="diagram2"></b-icon>Actividades fincas</b-button
                    >

                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(24, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="eye"></b-icon>Enf. Económicos</b-button
                    >

                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(25, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="eye"></b-icon>Proy. Investigación</b-button
                    >

                    <b-button
                      class="mr-1 mb-1"
                      variant="success"
                      @click="onObjectSelect(26, false)"
                    >
                      <b-icon class="mr-1 mb-1" icon="eye"></b-icon>Proyectos Futuros</b-button
                    >

                  </b-button-group>

              </div>

          </div>
        </div>
      </div>

      <div class="child dos">
        <div class="grid">
          <div class="col-12">
            <DataTable
              :value="arreglogenerico"
              v-show="!cargaskeletonarreglogenerico && !objeto.alternativo"
              responsiveLayout="scroll"
              :paginator="true"
              :rows="10"
            >
              <template #header>
                <div class="table-header">
                  {{ objeto.titulo }}
                  <div class="p-inputgroup mr-4 ml-4">
                    <InputText
                      placeholder="Buscar x nombre"
                      v-model="objeto.filtro"
                      @keyup.enter="filtrarregistrosparam(objeto.alternativo)"
                    />
                    <Button
                      icon="pi pi-search"
                      class="p-button-success"
                      @click="filtrarregistrosparam(objeto.alternativo)"
                    />
                  </div>

                  <Button
                    icon="pi pi-plus"
                    @click="limpiarCamposNuevoRegistro()"
                  />
                </div>
              </template>

              <Column field="detalle" header="Nombre"></Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-primary mr-2"
                    @click="listarmodalobjeto(slotProps.data)"
                  /> </template
              ></Column>
            </DataTable>

            <DataTable
              :value="arreglogenerico"
              v-show="!cargaskeletonarreglogenerico && objeto.alternativo"
              responsiveLayout="scroll"
              :paginator="true"
              :rows="10"
            >
              <template #header>
                <div class="table-header">
                  {{ objeto.titulo }}
                  <div class="p-inputgroup mr-4 ml-4">
                    <InputText
                      placeholder="Buscar x nombre"
                      v-model="objeto.filtro"
                      @keyup.enter="filtrarregistrosparam(objeto.alternativo)"
                    />
                    <Button
                      icon="pi pi-search"
                      class="p-button-success"
                      @click="filtrarregistrosparam(objeto.alternativo)"
                    />
                  </div>

                  <Button
                    icon="pi pi-plus"
                    @click="limpiarCamposNuevoRegistro()"
                  />
                </div>
              </template>

              <Column field="detalle" header="Nombre"></Column>
              <Column>
                <template #body="slotProps">
                  <InputSwitch
                    class="ml-2"
                    v-model="slotProps.data.bool"
                    :disabled="true"
                  /> </template
              ></Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-primary mr-2"
                    @click="listarmodalobjeto(slotProps.data)"
                  /> </template
              ></Column>
            </DataTable>
          </div>
          <div class="col-12">
            <DataTable :value="skeletons" v-show="cargaskeletonarreglogenerico">
              <Column field="ske1" header="Nombre">
                <template #body>
                  <Skeleton></Skeleton>
                </template>
              </Column>
              <Column field="ske4" header="">
                <template #body>
                  <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>

      <div class="child tres">
        <div class="grid">
          <div class="col-12">
            <div class="grid">
              <div class="col-12">
                <div class="p-inputgroup">
                  <label>{{ objeto.titulomodal }}</label>
                </div>
              </div>
            </div>

            <div class="grid">
              <div class="col-12">
                <div class="p-inputgroup">
                  <InputText
                    :placeholder="objeto.titulo"
                    v-model="objeto.detalle"
                  />
                </div>
              </div>
            </div>

            <div class="grid" v-show="objeto.alternativo">
              <div class="col-12">
                <div class="p-inputgroup">
                  <label> ¿Es alternativo? </label>
                  <InputSwitch class="ml-2" v-model="objeto.bool" />
                </div>
              </div>
            </div>

            <div id="finaldiv" class="grid">
              <div class="col-12">
                <div class="p-inputgroup">
                  <ProgressSpinner
                    class="mr-2"
                    v-show="objeto.wait"
                    style="width: 40px; height: 40px"
                    strokeWidth="8"
                    fill="#FFFFFF"
                    animationDuration=".5s"
                  />
                  <Button
                    :label="objeto.boton"
                    icon="pi pi-check"
                    autofocus
                    @click="registrarnuevoparam"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";

export default {
  name: "Especies",
  data() {
    return {
      listObjec: [
        {
          code: 1,
          nametable: "cat_especie",
          namefield_id: "catesp_id",
          namefield_name: "catesp_nombre",
          namefield_detail: "Categoria de especie",
        },
        {
          code: 2,
          nametable: "coberturareforestacion",
          namefield_id: "cobref_id",
          namefield_name: "cobref_detalle",
          namefield_detail: "Cobertura Reforestación",
        },
        {
          code: 3,
          nametable: "coberturaregistrobio",
          namefield_id: "cob_id",
          namefield_name: "cob_detalle",
          namefield_detail: "Categoria Biodiversidad",
        },
        {
          code: 4,
          nametable: "ubicacionfinca",
          namefield_id: "ub_id",
          namefield_name: "ub_detalle",
          namefield_detail: "Ubicacion finca",
        },
        {
          code: 5,
          nametable: "producto",
          namefield_id: "pro_id",
          namefield_name: "pro_detalle",
          namefield_detail: "Productos",
        },

        {
          code: 6,
          nametable: "tipoacueducto",
          namefield_id: "tpac_id",
          namefield_name: "tpac_detalle",
          namefield_bool: "tpac_alternativa",
          namefield_detail: "Tipo acueducto",
        },

        {
          code: 7,
          nametable: "tipoenergia",
          namefield_id: "tpen_id",
          namefield_name: "tpen_detalle",
          namefield_bool: "tpen_renovable",
          namefield_detail: "Tipo de energía",
        },

        {
          code: 8,
          nametable: "tipoalcantarillado",
          namefield_id: "tpal_id",
          namefield_name: "tpal_detalle",
          namefield_bool: "tpal_aternativo",
          namefield_detail: "Tipo alcantarillado",
        },

        {
          code: 9,
          nametable: "tipoestufa",
          namefield_id: "tpes_id",
          namefield_name: "tpes_detalle",
          namefield_bool: "tpes_alternativo",
          namefield_detail: "Tipo estufas",
        },

        {
          code: 10,
          nametable: "serviciostecnologicos",
          namefield_id: "ser_id",
          namefield_name: "ser_detalle",
          namefield_detail: "Servicio tecnológico",
        },

        {
          code: 11,
          nametable: "autoridades",
          namefield_id: "aut_id",
          namefield_name: "aut_nombre",
          namefield_detail: "Autoridad",
        },
        {
          code: 12,
          nametable: "aliados",
          namefield_id: "ali_id",
          namefield_name: "ali_detalle",
          namefield_detail: "Aliados",
        },
        {
          code: 13,
          nametable: "entidadproyecto",
          namefield_id: "entpro_id",
          namefield_name: "entpro_detalle",
          namefield_detail: "Entidad proyecto",
        },
        {
          code: 14,
          nametable: "actividadeconomica",
          namefield_id: "acteco_id",
          namefield_name: "acteco_detalle",
          namefield_detail: "Actividad económica",
        },
        {
          code: 15,
          nametable: "bienservicio",
          namefield_id: "bs_id",
          namefield_name: "bs_detalle",
          namefield_detail: "Bien/Servicio",
        },
        {
          code: 16,
          nametable: "mediocomercio",
          namefield_id: "medcor_id",
          namefield_name: "medcor_detalle",
          namefield_detail: "Medio de comercialización",
        },
        {
          code: 17,
          nametable: "tipoturismo",
          namefield_id: "tptur_id",
          namefield_name: "tptur_detalle",
          namefield_detail: "Tipo de turismo",
        },
        {
          code: 18,
          nametable: "lugaresguianza",
          namefield_id: "lg_id",
          namefield_name: "lg_detalle",
          namefield_detail: "Lugar guianza",
        },
        {
          code: 19,
          nametable: "actividadpaquete",
          namefield_id: "act_id",
          namefield_name: "act_detalle",
          namefield_detail: "Act. paquete",
        },
        {
          code: 20,
          nametable: "medidaseguridad",
          namefield_id: "med_id",
          namefield_name: "med_detalle",
          namefield_detail: "Medida de seguridad",
        },
        {
          code: 21,
          nametable: "tiporiesgo",
          namefield_id: "tprg_id",
          namefield_name: "tprg_detalle",
          namefield_detail: "Tipo riesgo",
        },
        {
          code: 22,
          nametable: "niveleducativo",
          namefield_id: "nived_id",
          namefield_name: "nived_detalle",
          namefield_detail: "Nivel educativo",
        },
        {
          code: 23,
          nametable: "objetoprincipal",
          namefield_id: "obpal_id",
          namefield_name: "obpal_detalle",
          namefield_detail: "Objeto principal",
        },
        {
          code: 24,
          nametable: "categoriaobservadora",
          namefield_id: "cat_id",
          namefield_name: "cat_detalle",
          namefield_detail: "Categoria observadora",
        },
        {
          code: 25,
          nametable: "proyectosinvestigacion",
          namefield_id: "proinv_id",
          namefield_name: "proinv_detalle",
          namefield_detail: "Proyectos Investigación",
        },
        {
          code: 26,
          nametable: "proyectosfuturos",
          namefield_id: "profut_id",
          namefield_name: "profut_detalle",
          namefield_detail: "Proyectos a futuro",
        },
      ],
      objeto: {
        id: 0,
        detalle: "",
        bool: false,
        alternativo: false,

        filtro: "",
        titulo: "",
        titulomodal: "Sin parámetro seleccionado",
        nuevo: true,
        wait: false,
        boton: "Guardar",
      },

      skeletons: this.global.skeletons,

      genericparams: {
        nametable: "",
        namefield_id: "",
        namefield_name: "",
        namefield_bool: false,
      },

      filtrarobjeto: "",
      arreglogenerico: [],
      cargaskeletonarreglogenerico: false,
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.objeto = {
        id: 0,
        detalle: "",
        bool: false,
        alternativo: this.objeto.alternativo,

        filtro: "",
        titulo: this.genericparams.namefield_detail,
        titulomodal: "Nuevo " + this.genericparams.namefield_detail,

        boton: "Guardar",
        accion: 0,
        nuevo: true,
      };

      this.filtrarobjeto = "";
    },

    listarmodalobjeto: async function (data) {
      if (this.objeto.alternativo) {
        this.objeto = {
          titulomodal: " Editar " + this.genericparams.namefield_detail,
          titulo: this.genericparams.namefield_detail,
          boton: "Editar",
          accion: 1,
          nuevo: false,

          id: data.id,
          detalle: data.detalle,
          bool: data.bool,
          alternativo: true,
        };
      } else {
        this.objeto = {
          titulomodal: " Editar " + this.genericparams.namefield_detail,
          titulo: this.genericparams.namefield_detail,
          boton: "Editar",
          accion: 1,
          nuevo: false,

          id: data.id,
          detalle: data.detalle,
          alternativo: false,
        };
      }
    },

    filtrarregistrosparam: async function (bool) {
      try {
        this.cargaskeletonarreglogenerico = true;
        let self = this;

        if (this.genericparams.nametable !== "") {
          var parametros = {
            nametable: this.genericparams.nametable,
            namefield_id: this.genericparams.namefield_id,
            namefield_name: this.genericparams.namefield_name,
            namefield_bool: this.genericparams.namefield_bool,
            filtro: this.objeto.filtro,
          };

          let linkrest = "parametrizacion.php?dato=getparametros";

          if (bool) {
            linkrest = "parametrizacion.php?dato=getparametrosalternativos";
          }

          await this.axios
            .post(linkrest, JSON.stringify(parametros))
            .then(function (response) {
              if (response.status === 200) {
                self.arreglogenerico = response.data;
                if (self.arreglogenerico.length === 0) {
                  self.mensajeWar("No se encontraron registros de la busqueda");
                }
              }
            })
            .catch((error) => {
              this.global.catching(error);
            })
            .finally(() => {
              self.cargaskeletonarreglogenerico = false;
            });
        } else {
          this.mensajeWar("Debe seleccionar un parámetro a consultar");
        }
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    registrarnuevoparam: async function () {
      try {
        if (this.genericparams.nametable !== "") {
          if (this.objeto.detalle !== "" && this.objeto.detalle.length < 75) {
            let self = this;

            this.objeto.boton = " Guardando... ";
            this.objeto.wait = true;

            var parametros = {
              nametable: this.genericparams.nametable,
              namefield_id: this.genericparams.namefield_id,
              namefield_name: this.genericparams.namefield_name,
              namefield_bool: this.genericparams.namefield_bool,

              nombre: this.objeto.detalle,
              id: this.objeto.id,
              bool: this.objeto.bool,
              nuevo: this.objeto.nuevo,
            };

            let linkrest = "parametrizacion.php?dato=nuevoparametro";

            if (this.objeto.alternativo) {
              linkrest = "parametrizacion.php?dato=nuevoparametroalternativo";
            }

            await this.axios
              .post(linkrest, JSON.stringify(parametros))
              .then(function (response) {
                if (response.status === 200) {
                  if (response.data.includes(self.global.transaccionOK)) {
                    self.mensajeOK(response.data);
                    self.limpiarCamposNuevoRegistro();
                    self.filtrarregistrosparam(self.objeto.alternativo);
                  } else if (self.global.buscarapierror(response.data) !== "") {
                    let msj_user = self.global.buscarapierror(response.data);
                    self.mensajeWar(msj_user);
                  } else {
                    self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                  }
                } else {
                  self.global.mensajeErr(self.global.respuestaInvalida);
                }
              })
              .catch((error) => {
                self.global.catching(error);
              })
              .finally(() => {
                self.objeto.boton = "Guardar";
                self.objeto.wait = false;
              });
          } else {
            this.mensajeWar("Digite un detalle válido");
          }
        } else {
          this.mensajeWar("Debe seleccionar un parámetro a gestionar");
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    onObjectSelect(val, bool) {
      //alert("CHANGE: " + val);
      var obj = this.listObjec.find((o) => o.code === val);
      //alert(obj.nametable);

      this.genericparams = {
        nametable: obj.nametable,
        namefield_id: obj.namefield_id,
        namefield_name: obj.namefield_name,
        namefield_detail: obj.namefield_detail,
      };

      this.objeto = {
        id: 0,
        detalle: "",
        alternativo: false,
        bool: this.objeto.bool,

        filtro: "",
        titulo: "",
        titulomodal: "Nuevo " + this.genericparams.namefield_detail,
        nuevo: true,
        wait: false,
        boton: "Guardar",
      };

      if (bool) {
        this.genericparams = {
          nametable: obj.nametable,
          namefield_id: obj.namefield_id,
          namefield_name: obj.namefield_name,
          namefield_detail: obj.namefield_detail,
          namefield_bool: obj.namefield_bool,
        };

        this.objeto = {
          id: 0,
          detalle: "",
          alternativo: true,
          bool: this.objeto.bool,

          filtro: "",
          titulo: "",
          titulomodal: "Nuevo " + this.genericparams.namefield_detail,
          nuevo: true,
          wait: false,
          boton: "Guardar",
        };
      }


      this.filtrarregistrosparam(bool);
      this.scrolldiv();
      
    },

    scrolldiv: function(){
      var div = document.getElementById('finaldiv');
      div.scrollTop = '9999';
    }
  },

  computed: {},

  created: function () {},

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
}

.uno {
  margin-right: 20px;
  flex-grow: 3;
}

.unointo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
}

.dos {
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 12;
}

.tres {
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 6;
}

.article {
  height: 100%;
  display: grid;
  place-content: center;
}
</style>
