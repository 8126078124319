<template>
  <div>
    <NavbarMain />
    <div class="container"></div>

    <Dialog
      header="Header"
      :visible.sync="showmodalusuario"
      position="center"
      :modal="true"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>Cambio de clave</h4>
      </template>

      <div class="p-fluid grid formgrid">
        <div class="field col-12">
          <label for="usu_clave">Nueva Clave</label>
          <Password
            id="usu_clave"
            class="m-4"
            v-model="nuevaclave"
            placeholder="Clave"
            :feedback="false"
          />
        </div>

        <div class="field col-12">
          <label for="usu_reeclave">Re Nueva Clave</label>
          <Password
            id="usu_reeclave"
            class="m-4"
            v-model="renuevaclave"
            placeholder="Re Clave"
            :feedback="false"
          />
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="boton"
          icon="pi pi-check"
          autofocus
          @click="cambiarclaveusuario"
        />
      </template>
    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "CambioClave",
  data() {
    return {
      showmodalusuario: true,
      nuevaclave: "",
      renuevaclave: "",
      wait: false,
      boton: "Cambiar Clave"
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    cambiarclaveusuario: async function () {
      try {
        var mensaje = this.validarCambiarClaveUsuario;

        if (mensaje === "") {
          this.wait = true;
          let self = this;

          this.boton = " Cambiando clave... ";

          var parametros = {
            alias: localStorage.getItem("alias"),
            clave: this.nuevaclave,
          };

          await this.axios
            .post(
              "usuarios.php?dato=cambiarclaveusuario",
              JSON.stringify(parametros)
            )
            .then(function (response) {
              if (response.status === 200) {
                if (response.data.includes(self.global.transaccionOK)) {
                  self.mensajeOK(response.data);
                  self.showmodalusuario = false;
                } else if (self.global.buscarapierror(response.data) !== "") {
                  let msj_user = self.global.buscarapierror(response.data);
                  self.mensajeWar(msj_user);
                } else {
                  self.mensajeErr(self.global.respuestaValidaSinTransaccion);
                }
              } else {
                self.global.mensajeErr(self.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              self.global.catching(error);
            })
            .finally(() => {
              self.wait = false;
              self.boton = " Cambiar clave ";
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log("ERROR JS: " + e);
      }
    },

  },

  computed: {
    validarCambiarClaveUsuario() {
      var mensajeAlerta = "";

      if (this.nuevaclave !== this.renuevaclave || this.nuevaclave.length < 6) {
        mensajeAlerta =
          "Las claves digitadas deben coincidir y no pueden tener menos de 6 caractéres";
      }
      return mensajeAlerta;
    },
  },

  created: function () {
    this.showmodalusuario = true;
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>
