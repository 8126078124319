<template>
  <div>
    <NavbarMain />

    <div class="container mt-4">
      <div class="grid">
        <div class="col-12">
          <DataTable
            :value="agencias"
            v-show="!cargaskeletonagencias"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
          >
            <template #header>
              <div class="table-header">
                Agencias
                <div class="p-inputgroup mr-4 ml-4">
                  <InputText
                    placeholder="Buscar x nombre de agencia"
                    v-model="agencia.filtro"
                    @keyup.enter="filtraragencias"
                  />
                  <Button
                    icon="pi pi-search"
                    class="p-button-success"
                    @click="filtraragencias"
                  />
                </div>

                <Button icon="pi pi-plus" @click="openmodalagencia()" />
              </div>
            </template>

            <Column field="age_nombre" header="Nombre"></Column>
            <Column field="age_ubicacion" header="Ubicacion"></Column>
            <Column field="age_telefono" header="Teléfono"></Column>
            <Column>
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-primary mr-2"
                  @click="listarmodalagencia(slotProps.data)"
                /> </template
            ></Column>
          </DataTable>
        </div>
        <div class="col-12">
          <DataTable :value="skeletons" v-show="cargaskeletonagencias">
            <Column field="ske1" header="Agencia">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske2" header="Ubicación">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske3" header="Teléfono">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske4" header="">
              <template #body>
                <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible.sync="showmodalagencia"
      position="center"
      :modal="true"
      :containerStyle="{ width: '100vw' }"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ agencia.titulomodal }} agencia</h4>
      </template>

      <div class="p-fluid grid formgrid">
        <div class="field col-12 md:col-3">
          <label for="age_ubicacion" v-show="!agencia.nuevo">Ubicación</label>
          <InputText
            id="age_ubicacion"
            placeholder="Digite ubicación"
            v-model="agencia.age_ubicacion"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="age_latitud" v-show="!agencia.nuevo">Latitud</label>
          <InputText
            id="age_latitud"
            placeholder="Latitud: 11.106014"
            v-model="agencia.age_latitud"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="age_longitud" v-show="!agencia.nuevo">Longitud</label>
          <InputText
            id="age_longitud"
            placeholder="Longitud: -74.1217839"
            v-model="agencia.age_longitud"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label for="age_telefono" v-show="!agencia.nuevo">Teléfono</label>
          <InputText
            id="age_telefono"
            placeholder="Teléfono: 304 4501023"
            v-model="agencia.age_telefono"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_nombre" v-show="!agencia.nuevo">Nombre</label>
          <InputText
            id="age_nombre"
            placeholder="Nombre: Moto Mink"
            v-model="agencia.age_nombre"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="age_camaracomercio" v-show="!agencia.nuevo"
            >Cámara de Comercio</label
          >

          <Dropdown
            v-model="agencia.age_tipopersona"
            :options="tipopersonas"
            optionLabel="tpper_detalle"
            placeholder="Seleccionar un tipo"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="age_rnt" v-show="!agencia.nuevo">N° de RNT</label>
          <InputText
            id="age_rnt"
            placeholder="N° RNT"
            v-model="agencia.age_rnt"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_categoriaagencia" v-show="!agencia.nuevo"
            >Categoría Observada RNT</label
          >
          <Dropdown
            v-model="agencia.age_categoriaagencia"
            :options="categoriasagencias"
            optionLabel="cat_detalle"
            placeholder="Seleccionar una categoria"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_subcategoriaagencia" v-show="!agencia.nuevo"
            >Subcategoría Observada</label
          >
          <Dropdown
            v-model="agencia.age_subcategoriaagencia"
            :options="subcategoriasagencias"
            optionLabel="subcat_detalle"
            placeholder="Seleccionar una Subcategoria"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_vehiculos" v-show="!agencia.nuevo"
            >Tipo de vehículos</label
          >
          <Chips
            v-model="agencia.age_vehiculos"
            placeholder="Vehiculos (Moto, microbus...)"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="age_numvehiculos" v-show="!agencia.nuevo">N° de Vehículos</label>
          <InputText
            id="age_numvehiculos"
            placeholder="N° Vehículos"
            v-model="agencia.age_numvehiculos"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="age_capacidadvehicular" v-show="!agencia.nuevo">Capacidad vehicular</label>
          <InputText
            id="age_capacidadvehicular"
            placeholder="Capacidad vehicular"
            v-model="agencia.age_capacidadvehicular"
          />
        </div>

         <div class="field col-12">
          <label for="age_tiposturismorecorridos" v-show="!agencia.nuevo"
            >Tipos de turismo</label
          >
          <Chips
            v-model="agencia.age_tiposturismorecorridos"
            placeholder="Tipos de turismo: (Aventura, De naturaleza...)"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_serviciosmassolicitados" v-show="!agencia.nuevo"
            >Servicios mas solicitados</label
          >
          <Chips
            v-model="agencia.age_serviciosmassolicitados"
            placeholder="Serv. mas solicitados (Sierra Minca, transporte al mercado...)"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="age_guia" v-show="!agencia.nuevo">Guia</label>
          <InputText
            id="age_guia"
            placeholder="Guia"
            v-model="agencia.age_guia"
          />
        </div>

        <div class="field col-12 md:col-3">
          <label for="age_promtarifas" v-show="!agencia.nuevo">Valor tarifas aprox.</label>
          <InputText
            id="age_promtarifas"
            placeholder="Valor tarifas aprox."
            v-model="agencia.age_promtarifas"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_medidas_seguridad" v-show="!agencia.nuevo"
            >Medidas de seguridad</label
          >
          <Chips
            v-model="agencia.age_medidas_seguridad"
            placeholder="Med. de Seguridad: (Seguro vehícular, otros...)"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_serviciostecnologicos" v-show="!agencia.nuevo"
            >Servicios tecnológicos</label
          >
          <Chips
            v-model="agencia.age_serviciostecnologicos"
            placeholder="Tecnológicos: (Wifi, celular, TDT, otros...)"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_autoridades" v-show="!agencia.nuevo"
            >Autoridades</label
          >
          <Chips
            v-model="agencia.age_autoridades"
            placeholder="Autoridades: (Corpamag, Gobernación...)"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="age_aliados" v-show="!agencia.nuevo"
            >Aliados estratégicos</label
          >
          <Chips
            v-model="agencia.age_aliados"
            placeholder="Aliados: (Expreso Brasilia, Sierra Minca...)"
          />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_camaracomercio">C. de comercio </label>
          <InputSwitch id="age_camaracomercio" v-model="agencia.age_camaracomercio" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_ofreservturisticos">Serv. turísticos </label>
          <InputSwitch id="age_ofreservturisticos" v-model="agencia.age_ofreservturisticos" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_medseguridad">Med. de Seguridad </label>
          <InputSwitch id="age_medseguridad" v-model="agencia.age_medseguridad" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_estacionatencion">Est. de Atención </label>
          <InputSwitch id="age_estacionatencion" v-model="agencia.age_estacionatencion" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_bioseguridad">Med. de Bioseg </label>
          <InputSwitch id="age_bioseguridad" v-model="agencia.age_bioseguridad" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_lavadomanos">Lav. de Manos </label>
          <InputSwitch id="age_lavadomanos" v-model="agencia.age_lavadomanos" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_polizas">Pólizas </label>
          <InputSwitch id="age_polizas" v-model="agencia.age_polizas" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_botiquin">Botiquín </label>
          <InputSwitch id="age_botiquin" v-model="agencia.age_botiquin" />
        </div>


        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_primaux">P. Auxilios </label>
          <InputSwitch id="age_primaux" v-model="agencia.age_primaux" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_seguro">S. todoriesgo </label>
          <InputSwitch id="age_seguro" v-model="agencia.age_seguro" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_seguridadprivada">Seg. privada </label>
          <InputSwitch id="age_seguridadprivada" v-model="agencia.age_seguridadprivada" />
        </div>

        <div class="field col-12 md:col-2">
          <label class="mr-2" for="age_disfribilador">Desfibrilador </label>
          <InputSwitch id="age_disfribilador" v-model="agencia.age_disfribilador" />
        </div>

         <div class="field col-12 md:col-3">
          <label class="mr-2" for="age_registroestadistico">Registro estadístico </label>
          <InputSwitch id="age_registroestadistico" v-model="agencia.age_registroestadistico" />
        </div>


        <div class="field col-12 md:col-3">
          <label class="mr-2" for="age_distanciamiento">Demarcación distanciamiento </label>
          <InputSwitch id="age_distanciamiento" v-model="agencia.age_distanciamiento" />
        </div>

        <div class="field col-12 md:col-3">
          <label class="mr-2" for="age_aforos">Aforos </label>
          <InputSwitch id="age_aforos" v-model="agencia.age_aforos" />
        </div>

        <div class="grid" v-show="agencia.viewlink">
          <div class="col-12">
            <div class="p-inputgroup">
              <b-link :href="agencia.linkgoogle" target="_blank"
                >Ver Mapa</b-link
              >
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="agencia.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="agencia.boton"
          icon="pi pi-check"
          autofocus
          @click="registrarnuevaagencia"
        />
      </template>
    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";

export default {
  name: "Agencias",
  data() {
    return {
        //mapa: https://www.openstreetmap.org/note/new?lat=11.09420300&lon=-74.11389000#map=17/11.09420300/-74.11389000&layers=N
      agencia: {
        age_id: 0,
        age_nombre: "",

        age_ubicacion: null,
        age_latitud: null,
        age_longitud: null,
        age_telefono: null,
        age_camaracomercio: false,
        age_rnt: null,
        age_ofreservturisticos: false,
        age_vehiculos: null,
        age_numvehiculos: null,
        age_capacidadvehicular: null,
        age_tiposturismorecorridos: null,
        age_serviciosmassolicitados: null,
        age_promtarifas: null,
        age_capatenciondiaria: null,
        age_medseguridad: null,
        age_polizas: false,
        age_botiquin: false,
        age_primaux: false,
        age_seguro: false,
        age_seguridadprivada: false,
        age_disfribilador: false,
        age_estacionatencion: false,
        age_bioseguridad: false,
        age_lavadomanos: false,
        age_distanciamiento: false,
        age_aforos: false,
        age_medidas_seguridad: null,
        age_autoridades: null,
        age_aliados: null,
        age_serviciostecnologicos: null,
        age_registroestadistico: false,
        age_guia: null,
        age_categoriaagencia: null,
        age_subcategoriaagencia: null,
        age_tipopersona: null,

        tipodepersona: null,
        categoria: null,
        subcategoria: null,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      },

      skeletons: this.global.skeletons,
      cargaskeletonagencias: false,
      showmodalagencia: false,

      agencias: [],
      categoriasagencias: [],
      subcategoriasagencias: [],
      tipopersonas: [],
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    registrarnuevaagencia: async function () {
      try {
        var mensaje = this.validarRegistrarAgencia;

        if (mensaje === "") {
          this.agencia.boton = " Guardando... ";
          this.agencia.wait = true;

          var parametros = {
            age_id: this.agencia.age_id,
            age_nombre: this.agencia.age_nombre,
            age_ubicacion: this.agencia.age_ubicacion,
            age_latitud: this.agencia.age_latitud,
            age_longitud: this.agencia.age_longitud,
            age_telefono: this.agencia.age_telefono,
            age_camaracomercio: this.agencia.age_camaracomercio,
            age_rnt: this.agencia.age_rnt,
            age_ofreservturisticos: this.agencia.age_ofreservturisticos,
            age_vehiculos: this.agencia.age_vehiculos.toString(),
            age_numvehiculos: this.agencia.age_numvehiculos,
            age_capacidadvehicular: this.agencia.age_capacidadvehicular,
            age_tiposturismorecorridos: this.agencia.age_tiposturismorecorridos.toString(),
            age_serviciosmassolicitados: this.agencia.age_serviciosmassolicitados.toString(),
            age_promtarifas: this.agencia.age_promtarifas,
            age_capatenciondiaria: this.agencia.age_capatenciondiaria,
            age_medseguridad: this.agencia.age_medseguridad.toString(),
            age_polizas: this.agencia.age_polizas,
            age_botiquin: this.agencia.age_botiquin,
            age_primaux: this.agencia.age_primaux,
            age_seguro: this.agencia.age_seguro,
            age_seguridadprivada: this.agencia.age_seguridadprivada,
            age_disfribilador: this.agencia.age_disfribilador,
            age_estacionatencion: this.agencia.age_estacionatencion,
            age_bioseguridad: this.agencia.age_bioseguridad,
            age_lavadomanos: this.agencia.age_lavadomanos,
            age_distanciamiento: this.agencia.age_distanciamiento,
            age_aforos: this.agencia.age_aforos,
            age_medidas_seguridad: this.agencia.age_medidas_seguridad.toString(),
            age_autoridades: this.agencia.age_autoridades.toString(),
            age_aliados: this.agencia.age_aliados.toString(),
            age_serviciostecnologicos: this.agencia.age_serviciostecnologicos.toString(),
            age_registroestadistico: this.agencia.age_registroestadistico,
            age_guia: this.agencia.age_guia,
            age_categoriaagencia: this.agencia.age_categoriaagencia.cat_id,
            age_subcategoriaagencia: this.agencia.age_subcategoriaagencia.subcat_id,
            age_tipopersona: this.agencia.age_tipopersona.tpper_id,
          };

          await this.axios
            .post("agencia.php?dato=nuevaagencia", JSON.stringify(parametros))
            .then((response) => {
              if (response.status === 200) {
                if (response.data.includes(this.global.transaccionOK)) {
                  this.mensajeOK(response.data);
                  this.limpiarCamposNuevoRegistro();
                  this.filtraragencias();
                } else if (this.global.buscarapierror(response.data) !== "") {
                  let msj_user = this.global.buscarapierror(response.data);
                  this.mensajeWar(msj_user);
                } else {
                  this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                }
              } else {
                this.global.mensajeErr(this.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              this.agencia.boton = " Guardar ";
              this.agencia.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtraragencias: async function () {
      try {
        this.cargaskeletonagencias = true;
        let self = this;

        var parametros = {
          filtro: this.agencia.filtro,
        };

        await this.axios
          .post("agencia.php?dato=getagencias", JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.agencias = response.data;
              if (self.agencias.length === 0) {
                self.mensajeWar("No se encontraron agencias");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonagencias = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getcategoriasagencias: async function () {
      try {
        this.cargaskeletonagencias = true;
        let self = this;
        await this.axios
          .post("agencia.php?dato=getcategorias")
          .then(function (response) {
            if (response.status === 200) {
              self.categoriasagencias = response.data;
              console.log(self.categoriasagencias);
            } else {
              self.mensajeErr("Error al cargar las agencias");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonagencias = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getsubcategoriasagencias: async function () {
      try {
        this.cargaskeletonagencias = true;
        let self = this;
        await this.axios
          .post("agencia.php?dato=getsubcategorias")
          .then(function (response) {
            if (response.status === 200) {
              self.subcategoriasagencias = response.data;
              console.log(self.subcategoriasagencias);
            } else {
              self.mensajeErr("Error al cargar las agencias");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonagencias = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    getgettipospersona: async function () {
      try {
        this.cargaskeletonagencias = true;
        let self = this;
        await this.axios
          .post("agencia.php?dato=gettipospersona")
          .then(function (response) {
            if (response.status === 200) {
              self.tipopersonas = response.data;
              console.log(self.tipopersonas);
            } else {
              self.mensajeErr("Error al cargar las agencias");
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonagencias = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.agencia = {
        age_id: 0,
        age_nombre: "",

       age_ubicacion: null,
        age_latitud: null,
        age_longitud: null,
        age_telefono: null,
        age_camaracomercio: false,
        age_rnt: null,
        age_ofreservturisticos: false,
        age_vehiculos: null,
        age_numvehiculos: null,
        age_capacidadvehicular: null,
        age_tiposturismorecorridos: null,
        age_serviciosmassolicitados: null,
        age_promtarifas: null,
        age_capatenciondiaria: null,
        age_medseguridad: null,
        age_polizas: false,
        age_botiquin: false,
        age_primaux: false,
        age_seguro: false,
        age_seguridadprivada: false,
        age_disfribilador: false,
        age_estacionatencion: false,
        age_bioseguridad: false,
        age_lavadomanos: false,
        age_distanciamiento: false,
        age_aforos: false,
        age_medidas_seguridad: null,
        age_autoridades: null,
        age_aliados: null,
        age_serviciostecnologicos: null,
        age_registroestadistico: false,
        age_guia: null,
        age_categoriaagencia: null,
        age_subcategoriaagencia: null,
        age_tipopersona: null,

        tipodepersona: null,
        categoria: null,
        subcategoria: null,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      };

      this.showmodalagencia = false;
    },

    openmodalagencia: function () {
      this.agencia = {
        age_id: 0,
        age_nombre: "",
        age_ubicacion: null,
        age_latitud: null,
        age_longitud: null,
        age_telefono: null,
        age_camaracomercio: false,
        age_rnt: null,
        age_ofreservturisticos: false,
        age_vehiculos: null,
        age_numvehiculos: null,
        age_capacidadvehicular: null,
        age_tiposturismorecorridos: null,
        age_serviciosmassolicitados: null,
        age_promtarifas: null,
        age_capatenciondiaria: null,
        age_medseguridad: null,
        age_polizas: false,
        age_botiquin: false,
        age_primaux: false,
        age_seguro: false,
        age_seguridadprivada: false,
        age_disfribilador: false,
        age_estacionatencion: false,
        age_bioseguridad: false,
        age_lavadomanos: false,
        age_distanciamiento: false,
        age_aforos: false,
        age_medidas_seguridad: null,
        age_autoridades: null,
        age_aliados: null,
        age_serviciostecnologicos: null,
        age_registroestadistico: false,
        age_guia: null,
        age_categoriaagencia: null,
        age_subcategoriaagencia: null,
        age_tipopersona: null,

        titulomodal: "Nueva ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        viewlink: false,
        linkgoogle: "",
      };

      this.showmodalagencia = true;
    },

    listarmodalagencia: async function (data) {
      var tiposvehiculos = [];
      var tiposdeturismo = [];
      var serviciosmassolicitados = [];
      var medidasdeseguridad = [];
      var serviciostecnologicos = [];
      var autoridades = [];
      var aliados = [];

      if (data.age_vehiculos !== "" && data.age_vehiculos !== null) {
        tiposvehiculos = data.age_vehiculos.toString().split(",");
      }

      if (data.age_tiposturismorecorridos !== "" && data.age_tiposturismorecorridos !== null) {
        tiposdeturismo = data.age_tiposturismorecorridos.toString().split(",");
      }

      if (data.age_serviciosmassolicitados !== "" && data.age_serviciosmassolicitados !== null) {
        serviciosmassolicitados = data.age_serviciosmassolicitados.toString().split(",");
      }

      if (data.age_medidas_seguridad !== "" && data.age_medidas_seguridad !== null) {
        medidasdeseguridad = data.age_medidas_seguridad.toString().split(",");
      }

      if (data.age_serviciostecnologicos !== "" && data.age_serviciostecnologicos !== null) {
        serviciostecnologicos = data.age_serviciostecnologicos.toString().split(",");
      }

      if (data.age_serviciostecnologicos !== "" && data.age_serviciostecnologicos !== null) {
        serviciostecnologicos = data.age_serviciostecnologicos.toString().split(",");
      }

      if (data.age_autoridades !== "" && data.age_autoridades !== null) {
        autoridades = data.age_autoridades.toString().split(",");
      }

      if (data.age_aliados !== "" && data.age_aliados !== null) {
        aliados = data.age_aliados.toString().split(",");
      }

      this.agencia = {
        titulomodal: " Editar ",
        boton: "Editar",
        accion: 1,
        nuevo: false,

        age_id: data.age_id,
        age_nombre: data.age_nombre,
        age_ubicacion: data.age_ubicacion,
        age_latitud: data.age_latitud,
        age_longitud: data.age_longitud,
        age_telefono: data.age_telefono,
        age_camaracomercio: data.age_camaracomercio,
        age_rnt: data.age_rnt,
        age_ofreservturisticos: data.age_ofreservturisticos,
        age_vehiculos: tiposvehiculos,
        age_numvehiculos: data.age_numvehiculos,
        age_capacidadvehicular: data.age_capacidadvehicular,
        age_tiposturismorecorridos: tiposdeturismo,
        age_serviciosmassolicitados: serviciosmassolicitados,
        age_promtarifas: data.age_promtarifas,
        age_capatenciondiaria: data.age_capatenciondiaria,
        age_medseguridad: data.age_medseguridad,
        age_polizas: data.age_polizas,
        age_botiquin: data.age_botiquin,
        age_primaux: data.age_primaux,
        age_seguro: data.age_seguro,
        age_seguridadprivada: data.age_seguridadprivada,
        age_disfribilador: data.age_disfribilador,
        age_estacionatencion: data.age_estacionatencion,
        age_bioseguridad: data.age_bioseguridad,
        age_lavadomanos: data.age_lavadomanos,
        age_distanciamiento: data.age_distanciamiento,
        age_aforos: data.age_aforos,
        age_medidas_seguridad: medidasdeseguridad,
        age_autoridades: autoridades,
        age_aliados: aliados,
        age_serviciostecnologicos: serviciostecnologicos,
        age_registroestadistico: data.age_registroestadistico,
        age_guia: data.age_guia,

        viewlink: true,
        linkgoogle:
          "https://www.google.com/maps/@" +
          data.age_latitud +
          "," +
          data.age_longitud +
          ",15z",

        age_categoriaagencia: this.categoriasagencias.find(
          (p) => p.cat_id === data.categoria.cat_id
        ),

        age_subcategoriaagencia: this.subcategoriasagencias.find(
          (p) => p.subcat_id === data.subcategoria.subcat_id
        ),

        age_tipopersona: this.tipopersonas.find(
          (p) => p.tpper_id === data.tipodepersona.tpper_id
        ),
      };

      this.showmodalagencia = true;
    },
  },

  computed: {
    validarRegistrarAgencia() {
      var mensajeAlerta = "";
      if (this.agencia.age_ubicacion === "") {
        mensajeAlerta = " Debe digitar una ubicación ";
      } else if (
        this.agencia.age_nombre === "" ||
        this.agencia.age_nombre.length > 75
      ) {
        mensajeAlerta =
          "Debe digitar un nombre válido, no debe superar los 75 caracteres";
      } else if (
        this.agencia.age_latitud === null ||
        Number.isNaN(Number(this.agencia.age_latitud)) ||
        this.agencia.age_latitud > 180 ||
        this.agencia.age_latitud < -180 ||
        this.agencia.age_latitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Latitud válido ej: 11.144433 separando decimales con punto (.) ";
      } else if (
        this.agencia.age_longitud === null ||
        Number.isNaN(Number(this.agencia.age_longitud)) ||
        this.agencia.age_longitud > 180 ||
        this.agencia.age_longitud < -180 ||
        this.agencia.age_longitud.includes(",")
      ) {
        mensajeAlerta =
          "Debe digitar un valor de Longitud válido ej: -74.118597 separando decimales con punto (.) ";
      } else if (this.agencia.age_tipopersona === null) {
        mensajeAlerta = " Debe seleccionar si es persona natural o jurídica ";
      }

      else if (this.agencia.age_categoriaagencia === null) {
        mensajeAlerta = " Debe seleccionar una categoría observada ";
      }

      else if (this.agencia.age_subcategoriaagencia === null) {
        mensajeAlerta = " Debe seleccionar una subcategoría ";
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    this.getcategoriasagencias();
    this.getsubcategoriasagencias();
    this.getgettipospersona();
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
}

.uno {
  margin-right: 20px;
  flex-grow: 10;
}

.dos {
  margin-left: 20px;
  flex-grow: 5;
}

.tres {
  flex-grow: 5;
}

.imagenes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
}

.article {
  height: 100%;
  display: grid;
  place-content: center;
}

.img {
  width: 300px;
  height: 300px;
}
</style>
