<template>
  <div>
    <NavbarMain />
    <iframe
      title="SwissContact"
      width="100%"
      height="700px"
      src="https://app.powerbi.com/view?r=eyJrIjoiM2Y5ZjkxZWYtYmRkMi00NDgwLWJhMmEtMTI1YmIxN2UyNGM2IiwidCI6IjM0MzAzNTQxLTc0ZWMtNGQ0YS04YzVhLTgwNDlkMmZkNmNlNiIsImMiOjR9"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "IndexFrameTest",
  data() {
    return {};
  },
  components: {
    NavbarMain,
  },

  methods: {},

  computed: {},
};
</script>
