<template>
  <div class="container mt-7">
    <div class="card m-4">
      <div
        class="flex flex-column card-container green-container justify-content-center"
      >
        <div class="flex align-items-center justify-content-center h-4rem m-2">
          <h4>Sistema de Información CIENSIERRA </h4>
        </div>

        <div class="flex align-items-center justify-content-center">
          <img
            src="@/assets/PersonajeTurismo.png"
            width="30%"
            height="30%"
          />
        </div>
        <div class="flex align-items-center justify-content-center h-4rem m-2">
          <div class="p-inputgroup m-4">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <InputText
              placeholder="Usuario"
              v-model="username"
              @keyup.enter="login"
            />
          </div>
        </div>
        <div class="flex align-items-center justify-content-center h-4rem m-2">
          <div class="p-inputgroup">
            <Password
              class="m-4"
              v-model="password"
              placeholder="Clave"
              :feedback="false"
              @keypress.enter.prevent="login"
            />
          </div>
        </div>

        <div class="flex align-items-center justify-content-center h-4rem m-2">
          <div class="p-inputgroup">
            <ProgressSpinner
              class="mr-2"
              v-show="wait"
              style="width: 40px; height: 40px"
              strokeWidth="8"
              fill="#FFFFFF"
              animationDuration=".5s"
            />
            <Button
              class="m-4"
              :label="boton"
              icon="pi pi-check"
              autofocus
              @click="login"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div>
        <div class="grid box">
          <div class="col-6 md:col-6 lg:col-6"></div>
        </div>

        <div class="grid box">
          <div class="col-6 md:col-6 lg:col-6"></div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
export default {
  name: "Especies",
  data() {
    return {
      username: "",
      password: "",
      boton: "Ingresar",
      wait: false,
      showprogressppallogin: false,
      usuarios: [],
    };
  },

  components: {
    Toast,
  },

  methods: {
    login: async function () {
      try {
        var mensaje = this.validarInicioSesion;

        if (mensaje === "") {
          this.showprogressppallogin = true;
          let self = this;

          this.boton = " Ingresando... ";
          this.wait = true;

          let config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          var parametros = {
            username: this.username,
            password: this.password,
          };

          await this.axios
            .post("usuarios.php?dato=login", JSON.stringify(parametros), config)
            .then(function (response) {
              if (response.status === 200) {
                self.usuarios = response.data;
                if (self.usuarios.length === 0) {
                  self.mensajeWar("Usuario no encontrado");
                } else {
                  self.mensajeOK("BIENVENIDO " + response.data[0].usu_nombre);
                  localStorage.setItem("data", JSON.stringify(response.data));
                  localStorage.setItem("token", response.data.token);
                  localStorage.setItem("alias", response.data[0].usu_alias);
                  localStorage.setItem("name", response.data[0].usu_nombre);
                  localStorage.setItem("phone", response.data[0].usu_telefono);
                  localStorage.setItem("mail", response.data[0].usu_correo);
                  self.$router.push("index");
                }
                console.log(self.usuarios);
              }
            })
            .catch((error) => {
              self.global.catching(error);
              self.mensajeErr("Error en la respuesta");
              document.form.reset();
            })
            .finally(() => {
              self.showprogressppallogin = false;
              self.boton = "Guardar";
              self.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },
  },

  created: function () {},

  computed: {
    validarInicioSesion: function () {
      return "";
    },
  },
};
</script>

<style>
.box {
  display: flex;
  align-items: center;
}
</style>
