<template>
  <div>
    <div>
      <DataTable
        :value="estufaHistorico"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="10"
      >
        <template #header>
          <div class="table-header">
            <Button icon="pi pi-plus" />
          </div>
        </template>

        <Column field="esthis_fecha" header="Fecha"></Column>
        <Column field="tpes_detalle" header="T de Estufa"></Column>
         <Column field="esthis_porcentaje" header="Total en fincas"></Column>
      </DataTable>
    </div>
    <div class="col-12">
      <DataTable :value="skeletons" v-show="cargaskeletonarreglogenerico">
        <Column field="ske1" header="Nombre">
          <template #body>
            <Skeleton></Skeleton>
          </template>
        </Column>
        <Column field="ske4" header="">
          <template #body>
            <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="col">
      <Chart
        type="bar"
        :data="basicData"
        :options="basicOptions"
        :responsive="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Estufa",
  data() {
    return {
      meses: this.global.meses,
      
      estufaHistorico: [],

      dataTable: [],
      basicData: [],

      basicOptions: {
        indexAxis: "y",
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },

      skeletons: this.global.skeletons,
      cargaskeletonarreglogenerico: false,

    };
  },

  methods: {
    getEstufaTable: async function () {
      if (
        this.anio !== null &&
        this.anio != undefined
      ) {
        try {
          this.cargaskeletonarreglogenerico = true;
          let self = this;

          var parametros = {
            year: this.anio,
          };

          await this.axios
            .post(
              "historicos.php?dato=getestufahistorico",
              JSON.stringify(parametros)
            )
            .then(function (response) {
              if (response.status === 200) {
                self.estufaHistorico = response.data;
              
/*
                var prelabels = self.estufaHistorico.slice(0, 1);
                var prolabelsdep = prelabels[0].labels;

                var labels = [];

                for (var i = 0; i < prolabelsdep.length; i++) {
                  var val = self.meses.find(
                    (m) => m.numero === prolabelsdep[i]
                  );
                  labels.push(val.nombre);
                }

                var data = self.estufaHistorico.slice(1, 2);
                self.dataTable = self.estufaHistorico.slice(2,3)[0];

                self.basicData = {
                  labels: labels,
                  datasets: data,
                };

                */
              } else {
                self.mensajeErr("Error al cargar KPIs años disponibles");
              }
            })
            .catch((error) => {
              this.global.catching(error);
            })
            .finally(() => {
              self.cargaskeletonarreglogenerico = false;
            });
        } catch (e) {
          alert("Exception Javascript: " + e);
          console.log("Exception Javascript: " + e);
        }
      }
    },

  },

  created: function () {
    this.getEstufaTable();
  },

  props: {
    anio: String,
  },

};
</script>
