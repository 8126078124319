<template>
  <div>
    <NavbarMain />

    <div class="container mt-4">
      <div class="grid">
        <div class="col-12">
          <DataTable
            :value="archivos"
            v-show="!cargaskeletonarchivos"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
          >
            <template #header>
              <div class="table-header">
                Busqueda de Archivos
                <div class="p-inputgroup mr-4 ml-4">
                  <InputText
                    placeholder="Buscar x nombre de archivo local"
                    v-model="archivo.filtro"
                    @keyup.enter="filtrararchivos"
                    autocomplete="off"
                  />
                  <Button
                    icon="pi pi-search"
                    class="p-button-success"
                    @click="filtrararchivos"
                  />
                </div>
              </div>
            </template>

            <Column field="arc_nombre" header="Nombre"></Column>

            <Column>
              <template #body="slotProps">
                <b-link :href="slotProps.data.link_evento" target="_blank">{{
                  slotProps.data.arc_nombrefile
                }}</b-link>
              </template></Column
            >
          </DataTable>
        </div>
        <div class="col-12">
          <DataTable :value="skeletons" v-show="cargaskeletonarchivos">
            <Column field="ske1" header="Nombre">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske2" header="Link descarga">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";
export default {
  name: "Archivos",

  data() {
    return {
      archivo: {
        arc_id: 0,
        arc_nombre: "",
        arc_nombrefile: "",
        arc_ruta: "public_ftp/archivosgenerales",

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      },

      skeletons: this.global.skeletons,
      cargaskeletonarchivos: false,
      showmodalarchivo: false,

      archivos: [],
      selectedFiles: [],
      sizeSelectedFiles: 0,
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {

    filtrararchivos: async function () {
      try {
        this.cargaskeletonarchivos = true;
        let self = this;

        var parametros = {
          filtro: this.archivo.filtro,
        };

        await this.axios
          .post(
            "archivos.php?dato=getarchivosconvinculo",
            JSON.stringify(parametros)
          )
          .then(function (response) {
            if (response.status === 200) {
              self.archivos = response.data;
              if (self.archivos.length === 0) {
                self.mensajeWar(
                  "No se encontraron archivos con ese criterio de busqueda"
                );
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonarchivos = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },


    onFileChange(event) {
      //console.log(event);
      this.sizeSelectedFiles = event.target.files.length;
      this.selectedFiles = event.target.files;
    },
  },

  computed: {

  },

  created: function () {},

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>
