<template>
  <div>
    <NavbarMain />

    <div class="container mt-4">
      <div class="grid">
        <div class="col-12">
          <DataTable
            :value="guias"
            v-show="!cargaskeletonguias"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
          >
            <template #header>
              <div class="table-header">
                Guias locales
                <div class="p-inputgroup mr-4 ml-4">
                  <InputText
                    placeholder="Buscar x nombre de guia local"
                    v-model="guia.filtro"
                    @keyup.enter="filtrarguias"
                  />
                  <Button
                    icon="pi pi-search"
                    class="p-button-success"
                    @click="filtrarguias"
                  />
                </div>

                <Button icon="pi pi-plus" @click="openmodalguia()" />
              </div>
            </template>

            <Column field="gui_nombre" header="Nombre"></Column>
            <Column field="gui_especialidad" header="Especialidad"></Column>
            <Column field="gui_telefono" header="Teléfono"></Column>
            <Column>
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-primary mr-2"
                  @click="listarmodalguia(slotProps.data)"
                /> </template
            ></Column>
          </DataTable>
        </div>
        <div class="col-12">
          <DataTable :value="skeletons" v-show="cargaskeletonguias">
            <Column field="ske1" header="Guia Local">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske2" header="Especialidad">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske3" header="Teléfono">
              <template #body>
                <Skeleton></Skeleton>
              </template>
            </Column>
            <Column field="ske4" header="">
              <template #body>
                <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible.sync="showmodalguia"
      position="center"
      :modal="true"
      :containerStyle="{ width: '100vw' }"
    >
      <template #header>
        <i class="pi pi-check" style="font-size: 2rem"></i>
        <h4>{{ guia.titulomodal }} guía local</h4>
      </template>

      <div class="p-fluid grid formgrid">
        <div class="field col-12">
          <label for="gui_nombre" v-show="!guia.nuevo">Nombre</label>
          <InputText
            id="gui_nombre"
            placeholder="Nombre: Carlos Silva"
            v-model="guia.gui_nombre"
          />
        </div>


        <div class="field col-12">
          <label for="gui_especialidad" v-show="!guia.nuevo">Especialidad</label>
          <InputText
            id="gui_especialidad"
            placeholder="Especialidad: Traductor"
            v-model="guia.gui_especialidad"
          />
        </div>

        <div class="field col-12">
          <label for="gui_telefono" v-show="!guia.nuevo">Teléfono</label>
          <InputText
            id="gui_telefono"
            placeholder="Teléfono: 3124508097"
            v-model="guia.gui_telefono"
          />
        </div>

        <div class="field col-12">
          <label for="gui_direccion" v-show="!guia.nuevo">Dirección</label>
          <InputText
            id="gui_direccion"
            placeholder="Dirección: Cll 3A N° 34-67 Minca "
            v-model="guia.gui_direccion"
          />
        </div>

        

      </div>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          v-show="guia.wait"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          animationDuration=".5s"
        />
        <Button
          :label="guia.boton"
          icon="pi pi-check"
          autofocus
          @click="registrarnuevaguia"
        />
      </template>
    </Dialog>

    <div class="grid">
      <div class="col-12">
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import NavbarMain from "@/components/NavbarMain.vue";

export default {
  name: "GuiasLocales",
  data() {
    return {
      guia: {
        gui_id: 0,
        gui_nombre: "",
        gui_especialidad: "",
        gui_telefono: "",
        gui_direccion: "",

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      },

      skeletons: this.global.skeletons,
      cargaskeletonguias: false,
      showmodalguia: false,

      guias: [],
    };
  },
  components: {
    Toast,
    NavbarMain,
  },

  methods: {
    registrarnuevaguia: async function () {
      try {
        var mensaje = this.validarRegistrarGuia;

        if (mensaje === "") {
          this.guia.boton = " Guardando... ";
          this.guia.wait = true;

          var parametros = {
            gui_id: this.guia.gui_id,
            gui_nombre: this.guia.gui_nombre,
            gui_especialidad: this.guia.gui_especialidad,
            gui_telefono: this.guia.gui_telefono,
            gui_direccion: this.guia.gui_direccion
          };

          await this.axios
            .post("guiaslocales.php?dato=nuevoguia", JSON.stringify(parametros))
            .then((response) => {
              if (response.status === 200) {
                if (response.data.includes(this.global.transaccionOK)) {
                  this.mensajeOK(response.data);
                  this.limpiarCamposNuevoRegistro();
                  this.filtrarguias();
                } else if (this.global.buscarapierror(response.data) !== "") {
                  let msj_user = this.global.buscarapierror(response.data);
                  this.mensajeWar(msj_user);
                } else {
                  this.mensajeErr(this.global.respuestaValidaSinTransaccion);
                }
              } else {
                this.global.mensajeErr(this.global.respuestaInvalida);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              this.guia.boton = " Guardar ";
              this.guia.wait = false;
            });
        } else {
          this.mensajeWar(mensaje);
        }
      } catch (e) {
        this.mensajeWar(this.global.errorJavaScript);
        console.log(e);
      }
    },

    filtrarguias: async function () {
      try {
        this.cargaskeletonguias = true;
        let self = this;

        var parametros = {
          filtro: this.guia.filtro,
        };

        await this.axios
          .post("guiaslocales.php?dato=getguias", JSON.stringify(parametros))
          .then(function (response) {
            if (response.status === 200) {
              self.guias = response.data;
              if (self.guias.length === 0) {
                self.mensajeWar("No se encontraron guias con ese criterio de busqueda");
              }
            }
          })
          .catch((error) => {
            this.global.catching(error);
          })
          .finally(() => {
            self.cargaskeletonguias = false;
          });
      } catch (e) {
        alert("Exception Javascript: " + e);
        console.log("Exception Javascript: " + e);
      }
    },

    mensajeOK: function (mensaje) {
      this.$toast.add({
        severity: "success",
        summary: "Mensaje de confirmación",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeWar: function (mensaje) {
      this.$toast.add({
        severity: "warn",
        summary: "Mensaje de alerta",
        detail: mensaje,
        life: 3000,
      });
    },

    mensajeErr: function (mensaje) {
      this.$toast.add({
        severity: "error",
        summary: "Mensaje de error",
        detail: mensaje,
        life: 3000,
      });
      console.log("ERROR: " + mensaje);
    },

    limpiarCamposNuevoRegistro() {
      this.guia = {
        gui_id: 0,
        gui_nombre: "",
        gui_especialidad: "",
        gui_telefono: "",
        gui_direccion: "",

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
        
      };

      this.showmodalguia = false;
    },

    openmodalguia: function () {
      this.guia = {
        gui_id: 0,
        gui_nombre: "",
        gui_especialidad: "",
        gui_telefono: "",
        gui_direccion: "",
       

        titulomodal: "Nuevo ",
        boton: "Guardar",
        accion: 0,
        nuevo: true,
        filtro: "",
        wait: false,
      };

      this.showmodalguia = true;
    },

    listarmodalguia: async function (data) {

      this.guia = {
        titulomodal: " Editar ",
        boton: "Editar",
        accion: 1,
        nuevo: false,

        gui_id: data.gui_id,
        gui_nombre: data.gui_nombre,
        gui_especialidad: data.gui_especialidad,
        gui_telefono: data.gui_telefono,
        gui_direccion: data.gui_direccion
        
      };

      this.showmodalguia = true;
    },
  },

  computed: {
    validarRegistrarGuia() {
      var mensajeAlerta = "";
      if (
        this.guia.gui_nombre === "" ||
        this.guia.gui_nombre.length > 100
      ) {
        mensajeAlerta =
          "Debe digitar un nombre válido, no debe superar los 100 caracteres";
      } else if (
        this.guia.gui_especialidad === "" ||
        this.guia.gui_especialidad.length > 100
      ) {
        mensajeAlerta =
          "Debe digitar una especialidad válida, no debe superar los 100 caracteres";
      } else if (
        this.guia.gui_telefono === null)
       {
        mensajeAlerta =
          "Debe digitar un N° de teléfono válido ej: 3105671122, sin espacios  ";
      } else if(this.guia.gui_direccion > 100){
         mensajeAlerta =
          "Debe digitar una especialidad válida, no debe superar los 100 caracteres";
      }

      return mensajeAlerta;
    },
  },

  created: function () {
    
  },

  mounted() {
    if (!localStorage.getItem("name")) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.ppal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.child {
  flex-grow: 1;
}

.uno {
  margin-right: 20px;
  flex-grow: 10;
}

.dos {
  margin-left: 20px;
  flex-grow: 5;
}

.tres {
  flex-grow: 5;
}

.imagenes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
}

.article {
  height: 100%;
  display: grid;
  place-content: center;
}

.img {
  width: 300px;
  height: 300px;
}
</style>
